import { isNumber, isNil } from 'lodash-es';

import { Alias, DTO, Mapper } from '@bp/shared/models/metadata';
import { SubscriptionFeaturesLimitsKeys, SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { MerchantSubscription } from '@bp/admins-shared/core/models';

import { CurrentMerchantSubscriptionFeatureLimit } from './current-merchant-subscription-feature-limit';

export class CurrentMerchantSubscription extends MerchantSubscription {

	@Mapper(SubscriptionPlan)
	@Alias('subscription')
	subscriptionPlan!: SubscriptionPlan;

	get isEnterprise(): boolean {
		return this.subscriptionPlan.isEnterprise;
	}

	readonly featureLimitsMap = this._aggregateFeatureLimits();

	constructor(dto?: DTO<CurrentMerchantSubscription>) {
		super(dto);
	}

	getFeatureLimit(featureName: SubscriptionFeaturesLimitsKeys): CurrentMerchantSubscriptionFeatureLimit {
		return this.featureLimitsMap.get(featureName)!;
	}

	getMonthPrice(): number {
		return this.subscriptionPlan.getMonthPriceFor(this.chargePeriod, this.currency);
	}

	getChargePeriodPrice(): number {
		return this.subscriptionPlan.prices?.get(this.chargePeriod)?.getPriceFor(this.currency) ?? 0;
	}

	private _aggregateFeatureLimits(): Map<SubscriptionFeaturesLimitsKeys, CurrentMerchantSubscriptionFeatureLimit> {
		return new Map(
			Object
				.entries(this.limitsCounters)
				.filter((entry): entry is [SubscriptionFeaturesLimitsKeys, number] => isNumber(entry[1]) || isNil(entry[1]))
				.map(([ featureName, counter ]) => {
					const featureLabel = this.limitsCounters.classMetadata.get(featureName)!.label!;
					const featureLimit = this.subscriptionPlan.limits[featureName];

					return [
						featureName,
						new CurrentMerchantSubscriptionFeatureLimit({
							featureName,
							featureLabel,
							featureDescription: this.subscriptionPlan.features
								.find(feature => feature.name === featureLabel)?.description,
							counter,
							limit: featureLimit ? Number(featureLimit) : null,
						}),
					];
				}),
		);
	}
}
