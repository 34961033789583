<div class="modal-header">
	<button
		*ngIf="!_isSubscriptionPlansTabActive"
		@fade
		mat-icon-button
		class="back-arrow-button"
		bpTooltip="Back to Plans"
		type="button"
		(click)="gotoSubscriptionPlansTab()"
	>
		<mat-icon class="material-icons-round">arrow_back</mat-icon>
	</button>
</div>

<div
	class="modal-content bp-thin-scrollbar"
	[class.checkout-tab-active]="_isCheckoutTabActive"
	[class.downgrade-tab-active]="_isDowngradeTabActive"
>
	<mat-progress-bar *ngIf="_changeSubscriptionPlanPending$ | async" @slide color="primary" mode="indeterminate">
	</mat-progress-bar>

	<div *ngIf="_currentMerchantSubscriptionFacade.loading$ | async" @fade class="pending-spinner-container">
		<bp-spinning-logo> </bp-spinning-logo>
	</div>

	<ng-container *ngIf="_currentMerchantSubscriptionFacade.presentEntity$ | async as currentMerchantSubscription">
		<bp-enterprise-subscription-pane *ngIf="_currentSubscriptionPlan!.isEnterprise; else nonEnterprisePlansTpl">
		</bp-enterprise-subscription-pane>

		<ng-template #nonEnterprisePlansTpl>
			<mat-tab-group
				@fade
				class="headless-mat-tab-group"
				dynamicHeight
				[selectedIndex]="_currentTabIndex$ | async"
				[disablePagination]="true"
				(selectedTabChange)="onSelectedTabChange($event)"
			>
				<mat-tab>
					<div
						*ngIf="_subscriptionPlansSharedFacade.allPresent$ | async as subscriptionPlans"
						class="subscription-plans-tab-container"
					>
						<bp-subscription-plans-overview
							[isUpgradeMode]="_isUpgradeMode"
							[currentMerchantSubscription]="currentMerchantSubscription"
							[subscriptionPlans]="subscriptionPlans"
							(executeSubscriptionPlanAction)="executeSubscriptionPlanAction($event)"
							(selectedChargePeriodChange)="_selectedChargePeriod = $event"
							(selectedCurrencyChange)="_selectedCurrency = $event"
						></bp-subscription-plans-overview>

						<bp-subscription-plan-logos></bp-subscription-plan-logos>

						<h2 class="text-center mb-4">Complete Feature List</h2>

						<bp-feature-comparison-table
							[subscriptionPlans]="subscriptionPlans"
						></bp-feature-comparison-table>

						<h2 class="text-center mb-4">Frequently Asked Questions</h2>

						<bp-faq [faqs]="_SUBSCRIPTION_PLANS_FAQS"></bp-faq>
					</div>
				</mat-tab>

				<mat-tab>
					<ng-template matTabContent>
						<bp-subscription-plan-checkout
							[subscriptionPlan]="_selectedSubscriptionPlan!"
							[chargePeriod]="_selectedChargePeriod"
							[merchantName]="(_currentMerchantFacade.entity$ | async)?.name"
							[currency]="_selectedCurrency"
							[pending]="_changeSubscriptionPlanPending$ | async"
							[error]="_currentMerchantSubscriptionFacade.error$ | async"
							(purchaseSubscriptionPlan)="
								_currentMerchantSubscriptionFacade.purchaseSubscriptionPlan($event)
							"
						></bp-subscription-plan-checkout>
					</ng-template>
				</mat-tab>

				<mat-tab>
					<ng-template matTabContent>
						<bp-subscription-downgrade
							[subscriptionPlan]="_selectedSubscriptionPlan!"
							[chargePeriod]="_selectedChargePeriod"
							[currentMerchantSubscription]="currentMerchantSubscription"
							[error]="_currentMerchantSubscriptionFacade.error$ | async"
							(cancel)="gotoSubscriptionPlansTab()"
							(downgrade)="downgradeSubscription()"
						></bp-subscription-downgrade>
					</ng-template>
				</mat-tab>

				<mat-tab bodyClass="subscription-plan-congratulation">
					<ng-template matTabContent>
						<bp-subscription-plan-congratulation
							[subscriptionPlan]="(_currentMerchantSubscriptionFacade.subscriptionPlan$ | async)!"
							(continue)="subscriptionPlanSuccessfullyPurchased.emit()"
						></bp-subscription-plan-congratulation>
					</ng-template>
				</mat-tab>
			</mat-tab-group>
		</ng-template>
	</ng-container>
</div>

<div *ngIf="(_currentSubscriptionPlan$ | async)?.isFree && _isSubscriptionPlansTabActive" @fade class="modal-footer">
	<content class="flex-align-items-center">
		<div
			*ngIf="_subscriptionPlansSharedFacade.pro$ | async as proSubscriptionPlan"
			class="money-back-guarantee ml-4 mr-5 mat-body-2 text-primary flex-column-align-items-center"
		>
			<div class="flex-align-items-center">
				<mat-icon *ngFor="let star of 3 | range" class="bp-icon-sm material-icons-round"> star </mat-icon>
				<span class="ml-2 mr-2">
					First {{ proSubscriptionPlan.prices!.get(_selectedChargePeriod)!.refundableForDays ?? 14 }} Days
				</span>
				<mat-icon *ngFor="let star of 3 | range" class="bp-icon-sm material-icons-round"> star </mat-icon>
			</div>
			<div>Money Back Guarantee</div>
		</div>

		<img src="assets/trust-icons.png" />

		<button type="button" class="bp-round-button ml-auto" color="primary" mat-flat-button (click)="upgradeToPro()">
			Upgrade To Pro!
		</button>
	</content>
</div>
