
import { Component, ChangeDetectionStrategy } from '@angular/core';

import type { ViewsSectionScheme } from '@bp/shared/components/metadata';
import { ViewEntityBaseComponent } from '@bp/shared/components/metadata';

import { IdentityPreferences } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-identity-preferences-view',
	templateUrl: './identity-preferences-view.component.html',
	styleUrls: [ './identity-preferences-view.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentityPreferencesViewComponent extends ViewEntityBaseComponent<IdentityPreferences> {

	override metadata = IdentityPreferences.getClassMetadata();

	sectionScheme: ViewsSectionScheme<IdentityPreferences> = [
		[ 'timezone' ],
	];
}
