import { Validators } from '@bp/shared/features/validation';
import type { IWireTransferPaymentOption } from '@bp/shared/models/business';
import {
	BankAccountRequisites, PaymentOptionType, wireTransferJsonPropertyDtoSourceMapper
} from '@bp/shared/models/business';
import type { DTO } from '@bp/shared/models/metadata';
import {
	Default, Validator, Mapper, SourceOfDTO
} from '@bp/shared/models/metadata';

import { StaticPaymentOptionBase } from './static-payment-option-base';

export class WireTransferPaymentOption extends StaticPaymentOptionBase implements IWireTransferPaymentOption {

	static type = PaymentOptionType.wireTransfer;

	readonly type = WireTransferPaymentOption.type;

	@SourceOfDTO(wireTransferJsonPropertyDtoSourceMapper)
	override json!: string[];

	@Validator(Validators.requiredArray)
	@Mapper(BankAccountRequisites)
	@Default([ new BankAccountRequisites() ])
	requisites!: BankAccountRequisites[];

	constructor(dto?: DTO<WireTransferPaymentOption>) {
		super(dto);
	}

}
