<bp-sidenav>
	<ng-container *bpHasAccess="Feature.dashboard">
		<bp-nav-item path="/dashboard" icon="house">
			Dashboard
			<bp-paywall-badge *bpBehindPaywall></bp-paywall-badge>
		</bp-nav-item>

		<hr />
	</ng-container>

	<bp-nav-item *bpHasAccess="Feature.transactions" path="/transactions" icon="transactions">
		Transactions

		<bp-paywall-badge *bpBehindPaywall></bp-paywall-badge>
	</bp-nav-item>

	<ng-container *bpHasAccess="Feature.payouts">
		<bp-nav-item *ngIf="(currentMerchantFacade.entity$ | async)?.allowPayouts" path="/payouts" icon="transactions">
			Payouts

			<bp-paywall-badge *bpBehindPaywall></bp-paywall-badge>
		</bp-nav-item>
	</ng-container>

	<bp-nav-item *bpHasAccess="Feature.paymentRoutes" path="/payment-routes" icon="paymentRouter">
		Router

		<bp-paywall-badge *bpBehindPaywall></bp-paywall-badge>
	</bp-nav-item>

	<bp-nav-item *bpHasAccess="Feature.psps" path="/merchant-psps" icon="paymentProviders">
		PSPs

		<bp-paywall-badge *bpBehindPaywall></bp-paywall-badge>
	</bp-nav-item>

	<bp-nav-item *bpHasAccess="Feature.cashiers" path="/checkouts" icon="cashiers"> Checkouts </bp-nav-item>

	<bp-nav-item
		*bpHasAccess="Feature.virtualTerminal"
		icon="terminal"
		[path]="[{ outlets: { '[right-drawer]': ['virtual-terminal'] } }]"
	>
		Virtual Terminal
	</bp-nav-item>

	<bp-nav-item *bpHasAccess="Feature.reports" icon="reports" path="/reports">
		Reports
		<bp-paywall-badge *bpBehindPaywall></bp-paywall-badge>
	</bp-nav-item>

	<ng-container *bpHasAccess="Feature.creditCardTokens">
		<bp-nav-item
			*ngIf="(currentMerchantFacade.entity$ | async)?.allowCreateCreditCardToken"
			path="/credit-card-tokens"
			icon="card-with-lock"
		>
			Bridger Safe

			<bp-paywall-badge *bpBehindPaywall></bp-paywall-badge>
		</bp-nav-item>
	</ng-container>

	<hr *ngIf="environment.isStagingOrLocal" />

	<div *bpFeatureUnderDevelopment>
		<bp-nav-item *bpHasAccess="Feature.members" icon="members" path="/members">
			Members
			<bp-paywall-badge *bpBehindPaywall></bp-paywall-badge>
		</bp-nav-item>
	</div>
</bp-sidenav>
