import { uniq } from 'lodash-es';

import type { Country } from '@bp/shared/models/countries';
import type { Currency } from '@bp/shared/models/currencies';
import { MetadataEntity } from '@bp/shared/models/metadata';
import { isInstanceOf } from '@bp/shared/utilities';

import type { PaymentRoute } from '../payment-route';
import { CreditCardPspPaymentOption } from '../payment-route';

export type PaymentRoutesCreditCardPspsPerCountryMap = Map<Country, string[]>;

export type PaymentRoutesCurrenciesPerCountryMap = Map<Country, Currency[]>;

export class VirtualTerminalData extends MetadataEntity {

	creditCardPspsPerCountryMap = this._extractFromPaymentRoutesCreditCardPspsPerCountry();

	currenciesPerCountryMap = this._extractFromPaymentRoutesCurrenciesPerCountry();

	constructor(private readonly _paymentRoutes: PaymentRoute[]) {
		super();
	}

	private _extractFromPaymentRoutesCreditCardPspsPerCountry(): PaymentRoutesCreditCardPspsPerCountryMap {
		return new Map(
			this._paymentRoutes.flatMap(paymentRoute => {
				const paymentRouteCreditCardPsps = paymentRoute.psps
					.filter(isInstanceOf(CreditCardPspPaymentOption))
					.map(paymentOption => (paymentOption.pspName));

				return paymentRoute.countries.map(country => [ country, paymentRouteCreditCardPsps ]);
			}),

		);
	}

	private _extractFromPaymentRoutesCurrenciesPerCountry(): PaymentRoutesCurrenciesPerCountryMap {
		return new Map(
			this._paymentRoutes.flatMap(paymentRoute => {
				const paymentRouteCurrencies = uniq(paymentRoute.psps
					.filter(isInstanceOf(CreditCardPspPaymentOption))
					.flatMap(psp => <Currency[]>psp.currencies));

				return paymentRoute.countries.map(country => [ country, paymentRouteCurrencies ]);
			}),
		);
	}
}
