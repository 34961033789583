import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { IState } from './current-merchant.reducer';
import { FEATURE_KEY } from './current-merchant.reducer';

const selectFeatureState = createFeatureSelector<IState>(FEATURE_KEY);

export const selectCurrentMerchant = createSelector(
	selectFeatureState,
	state => state.entity,
);

export const selectCurrentSubscription = createSelector(
	selectCurrentMerchant,
	currentMerchant => currentMerchant?.currentSubscription,
);

export const selectCurrentMerchantLoading = createSelector(
	selectFeatureState,
	state => state.loading,
);

export const selectBridgerpayProviders = createSelector(
	selectFeatureState,
	state => state.bridgerPsps,
);

export const selectError = createSelector(
	selectFeatureState,
	state => state.error,
);

export const selectVirtualTerminalData = createSelector(
	selectFeatureState,
	state => state.virtualTerminalData,
);
