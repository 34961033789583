import { ToastrService } from 'ngx-toastr';

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { FormBaseComponent, FORM_FIELD_DEFAULT_OPTIONS, IFormFieldDefaultOptions } from '@bp/shared/components/core';
import { Validators } from '@bp/shared/features/validation';
import { ensureFormGroupConfig } from '@bp/shared/typings';

import { IResourceAccessOtpVerificationApiRequest } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-resource-access-otp-verification-form',
	templateUrl: './resource-access-otp-verification-form.component.html',
	styleUrls: [ './resource-access-otp-verification-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: FORM_FIELD_DEFAULT_OPTIONS,

			useValue: <IFormFieldDefaultOptions>{
				appearance: 'round',
				hideRequiredMarker: true,
			},
		},
	 ],
})
export class ResourceAccessOtpVerificationFormComponent
	extends FormBaseComponent<IResourceAccessOtpVerificationApiRequest> {

	@Input() resourceName!: string;

	override onSubmitShowInvalidInputsToast = false;

	constructor(
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(fb, cdr, toaster);

		this.form = this._formBuilder.group(
			ensureFormGroupConfig<IResourceAccessOtpVerificationApiRequest>({
				code: [ null, [ Validators.required ]],
			}),
		);
	}

}
