import { combineLatest, merge } from 'rxjs';

import { Injectable } from '@angular/core';

import { TelemetryService, UserIdleService, IntercomService, HelpHeroService, EnvironmentService, CloudflareAccessService } from '@bp/shared/services';
import { AnalyticsService, GoogleTagService, IGoogleTagGlobalVariables } from '@bp/shared/features/analytics';
import { Undefinable } from '@bp/shared/typings';
import { filterPresent } from '@bp/shared/rxjs';
import { SurvicateService } from '@bp/shared/features/survicate';

import { MerchantAdminFeature } from '@bp/shared-domains-identity';

import { AdminSharedAppStartupService } from '@bp/admins-shared/core/services';
import type { Identity } from '@bp/admins-shared/domains/identity';
import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { BridgerPspsSharedFacade } from '@bp/admins-shared/domains/bridger-psps';
import { SubscriptionPlansSharedFacade } from '@bp/admins-shared/domains/subscription-plans';

import { CurrentMerchantSubscriptionFacade } from '@bp/merchant-admin/domains/current-merchant-subscription';
import { CurrentMerchantFacade } from '@bp/merchant-admin/domains/current-merchant';
import { MerchantPspsFacade } from '@bp/merchant-admin/domains/merchant-psps';

@Injectable({ providedIn: 'root' })
export class AppStartupService {

	constructor(
		private readonly _adminSharedAppStartup: AdminSharedAppStartupService,
		private readonly _identityFacade: IdentityFacade,
		private readonly _currentMerchantFacade: CurrentMerchantFacade,
		private readonly _currentMerchantSubscriptionFacade: CurrentMerchantSubscriptionFacade,
		private readonly _bridgerPspsSharedFacade: BridgerPspsSharedFacade,
		private readonly _merchantPspsFacade: MerchantPspsFacade,
		private readonly _telemetry: TelemetryService,
		private readonly _userIdleService: UserIdleService,
		private readonly _intercomService: IntercomService,
		private readonly _survicateService: SurvicateService,
		private readonly _subscriptionPlansSharedFacade: SubscriptionPlansSharedFacade,
		private readonly _googleTagService: GoogleTagService,
		private readonly _analyticsService: AnalyticsService,
		private readonly _helpHeroService: HelpHeroService,
		private readonly _environmentService: EnvironmentService,
		private readonly _cloudflareAccessService: CloudflareAccessService,
	) { }

	init(): void {
		this._adminSharedAppStartup.init();

		this._userIdleService.init();

		this._intercomService.init();

		this._survicateService.init();

		this._analyticsService.init();

		if (this._environmentService.isRemoteServer)
			this._helpHeroService.init('F9vIngoHKb');

		if (this._environmentService.isStaging)
			this._cloudflareAccessService.whenUserUnauthorizedByCloudflareRedirectToCloudflareLoginPage();

		this._identityFacade.onIdentityFirstSet(identity => {
			this._currentMerchantFacade.load();

			this._currentMerchantFacade.startRefreshingInInterval();

			this._bridgerPspsSharedFacade.loadAll();

			this._subscriptionPlansSharedFacade.loadAll();

			this._currentMerchantSubscriptionFacade.load();

			this._associateUserWithTelemetry(identity);

			this._updateIntercomUser(identity);

			this._setSurvicateVisitorTraits(identity);
		});

		this._identityFacade.onIdentityLogout(() => {
			void this._intercomService.reboot();

			this._helpHeroService.cancelTour();

			this._currentMerchantFacade.stopRefreshingInInterval();

			this._currentMerchantFacade.resetState();

			this._currentMerchantSubscriptionFacade.resetState();

			this._merchantPspsFacade.resetState();

			this._bridgerPspsSharedFacade.resetState();

			this._subscriptionPlansSharedFacade.resetState();
		});

		this._whenUserLoggedInDispatchLoginGoogleTagEvent();

		this._whenMerchantRelatedInfoLoadedUpdateTelemetry();

		this._whenMerchantLoadedSetUserPermissionsBasedOnMerchantFeatureFlags();

		this._whenIdentityChangesSetGoogleTagVariables();
	}

	private _whenIdentityChangesSetGoogleTagVariables(): void {
		merge(
			this._identityFacade.incompleteIdentity$.pipe(filterPresent),
			this._identityFacade.user$,
		)
			.subscribe(identity => void this._setIdentityGoogleTagVariables({
				// eslint-disable-next-line @typescript-eslint/naming-convention
				user_id: identity?.id ?? undefined,
				userEmail: identity?.email,
				merchantId: identity?.merchantId ?? undefined,
			}));
	}

	private _associateUserWithTelemetry(user: Identity): void {
		this._telemetry.identifyUser(user.email);

		this._helpHeroService.identify(user.email, {
			role: user.role,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			created_at: user.created?.format('YYYY-MM-DD'),
		});
	}

	private readonly _updateIntercomUser = (user: Identity): void => void this._intercomService.update({
		userId: user.id,
		name: user.name ?? undefined,
		email: user.email,
	});

	private readonly _setSurvicateVisitorTraits = (user: Identity): void => void this._survicateService.setVisitorTraits({
		userId: user.id,
		name: user.name,
		email: user.email,
	});

	private _whenMerchantRelatedInfoLoadedUpdateTelemetry(): void {
		combineLatest([
			this._currentMerchantFacade.presentEntity$,
			this._currentMerchantSubscriptionFacade.entity$,
		])
			.subscribe(([ currentMerchant, currentSubscription ]) => {
				const companyTelemetry = {
					merchantId: currentMerchant.id ?? undefined,
					website: currentMerchant.company.website,
					plan: currentSubscription?.subscriptionPlan.type.displayName,
					monthlySpend: currentSubscription?.getMonthPrice(),
				};

				void this._intercomService.company({
					...companyTelemetry,
					id: currentMerchant.id ?? undefined,
					name: currentMerchant.company.name ?? currentMerchant.name ?? undefined,
				});

				void this._survicateService.setVisitorTraits({
					organization: currentMerchant.company.name ?? currentMerchant.name ?? undefined,
				});

				if (this._identityFacade.user?.email) {
					const userTelemetry = {
						...companyTelemetry,
						companyName: currentMerchant.company.name ?? currentMerchant.name ?? undefined,
					};

					this._telemetry.identifyUser(this._identityFacade.user.email, {
						...userTelemetry,
						userRole: this._identityFacade.user.role,
					});

					this._helpHeroService.update(userTelemetry);
				}
			});
	}

	private _setIdentityGoogleTagVariables(
		variables: Undefinable<Required<Pick<IGoogleTagGlobalVariables, 'merchantId' | 'user_id' | 'userEmail'>>>,
	): void {
		this._googleTagService.setGlobalVariables(variables);
	}

	private _whenUserLoggedInDispatchLoginGoogleTagEvent(): void {
		this._identityFacade.userLoggedIn$
			.subscribe(() => void this._googleTagService.dispatchEvent('login'));
	}

	private _whenMerchantLoadedSetUserPermissionsBasedOnMerchantFeatureFlags(): void {
		this._currentMerchantFacade.presentEntity$
			.subscribe(merchant => {
				if (merchant.allowPaywith)
					this._identityFacade.addOrganizationPermissions([ MerchantAdminFeature.paywith ]);
			});
	}
}
