import { ToastrService } from 'ngx-toastr';
import { exhaustMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiVoidResult } from '@bp/shared/models/common';

import { IdentityApiService } from '@bp/admins-shared/domains/identity';

import { changePasswordFailure, changePasswordSuccess } from './user-api.actions';
import { changePassword } from './user.actions';

@Injectable()
export class UserEffects {

	changePassword$ = createEffect(() => this._actions$.pipe(
		ofType(changePassword),
		exhaustMap(password => this._identityApi
			.changePassword(password)
			.pipe(apiVoidResult(changePasswordSuccess, changePasswordFailure))),
	));

	changePasswordSuccess$ = createEffect(
		() => this._actions$.pipe(
			ofType(changePasswordSuccess),
			tap(() => this._toaster.success('The password has been updated')),
		),
		{ dispatch: false },
	);

	constructor(
		private readonly _actions$: Actions,
		private readonly _identityApi: IdentityApiService,
		private readonly _toaster: ToastrService,
	) { }
}
