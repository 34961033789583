import { isNil } from 'lodash-es';
import type { Observable } from 'rxjs';
import { combineLatest, defer } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { createEffect, ofType } from '@ngrx/effects';

import type { IPageQueryParams, ISortQueryParams } from '@bp/shared/models/common';
import { apiResult, paginateArray, sortArray, RecordsPage } from '@bp/shared/models/common';
import type { Entity, IEntitiesApiService } from '@bp/shared/models/metadata';
import { filterPresent } from '@bp/shared/rxjs';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';

import type { EntitiesInMemoryPagedListFacade } from './entities-in-memory-paged-list.facade';
import { EntitiesListBaseEffects } from './entities-list-base.effects';

export abstract class EntitiesInMemoryPagedListEffects<
	TEntity extends Entity,
	TLoadQueryParams extends (IPageQueryParams & ISortQueryParams),
	TEntitiesFacade extends EntitiesInMemoryPagedListFacade<TEntity, TLoadQueryParams>
>
	extends EntitiesListBaseEffects<TEntity, TLoadQueryParams, TEntitiesFacade, IEntitiesApiService<TEntity, TLoadQueryParams>> {

	loadOnNavigationToRouteComponent$ = createEffect(() => defer(() => this.routeComponentActivation$
		.pipe(map(this.actions.loadAll))));

	getAllRecordsPage$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.loadAll, this.actions.refresh),
		switchMap(() => this._loadAll()
			.pipe(apiResult(this.actions.api.loadAllSuccess, this.actions.api.loadAllFailure))),
	));

	filterInMemory$ = createEffect(() => combineLatest([
		this._entitiesFacade.all$.pipe(filterPresent),
		this.apiQueryParamsWithoutPage$,
	])
		.pipe(map(([ all, queryParams ]) => this.actions.filteredInMemory({
			filtered: this._sortFilteredRecords(
				this._filterRecordsInMemoryOnQueryParamsChange([ ...all ], queryParams),
				queryParams,
			),
		}))));

	pageFilter$ = createEffect(() => combineLatest([
		this._entitiesFacade.filteredInMemory$.pipe(filterPresent),
		this.apiQueryParamsWithPage$.pipe(
			distinctUntilChanged((a, b) => a.limit === b.limit && a.page === b.page),
		),
	])
		.pipe(map(([ filteredRecords, query ]) => {
			const currentPage = query.page ? Number.parseInt(query.page) : 1;
			const hasNextPage = filteredRecords.length > query.limit * currentPage;

			return this.actions.filteredRecordsPage({
				recordsPage: new RecordsPage({
					firstPage: currentPage === 1,
					nextPageCursor: hasNextPage ? (currentPage + 1).toString() : null,
					records: paginateArray(filteredRecords, query),
				}),
			});
		})));

	protected abstract _loadQueryParamsFactory: (dto?: Partial<TLoadQueryParams>) => TLoadQueryParams;

	protected _defaultSortParams: ISortQueryParams<NonFunctionPropertyNames<TEntity>> | null = null;

	protected abstract _filterRecordsInMemoryOnQueryParamsChange(
		records: TEntity[],
		queryParams: TLoadQueryParams
	): TEntity[];

	protected _setDefaultSortParams(sortParams: ISortQueryParams<NonFunctionPropertyNames<TEntity>>): void {
		this._defaultSortParams = sortParams;
	}

	protected _sortFilteredRecords(
		records: TEntity[],
		sortParams?: ISortQueryParams,
	): TEntity[] {
		const sortingParams = sortParams?.sortField
			? sortParams
			: <ISortQueryParams> this._defaultSortParams;

		if (isNil(sortingParams))
			return records;

		return sortArray(records, sortingParams);
	}

	protected _loadAll(): Observable<RecordsPage<TEntity>> {
		return this._apiService
			.getRecordsPage(this._loadQueryParamsFactory(<TLoadQueryParams>{ limit: 999_999 }));
	}

}
