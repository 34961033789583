import { DTO } from '@bp/shared/models/metadata';
import { Dictionary } from '@bp/shared/typings';

import { SubscriptionPlanChargePeriod } from '../subscription-plan-charge-period.enum';
import { SubscriptionPlanPrice } from '../subscription-plan-price';

export type SubscriptionPlanPricePerChargePeriodMap = Map<SubscriptionPlanChargePeriod, SubscriptionPlanPrice>;

export function subscriptionPlanPricePerChargePeriodMapper(
	dtoOrMap: Dictionary<DTO<SubscriptionPlanPrice>> | SubscriptionPlanPricePerChargePeriodMap,
): SubscriptionPlanPricePerChargePeriodMap {
	return dtoOrMap instanceof Map
		? dtoOrMap
		: new Map(
			Object
				.entries(dtoOrMap)
				.map(([ chargePeriod, priceDto ]) => [
					SubscriptionPlanChargePeriod.parseStrict(chargePeriod),
					new SubscriptionPlanPrice(priceDto),
				]),
		);
}
