<mat-progress-bar *ngIf="userFacade.pending$ | async" @slide color="primary" mode="indeterminate"> </mat-progress-bar>

<div class="page-header right-drawer-header">
	<h1>Change Password</h1>
</div>

<content class="right-drawer-body gray">
	<bp-change-password-form
		class="card"
		[email]="(identityFacade.user$ | async)?.email"
		[pending]="userFacade.pending$ | async"
		[error]="userFacade.error$ | async"
		(submitted)="userFacade.changePassword($event)"
	>
	</bp-change-password-form>
</content>
