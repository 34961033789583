import m from 'moment';
import type { Observable } from 'rxjs';
import { timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { momentMapper } from '@bp/shared/models/metadata';

import { MomentBasePipe } from './moment-base.pipe';

@Pipe({
	name: 'momentIsSameOrBeforeNow',
})
export class MomentIsSameOrAfterNowPipe extends MomentBasePipe implements PipeTransform {
	transform(momentInput: m.MomentInput): Observable<boolean> {
		return this._momentTimezoneService.timezone$.pipe(
			switchMap(() => this._momentIsSameOrAfterNowCheckedEach30Seconds(momentInput)),
		);
	}

	private _momentIsSameOrAfterNowCheckedEach30Seconds(
		momentInput: m.MomentInput,
	): Observable<boolean> {
		const moment = momentMapper(momentInput);

		return timer(0, 1000 * 30)
			.pipe(map(() => moment.isSameOrBefore(m())));
	}
}
