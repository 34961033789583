import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BaseDialogComponent } from '@bp/shared/components/dialogs';
import { SLIDE_UP } from '@bp/shared/animations';
import { takeUntilDestroyed } from '@bp/shared/models/common';

import { FeaturePermission, MerchantAdminFeature } from '@bp/shared-domains-identity';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

export type ResourceAccessOtpVerificationDialogData = {
	featurePermission: FeaturePermission<MerchantAdminFeature>;
};

@Component({
	selector: 'bp-resource-access-otp-verification-dialog',
	templateUrl: './resource-access-otp-verification-dialog.component.html',
	styleUrls: [ './resource-access-otp-verification-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE_UP ],
})
export class ResourceAccessOtpVerificationDialogComponent extends BaseDialogComponent {
	constructor(
		public identityFacade: IdentityFacade,
		public override dialogRef: MatDialogRef<ResourceAccessOtpVerificationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public override data: ResourceAccessOtpVerificationDialogData,
	) {
		super(dialogRef, data);

		this.identityFacade.generateResourceAccessOtp(this.data.featurePermission.resourceName);

		this._closeDialogOnSuccessfulOtpVerification();
	}

	private _closeDialogOnSuccessfulOtpVerification(): void {
		this.identityFacade.resourceAccessOtpVerificationSuccess$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.dialogRef.close(true));
	}

	override canCloseWithTrueOnEnter(): boolean {
		return false;
	}
}
