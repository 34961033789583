import type { DTO } from '@bp/shared/models/metadata';
import {
	Control, Default, FieldControlType, FieldViewType, Hint, Label, MapFromDTO, Mapper, Table, View
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import { Article } from '@bp/shared/domains/articles';

import type { IPublishableNotifiableNews } from '@bp/firebase-functions';

import { NewsKind } from './news-kind';

export type NewsSummaryKeys = NonFunctionPropertyNames<NewsSummary>;

export class NewsSummary extends Article implements IPublishableNotifiableNews {

	@Hint('The youtube cover image will be used as the cover if youtube video URL provided')
	override youtubeVideoId!: string | null;

	@MapFromDTO()
	@View(FieldViewType.thumbnail)
	externalArticleResourceLogoImgUrl!: string | null;

	@MapFromDTO()
	@View(FieldViewType.link)
	externalArticleResourceUrl!: string | null;

	@Mapper(NewsKind)
	@Control(FieldControlType.select, {
		list: NewsKind.getList(),
		required: true,
	})
	@Table({ sortable: true })
	kind!: NewsKind;

	@Default(false)
	@Label('This Is Announcement')
	@Hint('The news will appear on the merchant admin announcements')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	// TODO: rename to showOnMerchantAdminAnnouncement
	showOnMerchantAdminWhatsNewHub!: boolean;

	@Default(false)
	@Label('Skip Publishing To The bridgerpay.com Newsroom')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	skipPublishingToPromoWebsite!: boolean;

	@Default(false)
	@Hint('The merchant admin user is gonna be explicitly notified')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	showNotification!: boolean;

	constructor(dto?: DTO<NewsSummary>) {
		super(dto);

		this.name = this.title;
	}

}
