import { ToastrService } from 'ngx-toastr';
import { map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, interval } from 'rxjs';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiResult } from '@bp/shared/models/common';
import { UserIdleService } from '@bp/shared/services';

import { VirtualTerminalData } from '@bp/admins-shared/core/models';

import { CurrentMerchantApiService } from '../services';

import { loadFailure, loadSuccess, loadVirtualTerminalDataFailure, loadVirtualTerminalDataSuccess, refreshFailure, refreshSuccess } from './current-merchant-api.actions';
import { load, loadVirtualTerminalData, refresh, startRefreshingInInterval, stopRefreshingInInterval } from './current-merchant.actions';
import { CurrentMerchantFacade } from './current-merchant.facade';

@Injectable()
export class CurrentMerchantEffects {

	load$ = createEffect(() => this._actions$.pipe(
		ofType(load),
		switchMap(() => this._currentMerchantApiService
			.getCurrent()
			.pipe(apiResult(loadSuccess, loadFailure))),
	));

	refresh$ = createEffect(() => this._actions$.pipe(
		ofType(refresh),
		switchMap(() => this._currentMerchantApiService
			.getCurrent()
			.pipe(apiResult(refreshSuccess, refreshFailure))),
	));

	refreshInInterval$ = createEffect(
		() => this._actions$.pipe(
			ofType(startRefreshingInInterval, stopRefreshingInInterval),
			switchMap(({ type }) => type === startRefreshingInInterval.type
				? this._userIdleService.active$.pipe(
					switchMap(isActive => isActive ? interval(10_000) : EMPTY),
				)
				: EMPTY),
			tap(() => void this._currentMerchantFacade.refresh()),
		),
		{ dispatch: false },
	 );

	loadFailure$ = createEffect(
		() => this._actions$.pipe(
			ofType(loadFailure, refreshFailure),
			tap(({ error, type }) => this._toaster.error(
				error.message,
				type,
				{ disableTimeOut: true },
			)),
		),
		{ dispatch: false },
	);

	loadVirtualTerminalData$ = createEffect(() => this._actions$.pipe(
		ofType(loadVirtualTerminalData),
		switchMap(() => this._currentMerchantApiService
			.getPaymentRoutes()
			.pipe(
				map(paymentRoutes => new VirtualTerminalData(paymentRoutes)),
				apiResult(loadVirtualTerminalDataSuccess, loadVirtualTerminalDataFailure),
			)),
	));

	constructor(
		private readonly _currentMerchantApiService: CurrentMerchantApiService,
		private readonly _currentMerchantFacade: CurrentMerchantFacade,
		private readonly _actions$: Actions,
		private readonly _toaster: ToastrService,
		private readonly _userIdleService: UserIdleService,
	) {
	}

}
