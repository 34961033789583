<ng-container *ngIf="MockedBackendState.isActiveAndNotDemoMode">
	<div *bpDelayedRender="200" @slide>
		<bp-select-field
			required
			appearance="standard"
			label="Login As Mock User"
			[items]="mockUserEmails"
			(valueChange)="introFacade.loginAsMockBackendUser($event)"
		>
			<span *bpSelectOption="let email"> {{ getMockUserFullname(email) }} </span>
		</bp-select-field>

		<bp-select-field
			required
			class="ml-4"
			panelClass="dev-workflows-select-panel"
			appearance="standard"
			label="Go To Intro Workflow"
			itemDisplayValuePropertyName="title"
			[items]="devLinks"
		>
			<a *bpSelectOption="let devLink" class="reset-default-decoration" [href]="devLink.url">
				{{ devLink.title }}
			</a>
		</bp-select-field>
	</div>
</ng-container>
