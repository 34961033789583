<bp-input
	class="w-100"
	[label]="label"
	[hint]="hint"
	[nativeAutocomplete]="nativeAutocomplete"
	[color]="color"
	[formControl]="_internalControl"
	[placeholder]="placeholder"
	[floatLabel]="floatLabel"
	[appearance]="appearance"
>
</bp-input>
