import { ChangeDetectionStrategy, Component, DoCheck } from '@angular/core';

import { FADE, FADE_IN, ROUTE_HIT_ANIMATIONS } from '@bp/shared/animations';
import { EnvironmentService } from '@bp/shared/services';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { LayoutFacade } from '@bp/admins-shared/features/layout';

import { CurrentMerchantFacade } from '@bp/merchant-admin/domains/current-merchant';
import { CurrentMerchantSubscriptionFacade } from '@bp/merchant-admin/domains/current-merchant-subscription';

@Component({
	selector: 'bp-root',
	templateUrl: './root.component.html',
	styleUrls: [ './root.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ ROUTE_HIT_ANIMATIONS, FADE, FADE_IN ],
})
export class RootComponent implements DoCheck {

	shouldAnimateOutlet!: boolean;

	constructor(
		public layoutFacade: LayoutFacade,
		public identityFacade: IdentityFacade,
		public currentMerchantFacade: CurrentMerchantFacade,
		public currentMerchantSubscriptionFacade: CurrentMerchantSubscriptionFacade,
		public environment: EnvironmentService,
	) { }

	// eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
	ngDoCheck(): void {
		// console.warn('DO CHECK');
	}
}
