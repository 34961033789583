<div
	*ngFor="let subscriptionPlan of subscriptionPlans"
	class="subscription-plan-overview-card {{ subscriptionPlan.type.kebabName }}"
	[class.active]="subscriptionPlan.id === currentSubscriptionPlan.id"
>
	<div class="header-block">
		{{ subscriptionPlan.name }}

		<div *ngIf="subscriptionPlan.isPro" class="most-popular-badge ml-auto">Most Popular</div>
	</div>

	<div class="price-block">
		<ng-container *ngIf="subscriptionPlan.isEnterprise; else defaultPriceTpl">
			<div>
				<bp-svg-icon name="enterprise-building"></bp-svg-icon>
			</div>

			<div class="transactions-limit">
				<span class="transactions-limit-number"> Unlimited </span> transactions
			</div>

			<div class="price-per-transaction">TBD / transaction</div>
		</ng-container>

		<ng-template #defaultPriceTpl>
			<div class="plan-price-per-month">
				<span class="plan-price">
					{{ subscriptionPlan.getMonthPriceFor(chargePeriod, currency)! | bpCurrency: currency }}
				</span>
				/ Month
			</div>

			<div class="transactions-limit">
				<span class="transactions-limit-number">
					{{ subscriptionPlan.limits.transactions | number }}
				</span>
				transactions / mo
			</div>

			<div class="price-per-transaction">
				then
				<span class="transaction-price">
					{{ subscriptionPlan.getTransactionPriceFor(chargePeriod, currency)! | bpCurrency: currency }}
				</span>
				each
			</div>

			<div *ngIf="subscriptionPlan.hasFlatPrice" class="charge-period">
				Billed {{ chargePeriod.displayName | lowercase }}
			</div>
		</ng-template>

		<div class="separator"></div>
	</div>

	<div class="action-block">
		<ng-template
			[ngTemplateOutlet]="subscriptionActionTemplate"
			[ngTemplateOutletContext]="{ $implicit: subscriptionPlan }"
		></ng-template>
	</div>

	<div class="outline-block">
		<div class="outline-text text-center mt-3">
			{{ subscriptionPlan.outline }}
		</div>

		<hr />
	</div>

	<div class="features-block">
		<header class="mb-3 font-weight-extra-bold">
			{{ subscriptionPlanFeatureBlockTitleMap.get(subscriptionPlan) }}
		</header>

		<ul class="flex-column">
			<ng-container *ngFor="let feature of subscriptionPlan.features">
				<li *ngIf="feature.displayName" class="flex-align-items-center">
					<span [innerHtml]="feature.displayName | bpWrapSubstringWithTag: '\\d free':'strong'"></span>

					<bp-svg-icon
						*ngIf="feature.description"
						name="more-info"
						class="ml-auto bp-icon-xxs"
						[bpTooltip]="feature.description"
					></bp-svg-icon>
				</li>
			</ng-container>
		</ul>
	</div>
</div>
