import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import type { News } from '@bp/shared/domains/newsroom';

import { load } from './announcement.actions';
import { getEntity, getLoading } from './announcement.selectors';

@Injectable({ providedIn: 'root' })
export class AnnouncementFacade {

	entity: News | null = null;

	readonly entity$ = this._store$.select(getEntity);

	readonly loading$ = this._store$.select(getLoading);

	constructor(protected _store$: Store) {
		this._setEntityPropertyOnStateChange();
	}

	load(id: string): void {
		this._store$.dispatch(load({ id }));
	}

	private _setEntityPropertyOnStateChange(): void {
		this.entity$.subscribe(entity => (this.entity = entity));
	}

}
