import { PaymentOptionType } from '@bp/shared/models/business';
import { Currency, ICurrency } from '@bp/shared/models/currencies';
import { DTO, FieldViewType, View, Control, Default, FieldControlType, Mapper } from '@bp/shared/models/metadata';

import { PspPaymentOptionBase } from './psp-payment-option-base';

export class ApmPspPaymentOption extends PspPaymentOptionBase {

	static type = PaymentOptionType.apm;

	readonly type = ApmPspPaymentOption.type;

	@Mapper(Currency)
	@Default([])
	@Control(
		FieldControlType.chip,
		{
			placeholder: 'Add currency...',
		},
	)
	currencies!: ICurrency[];

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	usedInPaywith!: boolean;

	constructor(dto?: DTO<ApmPspPaymentOption>) {
		super(dto);
	}

}
