<button
	mat-icon-button
	class="bp-icon-button-md"
	bpTooltip="Announcements"
	(click)="announcementsFacade.isOpened ? announcementsFacade.close() : announcementsFacade.open()"
>
	<bp-attention-drawing-dot
		*ngIf="(announcementsFacade.stateForCurrentUser$ | async)?.showNotificationDot"
		@fade
	></bp-attention-drawing-dot>
	<bp-svg-icon name="horn"></bp-svg-icon>
</button>
