
import { filter } from 'rxjs/operators';

import type { OnChanges, SimpleChanges } from '@angular/core';
import { Directive, ElementRef, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { isInstanceOf } from '@bp/shared/utilities';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

@Directive({
	selector: '[bpRouterLinkActiveOnIdInUrl]',
})
export class RouterLinkActiveOnIdInUrlDirective extends Destroyable implements OnChanges {

	@Input('bpRouterLinkActiveOnIdInUrl') id!: string | null;

	private readonly _activeClassName = 'active';

	constructor(
		private readonly _host: ElementRef<HTMLElement>,
		private readonly _router: Router,
	) {
		super();

		this._whenURLChangesUpdateHostActiveClass();
	}

	ngOnChanges({ id }: Partial<SimpleChanges>): void {
		if (id)
			this._toggleHostActiveClassOnIdInURL(this._router.url);
	}

	private _whenURLChangesUpdateHostActiveClass(): void {
		this._router.events
			.pipe(
				filter(isInstanceOf(NavigationEnd)),
				takeUntilDestroyed(this),
			)
			.subscribe(({ url }) => void this._toggleHostActiveClassOnIdInURL(url));
	}

	private _toggleHostActiveClassOnIdInURL(url: string): void {
		this._host.nativeElement.classList.toggle(
			this._activeClassName,
			url.includes(this.id!),
		);
	}
}
