import { Observable, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpResponse } from '@angular/common/http';

import { IdentityFacade, IDENTITY_API_AUTH_NAMESPACE } from '@bp/admins-shared/domains/identity';

@Injectable()
export class HttpPostRequestIdentityRefreshInterceptor implements HttpInterceptor {

	constructor(private readonly _identityFacade: IdentityFacade) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const shouldRefreshAccessTokenAfterSuccessPost = !request.url.includes(IDENTITY_API_AUTH_NAMESPACE) && [ 'DELETE', 'POST' ].includes(request.method);

		return next.handle(request)
			.pipe(tap(response => {
				if (shouldRefreshAccessTokenAfterSuccessPost && response instanceof HttpResponse)
					this._identityFacade.refreshAccessToken();
			}));
	}

}
