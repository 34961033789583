
import { IPageQueryParamsDTO, mixinPageQueryParams, QueryParamsBase, StringifyQuery } from '@bp/shared/models/common';
import { ISortQueryParamsDTO, mixinSortQueryParams } from '@bp/shared/models/metadata';

import { SubscriptionPlan } from './subscription-plan';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISubscriptionPlansQueryParams {
	wizardSetupId?: string;
}

type SubscriptionPlansQueryParamsDTO = IPageQueryParamsDTO
& ISortQueryParamsDTO
& StringifyQuery<ISubscriptionPlansQueryParams>;

class SubscriptionPlansQueryParamsBase extends QueryParamsBase<SubscriptionPlansQueryParamsDTO> { }

export class SubscriptionPlansQueryParams extends mixinPageQueryParams(
	mixinSortQueryParams(SubscriptionPlansQueryParamsBase, SubscriptionPlan),
) implements ISubscriptionPlansQueryParams {

	wizardSetupId?: string;

	constructor(dto?: SubscriptionPlansQueryParamsDTO) {
		super(dto);

		this.wizardSetupId = dto?.wizardSetupId;
	}

}
