import type { PspPaymentOptionType } from '@bp/shared/models/business';
import { Control, Default, FieldControlType, FieldViewType, Label, MetadataEntity, View } from '@bp/shared/models/metadata';

export abstract class BridgerPspPaymentOptionBase extends MetadataEntity {

	static readonly type: PspPaymentOptionType;

	abstract readonly type: PspPaymentOptionType;

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Label('Open Checkout In A New Tab')
	openInNewWindow!: boolean;

	@Default(null)
	credentialApiUrl!: string | null;

}
