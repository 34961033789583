import { RouteMetatags } from '@bp/shared/features/metatags';
import type { DTO } from '@bp/shared/models/metadata';
import { Required } from '@bp/shared/models/metadata';

import { NewsSummary } from './news-summary';

export class News extends NewsSummary {

	@Required()
	content!: string;

	constructor(dto?: DTO<News>) {
		super(dto);

		this.metatags = new RouteMetatags({
			title: `${ this.title } / Newsroom`,
			description: this.summary ?? undefined,
			type: 'article',
			image: this.coverImgUrl,
			url: `/newsroom/${ this.id }`,
			article: {
				publishedTime: this.publicationDate,
				modifiedTime: this.updatedAt,
			},
		});
	}

}
