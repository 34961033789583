<mat-progress-bar
	*ngIf="announcementsFacade.pending$ | async"
	@slide
	class="absolute"
	color="primary"
	mode="indeterminate"
></mat-progress-bar>

<bp-announcements-hub-item
	*ngFor="let announcement of announcementsFacade.records$ | async; trackBy: trackById"
	@hubItemFlyInOut
	(@hubItemFlyInOut.start)="showNoAnnouncements$.next(false)"
	(@hubItemFlyInOut.done)="showNoAnnouncements$.next(!announcementsFacade.records.length)"
	[announcement]="announcement"
>
</bp-announcements-hub-item>

<h1 *ngIf="showNoAnnouncements$ | async" @fadeIn class="text-center">No announcements</h1>
