import { compact, isArray, isEmpty, uniq } from 'lodash-es';

import { MASK_SUBSTITUTION_CHAR } from '@bp/shared/models/core';

import type { PaymentCardBrandPattern, PaymentCardBrandRangePattern } from './enums';
import { PaymentCardBrand } from './enums';

// inspired by https://github.com/braintree/credit-card-type/commit/8abd60b7be4b466807827584eb9956702742fbbb

type PaymentCardBrandMatch = [ cardBrand: PaymentCardBrand, matchStrength: number | null];

export class PaymentCards {

	private static readonly _testOrder = [
		PaymentCardBrand.visa,
		PaymentCardBrand.masterCard,
		PaymentCardBrand.amex,
		PaymentCardBrand.dinersclub,
		PaymentCardBrand.discover,
		PaymentCardBrand.jcb,
		PaymentCardBrand.unionpay,
		PaymentCardBrand.maestro,
		PaymentCardBrand.elo,
		PaymentCardBrand.mir,
		PaymentCardBrand.hiper,
		PaymentCardBrand.hipercard,
	];

	private static readonly _currentMonth = new Date().getMonth() + 1;

 	private static readonly _currentYear = Number(new Date()
		.getFullYear()
		.toString());

	static readonly maskDotChar = MASK_SUBSTITUTION_CHAR;

	static readonly brands = PaymentCardBrand.getList();

	static findBrand(name: string): PaymentCardBrand | null {
		return PaymentCardBrand.parse(name);
	}

	static guessBrandsByCardNumber(cardNumber: string): PaymentCardBrand[] {
		if (cardNumber.length === 0)
			return [];

		cardNumber = cardNumber.replace(/\D/gu, '');

		const cardBrandMatches = compact(
			this._testOrder.flatMap(
				testCardBrand => this._matchPaymentCardBrand(cardNumber, testCardBrand),
			),
		);

		const bestMatch = this._findBestMatch(cardBrandMatches);

		return bestMatch
			? [ bestMatch ]
			: uniq(cardBrandMatches.map(([ cardBrand ]) => cardBrand));
	}

	static guessBestMatchingBrandByCardNumber(cardNumber: string): PaymentCardBrand | null {
		const matches = this.guessBrandsByCardNumber(cardNumber);

		return matches.length === 1 ? matches[0] : null;
	}

	static formatMaskedCardNumber(number: string): string {
		return number
			.replace(/\s+/ug, '')
			.replace(/\*/ug, this.maskDotChar)
			.replace(new RegExp(`([\\d|${ this.maskDotChar }]{4})`, 'ug'), '$1 ')
			.trim();
	}

	static toExpireDateString(month: number, year: number): string {
		return `${ month < 10 ? '0' : '' }${ month } / ${ year }`;
	}

	static parseExpireDateString(expire: string = ''): { month: number; year: number } {
		const [ rawMonth, rawYear ] = <[ string, string ]> expire
			.replace(/\s/ug, '')
			.split('/');

		const month = Number(rawMonth);

		let year = rawYear && (rawYear.length === 2 || rawYear.length === 4)
			? Number(rawYear)
			: Number.NaN;

		if (year < 100)
			year += 2000;

		return { month, year };
	}

	static isExpired(expire: string): boolean {
		const { month, year } = this.parseExpireDateString(expire);

		return year < this._currentYear || year === this._currentYear && month < this._currentMonth;
	}

	private static _findBestMatch(cardBrandMatches: PaymentCardBrandMatch[]): PaymentCardBrand | null {
		if (!this._hasEnoughMatchingCardBrandsToDetermineBestMatch(cardBrandMatches))
			return null;

		return cardBrandMatches.reduce((bestCardBrandMatch, cardBrandMatch) => {
			if (bestCardBrandMatch[1]! < cardBrandMatch[1]!)
				return cardBrandMatch;

			return bestCardBrandMatch;
		})[0];
	}

	private static _hasEnoughMatchingCardBrandsToDetermineBestMatch(
		cardBrandMatches: PaymentCardBrandMatch[],
	): boolean {
		const numberOfCardBrandsMatchesWithMatchStrength = cardBrandMatches
			.filter(([ , matchStrength ]) => matchStrength !== null)
			.length;

		return numberOfCardBrandsMatchesWithMatchStrength > 0
			&& numberOfCardBrandsMatchesWithMatchStrength === cardBrandMatches.length;
	}

	private static _matchPaymentCardBrand(
		cardNumber: string,
		testCardBrand: PaymentCardBrand,
	): PaymentCardBrandMatch[] | null {
		const results: PaymentCardBrandMatch[] = [];

		for (const pattern of testCardBrand.scheme.patterns) {
			if (!this._matchCardNumberAgainstCardBrandPatterns(cardNumber, pattern))
				continue;

			const patternLength = isArray(pattern)
				? String(pattern[0]).length
				: String(pattern).length;

			results.push([
				testCardBrand,
				cardNumber.length >= patternLength ? patternLength : null,
			]);
		}

		return isEmpty(results) ? null : results;
	}

	private static _matchCardNumberAgainstCardBrandPatterns(
		cardNumber: string,
		cardBrandPattern: PaymentCardBrandPattern,
	): boolean {
		return isArray(cardBrandPattern)
			? this._matchCardNumberAgainstCardBrandRangePattern(cardNumber, cardBrandPattern)
			: this._matchCardNumberAgainstCardBrandRegularPattern(cardNumber, cardBrandPattern);
	}

	private static _matchCardNumberAgainstCardBrandRangePattern(
		cardNumber: string,
		[ startRange, endRange ]: PaymentCardBrandRangePattern,
	): boolean {
		const maxLengthToCheck = String(startRange).length;
  		const cardNumberMatchingSegment = cardNumber.slice(0, maxLengthToCheck);
		const integerCardNumberMatchingSegment = Number.parseInt(cardNumberMatchingSegment);

		const croppedStartRange = Number.parseInt(
			String(startRange).slice(0, cardNumberMatchingSegment.length),
		);
		const croppedEndRange = Number.parseInt(
			String(endRange).slice(0, cardNumberMatchingSegment.length),
		);

		return integerCardNumberMatchingSegment >= croppedStartRange
			&& integerCardNumberMatchingSegment <= croppedEndRange;
	}

	private static _matchCardNumberAgainstCardBrandRegularPattern(
		cardNumber: string,
		cardBrandRegularPattern: number,
	): boolean {
		const stringCardBrandRegularPattern = String(cardBrandRegularPattern);

		return cardNumber.startsWith(stringCardBrandRegularPattern)
			|| stringCardBrandRegularPattern.startsWith(cardNumber);
	}
}
