import type { Observable } from 'rxjs';
import { from, lastValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';

import { assertIsQueryParamsBased, RecordsPage } from '@bp/shared/models/common';
import type { DTO, IEntitiesFirebaseApiService } from '@bp/shared/models/metadata';
import { FirebaseService } from '@bp/shared/services';

import { NEWSROOM_COLLECTION_PATH } from '@bp/firebase-functions';

import type { NewsSummariesQueryParams } from '../models';
import { News } from '../models';

import { NewsSummariesApiService } from './news-summaries-api.service';

@Injectable({
	providedIn: 'root',
})
export class NewsApiService implements IEntitiesFirebaseApiService<News, NewsSummariesQueryParams> {

	readonly collectionPath = NEWSROOM_COLLECTION_PATH;

	constructor(
		private readonly _firebaseService: FirebaseService,
		private readonly _newsSummariesApiService: NewsSummariesApiService,
	) { }

	readonly factory = (dto: DTO<News>): News => new News(dto);

	listenToQueriedRecordsPageChanges(query?: NewsSummariesQueryParams): Observable<RecordsPage<News>> {
		assertIsQueryParamsBased(query);

		return this._firebaseService.listenToQueriedRecordsPageChanges(
			this.collectionPath,
			query,
			this.factory,
			this._newsSummariesApiService.newsSummariesFirestoreQueryComposer(query),
		);
	}

	get(id: string): Observable<News | null> {
		return this._firebaseService
			.getDocument(`${ this.collectionPath }/${ id }`, this.factory);
	}

	save(entity: News): Observable<News> {
		return from(this._save(entity));
	}

	private async _save(entity: News): Promise<News> {
		const news = this.factory({
			...entity,
			id: entity.id ?? await this._firebaseService.generateNewDocumentId(this.collectionPath),
		});

		const [ savedNews ] = await Promise.all([
			lastValueFrom(
				this._firebaseService
					.save(
						this.collectionPath,
						news,
						this.factory,
					),
			),
			lastValueFrom(
				this._firebaseService
					.save(
						this._newsSummariesApiService.collectionPath,
						this._newsSummariesApiService.factory(news),
						this._newsSummariesApiService.factory,
					),
			),
		]);

		return savedNews;
	}

	delete(news: News): Observable<void> {
		return from(this._delete(news));
	}

	private async _delete({ id }: News): Promise<void> {
		const [ batch, newsRef, newsSummaryRef ] = await Promise.all([
			this._firebaseService.batch(),
			this._firebaseService.getDocumentRef(`${ this.collectionPath }/${ id }`),
			this._firebaseService.getDocumentRef(`${ this._newsSummariesApiService.collectionPath }/${ id }`),
		]);

		await batch
			.delete(newsRef)
			.delete(newsSummaryRef)
			.commit();
	}
}
