<button
	mat-flat-button
	class="text-mediumgray"
	[class.active]="!isSelectedAnnuallyPeriod"
	(click)="chargePeriodChange.emit(SubscriptionPlanChargePeriod.monthly)"
>
	Pay monthly
</button>

<mat-slide-toggle [checked]="isSelectedAnnuallyPeriod" (change)="emitChargePeriodChange($event)"></mat-slide-toggle>

<button
	mat-flat-button
	class="text-mediumgray"
	[class.active]="isSelectedAnnuallyPeriod"
	(click)="chargePeriodChange.emit(SubscriptionPlanChargePeriod.annually)"
>
	Pay yearly
</button>
