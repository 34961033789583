import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';
import { PickBy } from '@bp/shared/typings';

import { CurrentMerchantSubscription } from '../../../../models';

@Component({
	selector: 'bp-current-subscription-limits-meter-popup',
	templateUrl: './current-subscription-limits-meter-popup.component.html',
	styleUrls: [ './current-subscription-limits-meter-popup.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSubscriptionLimitsMeterPopupComponent {

	@Input() currentMerchantSubscription!: CurrentMerchantSubscription;

	get currentSubscriptionPlan(): SubscriptionPlan {
		return this.currentMerchantSubscription.subscriptionPlan;
	}

	get featureLimitsMap(): PickBy<CurrentMerchantSubscription, 'featureLimitsMap'> {
		return this.currentMerchantSubscription.featureLimitsMap;
	}

}
