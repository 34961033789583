import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-paywall-badge',
	templateUrl: './paywall-badge.component.html',
	styleUrls: [ './paywall-badge.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaywallBadgeComponent {

}
