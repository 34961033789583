import luhn from 'fast-luhn';
import { range, reduce, min, max, isString } from 'lodash-es';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormFieldControlComponent } from '@bp/shared/components/core';
import { TextMask, TextMaskConfig } from '@bp/shared/features/text-mask';
import { IValidatorFunc, Validators } from '@bp/shared/features/validation';
import { Dictionary } from '@bp/shared/typings';
import { PaymentCardBrand, PaymentCards } from '@bp/shared/models/business';
import { FADE } from '@bp/shared/animations';
import { InputComponent } from '@bp/shared/components/controls';

@Component({
	selector: 'bp-payment-card-number-input',
	templateUrl: './payment-card-number-input.component.html',
	styleUrls: [ './payment-card-number-input.component.scss' ],
	animations: [ FADE ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PaymentCardNumberInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PaymentCardNumberInputComponent,
			multi: true,
		},
	],
})
export class PaymentCardNumberInputComponent extends FormFieldControlComponent<string | null> {

	@Input() skipLuhnValidation = false;

	@Output()
	readonly paymentCardBrandChange = new EventEmitter<PaymentCardBrand | null>();

	@ViewChild(InputComponent, { static: true }) private readonly _input!: InputComponent;

	mask = new TextMaskConfig({
		placeholderChar: TextMaskConfig.whitespace,
		placeholderFromMask: false,
		guide: false,
		maskOnFocus: false,
		mask: (numberInputValue: string) => this._buildMaskAccordingToValueLengthSplittedInGroupsOfFour(
			numberInputValue,
		),
	});

	paymentCardBrand: PaymentCardBrand | null = null;

	private readonly _defaultMinPaymentCardNumberLength = 12;

	private _minPaymentCardNumberLength: number = this._defaultMinPaymentCardNumberLength;

	private readonly _defaultMaxPaymentCardNumberLength = 19;

	private _maxPaymentCardNumberLength: number = this._defaultMaxPaymentCardNumberLength;

	private _masksCache: Dictionary<TextMask> = {};

	private readonly _cardNumberCharRegexp = new RegExp(`\\d|${ PaymentCards.maskDotChar }`, 'u');

	focus(): void {
		this._input.focus();
	}

	private readonly _buildMaskAccordingToValueLengthSplittedInGroupsOfFour = (numberInputValue: string): TextMask => {
		this._guessAndSetPaymentCardBrand(numberInputValue);

		const maxPaymentCardLength = Math.min(
			this._maxPaymentCardNumberLength,
			Math.max(this._minPaymentCardNumberLength, numberInputValue.length),
		);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		return this._masksCache[maxPaymentCardLength] || (this._masksCache[maxPaymentCardLength] = reduce(
			range(maxPaymentCardLength),
			(accumulator, _v, index) => {
				index++;

				return [
					...accumulator,
					this._cardNumberCharRegexp,
					...(index % 4 || index === maxPaymentCardLength ? [] : [ ' ' ]),
				];
			},
			<TextMask>[],
		));
	};

	// #region Implementation of the Validator interface

	protected override _validator: IValidatorFunc<string | null> | null = ({ value }) => {
		if (Validators.isEmptyValue(value))
			return null;

		return Validators.compose([
			Validators.minLength(this._minPaymentCardNumberLength),
			this._luhnValidation,
		])!({ value });
	};

	private readonly _luhnValidation: IValidatorFunc = ({ value }) => {
		if (this.skipLuhnValidation || this.paymentCardBrand?.scheme.skipLuhn)
			return null;

		if (!isString(value))
			throw new Error('`luhn` validator expects string to be validated');

		return luhn(value)
			? null
			: { ccNumber: true };
	};

	// #endregion Implementation of the Validator interface

	private _guessAndSetPaymentCardBrand(number: string): void {
		const guessedPaymentCardBrand = PaymentCards.guessBestMatchingBrandByCardNumber(number);

		this._setMinMaxPaymentCardNumberLengths(guessedPaymentCardBrand);

		if (guessedPaymentCardBrand !== this.paymentCardBrand) {
			this.paymentCardBrand = guessedPaymentCardBrand;

			this.paymentCardBrandChange.emit(guessedPaymentCardBrand);
		}
	}

	private _setMinMaxPaymentCardNumberLengths(guessedPaymentCardBrand: PaymentCardBrand | null): void {
		this._minPaymentCardNumberLength = guessedPaymentCardBrand
			? min(guessedPaymentCardBrand.scheme.lengths)!
			: this._defaultMinPaymentCardNumberLength;

		this._maxPaymentCardNumberLength = guessedPaymentCardBrand
			? max(guessedPaymentCardBrand.scheme.lengths)!
			: this._defaultMaxPaymentCardNumberLength;
	}
}
