import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IState, FEATURE_KEY } from './current-merchant-subscription.reducer';

const selectFeatureState = createFeatureSelector<IState>(FEATURE_KEY);

export const selectCurrentMerchantSubscription = createSelector(
	selectFeatureState,
	state => state.entity,
);

export const selectCurrentSubscriptionPlan = createSelector(
	selectCurrentMerchantSubscription,
	currentMerchantSubscription => currentMerchantSubscription?.subscriptionPlan,
);

export const selectCurrentChargePeriod = createSelector(
	selectCurrentMerchantSubscription,
	currentMerchantSubscription => currentMerchantSubscription?.chargePeriod,
);

export const selectCurrentMerchantSubscriptionLoading = createSelector(
	selectFeatureState,
	state => state.loading,
);

export const selectCurrentMerchantSubscriptionPending = createSelector(
	selectFeatureState,
	state => state.pending,
);

export const selectError = createSelector(
	selectFeatureState,
	state => state.error,
);
