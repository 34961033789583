import { createAction, props } from '@ngrx/store';

import { TELEMETRY_HIDDEN_MARK } from '@bp/shared/models/core';
import { SubscriptionPlanPurchase } from '@bp/shared/domains/subscription-plans/checkout';

import { DowngradeSubscriptionPlanApiRequest } from '../models';

export const ACTIONS_SCOPE = '[Domain][Current Merchant Subscription]';

export const load = createAction(
	`${ ACTIONS_SCOPE } Load`,
);

export const openBillingPortal = createAction(
	`${ ACTIONS_SCOPE } Open Billing Portal`,
);

export const loadBillingPortalDetails = createAction(
	`${ ACTIONS_SCOPE } Load Billing Portal Details`,
);

export const refresh = createAction(
	`${ ACTIONS_SCOPE } Refresh`,
);

export const purchaseSubscriptionPlan = createAction(
	`${ ACTIONS_SCOPE } Purchase Subscription Plan ${ TELEMETRY_HIDDEN_MARK }`,
	props<SubscriptionPlanPurchase>(),
);

export const downgradeSubscriptionPlan = createAction(
	`${ ACTIONS_SCOPE } Downgrade Subscription Plan`,
	props<DowngradeSubscriptionPlanApiRequest>(),
);

export const resetState = createAction(
	`${ ACTIONS_SCOPE } Reset State`,
);

export const resetError = createAction(
	`${ ACTIONS_SCOPE } Reset Error`,
);
