import { isArray } from 'lodash-es';

import type { IPageQueryParamsDTO, StringifyQuery } from '@bp/shared/models/common';
import { mixinPageQueryParams, QueryParamsBase } from '@bp/shared/models/common';
import { booleanMapper, ISortQueryParamsDTO, mixinSortQueryParams } from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities';

import { NewsKind } from './news-kind';
import { NewsSummary, NewsSummaryKeys } from './news-summary';
import { NewsStatus } from './news-status';

interface INewsSummariesQueryParams {

	search?: string;

	isPublished?: NewsStatus | boolean;

	kinds?: NewsKind[];

	showOnMerchantAdminWhatsNewHub?: boolean;

	skipPublishingToPromoWebsite?: boolean;

}

type NewsSummariesQueryParamsDTO = IPageQueryParamsDTO
& ISortQueryParamsDTO<NewsSummaryKeys>
& StringifyQuery<INewsSummariesQueryParams>;

class NewsSummariesQueryParamsBase extends QueryParamsBase<NewsSummariesQueryParamsDTO> { }

export class NewsSummariesQueryParams extends mixinPageQueryParams(
	mixinSortQueryParams(NewsSummariesQueryParamsBase, NewsSummary),
) implements INewsSummariesQueryParams {

	static published(dto: NewsSummariesQueryParamsDTO): NewsSummariesQueryParams {
		return new NewsSummariesQueryParams({
			...dto,
			isPublished: true,
			sortField: 'publicationDate',
		});
	}

	static publishedToPromo(dto: NewsSummariesQueryParamsDTO): NewsSummariesQueryParams {
		return new NewsSummariesQueryParams({
			...dto,
			isPublished: true,
			sortField: 'publicationDate',
			skipPublishingToPromoWebsite: false,
		});
	}

	search?: string;

	isPublished?: boolean;

	kinds?: NewsKind[];

	showOnMerchantAdminWhatsNewHub?: boolean;

	skipPublishingToPromoWebsite?: boolean;

	constructor(dto: NewsSummariesQueryParamsDTO) {
		super(dto);

		this.search = dto.search;

		if (dto.isPublished !== undefined)
			this.isPublished = this._parseIsPublished(dto.isPublished);

		if (dto.kinds !== undefined) {
			this.kinds = (isArray(dto.kinds) ? dto.kinds : dto.kinds.split(','))
				.map(v => NewsKind.parse(v))
				.filter(isPresent);
		}

		if (dto.showOnMerchantAdminWhatsNewHub !== undefined)
			this.showOnMerchantAdminWhatsNewHub = booleanMapper(dto.showOnMerchantAdminWhatsNewHub);

		if (dto.skipPublishingToPromoWebsite !== undefined)
			this.skipPublishingToPromoWebsite = booleanMapper(dto.skipPublishingToPromoWebsite);
	}

	private _parseIsPublished(isPublished: Required<NewsSummariesQueryParamsDTO['isPublished']>): boolean {
		const parsed = NewsStatus.parse(isPublished);

		if (parsed)
			return parsed === NewsStatus.published;

		return !!isPublished;
	}
}
