<mat-accordion>
	<mat-expansion-panel *ngFor="let faq of faqs" #panel hideToggle>
		<mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
			<mat-panel-title>{{ faq.question }}</mat-panel-title>

			<bp-plus-minus-btn [showMinus]="panel.expanded"></bp-plus-minus-btn>
		</mat-expansion-panel-header>

		<div class="answer" [innerHTML]="faq.answer"></div>
	</mat-expansion-panel>
</mat-accordion>
