
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BaseDialogComponent } from '@bp/shared/components/dialogs';
import { SLIDE_UP } from '@bp/shared/animations';

import { CURRENT_SUBSCRIPTION_MANAGEMENT_OVERLAY_PANE_CLASS } from '@bp/merchant-admin/domains/current-merchant-subscription';

// eslint-disable-next-line @typescript-eslint/ban-types
export type ResourcePaywallDialogData = {

};

@Component({
	selector: 'bp-resource-paywall-dialog',
	templateUrl: './resource-paywall-dialog.component.html',
	styleUrls: [ './resource-paywall-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE_UP ],
})
export class ResourcePaywallDialogComponent extends BaseDialogComponent {

	constructor(
		public override dialogRef: MatDialogRef<ResourcePaywallDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public override data: ResourcePaywallDialogData,
	) {
		super(dialogRef, data);

		this.dialogRef.addPanelClass(CURRENT_SUBSCRIPTION_MANAGEMENT_OVERLAY_PANE_CLASS);
	}

	override canCloseWithTrueOnEnter(): boolean {
		return false;
	}
}
