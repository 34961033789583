import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedPipesModule } from '@bp/shared/pipes';

import {
	FaqComponent, FeatureComparisonTableComponent, SubscriptionPlanLogosComponent, FeaturePresenceOrCountComponent
} from './components';

const EXPOSED = [
	FaqComponent,
	FeatureComparisonTableComponent,
	SubscriptionPlanLogosComponent,
	FeaturePresenceOrCountComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatExpansionModule,
		MatButtonModule,

		SharedComponentsCoreModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesTooltipModule,
		SharedPipesModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedDomainsSubscriptionPlansCoreModule {
}
