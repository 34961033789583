import { Directive, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { GoogleTagService } from '@bp/shared/features/analytics';
import { CalendlyDirective } from '@bp/shared/features/calendly';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { EnvironmentService } from '@bp/shared/services';

import { BookDemoCalendarKind, BookDemoCalendarKindLiterals, DEFAULT_BOOK_DEMO_CALENDAR_OPTIONS_PER_KIND, BOOK_DEMO_CALENDAR_OPTIONS_STAGING } from '../models';

@Directive({
	selector: '[bpCalendlyBookDemo]',
})
export class CalendlyBookDemoDirective extends CalendlyDirective {

	@Input('bpCalendlyBookDemo')

	get calendarKind(): BookDemoCalendarKind {
		return this._calendarKind;
	}

	set calendarKind(value: BookDemoCalendarKind | BookDemoCalendarKindLiterals) {
		this._calendarKind = BookDemoCalendarKind.parseStrict(value);

		this.options = this._environmentService.isStagingOrLocal
			? BOOK_DEMO_CALENDAR_OPTIONS_STAGING
			: DEFAULT_BOOK_DEMO_CALENDAR_OPTIONS_PER_KIND.get(this._calendarKind)!;
	}

	private _calendarKind!: BookDemoCalendarKind;

	constructor(
		private readonly _environmentService: EnvironmentService,
		private readonly _googleTagService: GoogleTagService,
		matDialog: MatDialog,
	) {
		super(matDialog);

		this._dispatchGoogleTagDemoBookedEventOnCalendlyBooked();
	}

	private _dispatchGoogleTagDemoBookedEventOnCalendlyBooked(): void {
		this.eventScheduled
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => {
				this._googleTagService.dispatchEvent('demo_booked');

				this._googleTagService.dispatchEvent(`demo_booked_${ <BookDemoCalendarKindLiterals> this.calendarKind.name }`);
			});
	}

}
