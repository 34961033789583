
import { createAction } from '@ngrx/store';

export const ACTIONS_SCOPE = '[Domain][Current Merchant]';

export const load = createAction(
	`${ ACTIONS_SCOPE } Load`,
);

export const refresh = createAction(
	`${ ACTIONS_SCOPE } Refresh`,
);

export const startRefreshingInInterval = createAction(
	`${ ACTIONS_SCOPE } Start Refreshing In Interval`,
);

export const stopRefreshingInInterval = createAction(
	`${ ACTIONS_SCOPE } Stop Refreshing In Interval`,
);

export const resetState = createAction(
	`${ ACTIONS_SCOPE } Reset State`,
);

export const loadVirtualTerminalData = createAction(
	`${ ACTIONS_SCOPE } Load Virtual Terminal Data`,
);
