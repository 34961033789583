import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedComponentsMetadataModule } from '@bp/shared/components/metadata';
import { SharedComponentsControlsModule } from '@bp/shared/components/controls';

import { AdminsSharedFeaturesLayoutModule } from '@bp/admins-shared/features/layout';

import { AdminsSharedPagesIdentityRoutingModule } from './admins-shared-pages-identity-routing.module';
import { ChangePasswordFormComponent, ChangePasswordPageComponent, IdentityPreferencesFormComponent, IdentityPreferencesPageComponent, IdentityPreferencesPortalTabPageComponent, IdentityPreferencesViewComponent } from './pages';
import { FEATURE_KEY, reducer, UserEffects } from './state';

@NgModule({
	imports: [
		CommonModule,
		CommonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressBarModule,
		MatTabsModule,
		MatButtonModule,
		RouterModule,

		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([ UserEffects ]),

		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		SharedComponentsMetadataModule,

		AdminsSharedFeaturesLayoutModule,

		AdminsSharedPagesIdentityRoutingModule,
	],
	declarations: [
		IdentityPreferencesPageComponent,
		IdentityPreferencesPortalTabPageComponent,
		ChangePasswordPageComponent,
		ChangePasswordFormComponent,
		IdentityPreferencesFormComponent,
		IdentityPreferencesViewComponent,
	],
})
export class AdminsSharedPagesIdentityModule { }
