import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';

import { FADE_IN_SEMI_SLOW } from '@bp/shared/animations';
import { PspPaymentOptionType } from '@bp/shared/models/business';
import { BridgerPsp } from '@bp/shared/domains/bridger-psps/core';

@Component({
	selector: 'bp-bridger-psps-multipicker',
	templateUrl: './bridger-psps-multipicker.component.html',
	styleUrls: [ './bridger-psps-multipicker.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_SEMI_SLOW ],
})
export class BridgerPspsMultipickerComponent implements OnInit {

	private readonly _selected$ = new BehaviorSubject<Set<BridgerPsp>>(new Set());

	@Input()
	get providers() {
		return this._providers;
	}

	set providers(value: BridgerPsp[] | null) {
		this._providers = value || [];
	}

	private _providers!: BridgerPsp[];

	@Input() selectable = true;

	@Input() paymentOptionType!: PspPaymentOptionType;

	@Input() initiallySelected?: BridgerPsp[] | null;

	@Output('selected') readonly selected$ = this._selected$.pipe(skip(1));

	get selected() {
		return this._selected$.value;
	}

	ngOnInit() {
		this._selected$.next(new Set(this.initiallySelected ?? []));
	}

	toggleProvider(provider: BridgerPsp) {
		if (this.selected.has(provider))
			this.selected.delete(provider);
		else
			this.selected.add(provider);

		this._selected$.next(new Set(this.selected));
	}

}
