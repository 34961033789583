import { Enumeration } from '@bp/shared/models/core/enum';

export class NewsKind extends Enumeration {

	static pressRelease = new NewsKind();

	static update = new NewsKind();

	static blogPost = new NewsKind();

	static feature = new NewsKind();

	static caseStudy = new NewsKind();

	// Static podcast = new NewsKind();

}
