import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { IdentityLoggedInGuard } from '@bp/shared-domains-identity';

import { rightDrawerRouteDataOptions } from '@bp/admins-shared/features/layout';

import {
	ChangePasswordPageComponent,
	IdentityPreferencesPageComponent,
	IdentityPreferencesPortalTabPageComponent
} from './pages';
import { IdentityPreferencesPageTab } from './models';

const routes: Routes = [
	{
		path: 'identity/preferences',
		component: IdentityPreferencesPageComponent,
		outlet: '[right-drawer]',
		canActivate: [ IdentityLoggedInGuard ],
		data: {
			title: 'Preference',

			...rightDrawerRouteDataOptions({
				drawerWithNavigationLevel: false,
			}),
		},
		children: [
			{
				path: IdentityPreferencesPageTab.portal.name,
				component: IdentityPreferencesPortalTabPageComponent,
				data: {
					title: IdentityPreferencesPageTab.portal.displayName,
				},
			},
			{
				path: '**',
				redirectTo: IdentityPreferencesPageTab.portal.name,
			},
		],
	},
	{
		path: 'identity/change-password',
		component: ChangePasswordPageComponent,
		outlet: '[right-drawer]',
		canActivate: [ IdentityLoggedInGuard ],
		data: {
			title: 'Change Password',
		},
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class AdminsSharedPagesIdentityRoutingModule {}
