import { isNil } from 'lodash-es';
import moment from 'moment';

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { SLIDE_RIGHT } from '@bp/shared/animations';
import { ControlComponent, DatepickerCalendarHeaderComponent } from '@bp/shared/components/core';
import type { DateRangeInput, DateRangeInputValue } from '@bp/shared/models/core';
import { DateRange } from '@bp/shared/models/core';
import { bpQueueMicrotask } from '@bp/shared/utilities';

@Component({
	selector: 'bp-date-range',
	templateUrl: './date-range.component.html',
	styleUrls: [ './date-range.component.scss' ],
	animations: [ SLIDE_RIGHT ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DateRangeComponent,
			multi: true,
		},
	],
})
export class DateRangeComponent extends ControlComponent<DateRange> {

	@Input() showDeleteSign = true;

	@Input() noFutureDates = true;

	@Input() interactive = true;

	@Input() panelClass!: string;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	DatepickerCalendarHeaderComponent = DatepickerCalendarHeaderComponent;

	@HostBinding('class.empty') override get empty() {
		return this.value.empty;
	}

	override value = new DateRange();

	currentDay = moment();

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value: DateRangeInputValue): void {
		bpQueueMicrotask(() => {
			this.value = DateRange.parse(value);

			this._cdr.markForCheck();
		});
	}
	// #endregion Implementation of the ControlValueAccessor interface

	patch(v: DateRangeInput | null) {
		if (isNil(v)) {
			this.setValue(new DateRange());

			return;
		}

		this.setValue(new DateRange({
			...this.value,
			...v,
			to: isNil(v.to) ? null : moment(v.to).endOf('day'),
		}));
	}
}
