import { Alias, Default, DTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';
import { SubscriptionPlanChargePeriod, SubscriptionFeaturesLimitsCounters } from '@bp/shared/domains/subscription-plans/core';
import { Currency } from '@bp/shared/models/currencies';

export class MerchantSubscription extends MetadataEntity {

	@Alias('subscriptionId')
	subscriptionPlanId!: string;

	@Mapper(SubscriptionPlanChargePeriod)
	chargePeriod!: SubscriptionPlanChargePeriod;

	@Mapper(Currency)
	currency!: Currency;

	@Mapper(SubscriptionFeaturesLimitsCounters)
	limitsCounters!: SubscriptionFeaturesLimitsCounters;

	/**
	 * if no credit card collected I assume there is no stripe subscription at this point.
	 */
	@Default(null)
	pspSubscriptionId!: string | null;

	get hasBillingPortal(): boolean {
		return !!this.pspSubscriptionId;
	}

	constructor(dto?: DTO<MerchantSubscription>) {
		super(dto);
	}

}
