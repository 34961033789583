import { Currency, CurrencyCode } from '@bp/shared/models/currencies';
import { Dictionary } from '@bp/shared/typings';

export type PricePerCurrencyMap = Map<Currency, number>;

export function pricePerCurrencyMapper(dtoOrMap: Dictionary<number> | PricePerCurrencyMap): PricePerCurrencyMap {
	return dtoOrMap instanceof Map
		? dtoOrMap
		: new Map(
			Object
				.entries(dtoOrMap)
				.map(([ currency, price ]) => [
					new Currency(<CurrencyCode>currency),
					Number(price),
				]),
		);
}
