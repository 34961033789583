import type { AbstractControl, ValidatorFn } from '@angular/forms';

import type { NonFunctionPropertyNames } from './typings';

export type FormBuilderValue = [ any, (ValidatorFn | ValidatorFn[])?];

// eslint-disable-next-line @typescript-eslint/ban-types
export type FormGroupConfig<T extends {}, U = FormBuilderValue> = {
	[ K in NonFunctionPropertyNames<T> ]?: T[ K ] extends never ? never : U
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FormControls<T extends {} > = {
	[ K in NonFunctionPropertyNames<T> ]?: T[ K ] extends never ? never : AbstractControl
};

export function ensureFormGroupConfig<T>(config: FormGroupConfig<T>): FormGroupConfig<T> {
	return config;
}
