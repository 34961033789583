import { camelCase, mapKeys } from 'lodash-es';

import type { DTO } from '@bp/shared/models/metadata';
import { isEmpty, tryDecodeBase64 } from '@bp/shared/utilities';

import type { BankAccountRequisites } from './bank-account-requisites';
import type { IWireTransferPaymentOption } from './wire-transfer-payment-option.interface';

export function wireTransferJsonPropertyDtoSourceMapper(jsonValues: string[]): DTO<IWireTransferPaymentOption> {
	if (isEmpty(jsonValues))
		return {};

	const dtos = jsonValues.map((dto): DTO<BankAccountRequisites> | DTO<IWireTransferPaymentOption> => JSON.parse(
		tryDecodeBase64(dto),
	));

	if (isEmpty(dtos))
		return {};

	const paymentOptionProperty: keyof IWireTransferPaymentOption = 'requisites';
	const [ possiblePaymentOption ] = dtos;

	if (paymentOptionProperty in possiblePaymentOption)
		return possiblePaymentOption;

	return {
		requisites: dtos.map(dto => mapKeys(dto, (_v, k) => camelCase(k))),
	};
}
