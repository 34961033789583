import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DTO } from '@bp/shared/models/metadata';
import { mapTo } from '@bp/shared/rxjs';
import { SubscriptionPlanPurchase } from '@bp/shared/domains/subscription-plans/checkout';

import { DowngradeSubscriptionPlanApiRequest, MerchantBillingPortal } from '../models';

@Injectable({
	providedIn: 'root',
})
export class CurrentMerchantSubscriptionApiService {

	private readonly _namespace = 'merchant';

	constructor(
		private readonly _http: HttpClient,
	) { }

	purchaseSubscriptionPlan(request: SubscriptionPlanPurchase): Observable<void> {
		return this._http
			.post<void>(`${ this._namespace }/change-subscription`, request);
	}

	downgradeSubscription(request: DowngradeSubscriptionPlanApiRequest): Observable<void> {
		return this._http
			.post<void>(`${ this._namespace }/change-subscription`, request);
	}

	loadBillingPortalDetails(): Observable<MerchantBillingPortal> {
		return this._http
			.get<DTO<MerchantBillingPortal>>(`${ this._namespace }/billing-portal`)
			.pipe(mapTo(MerchantBillingPortal));
	}

}
