import { sortBy, uniqBy } from 'lodash-es';

import { PaymentCardBrand, PspPaymentOptionType } from '@bp/shared/models/business';
import type { ICurrency } from '@bp/shared/models/currencies';
import { currenciesFactory } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import {
	booleanMapper, Control, Default, Entity, FieldControlType, FieldViewType, Hint, Label, MapFromDTO, Mapper, Table, Unserializable, View,
	ViewEmptyValue
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import type { INamedEntitySummary } from '@bp/shared/models/core';
import { BridgerPsp, BridgerPspPaymentOptions } from '@bp/shared/domains/bridger-psps/core';

import { PspStatus } from '../enums';
import { ApiPropertiesMetadataSection } from '../api-property-metadata';

import { PspCredential } from './psp-credential';

export type MerchantPspKeys = NonFunctionPropertyNames<MerchantPsp>;

export class MerchantPsp extends Entity {

	@Label('Name')
	@MapFromDTO()
	@Table()
	pspName!: string;

	@Default(PspStatus.active)
	@Mapper(PspStatus)
	// @Table({ sortable: true })
	@View(FieldViewType.status)
	status!: PspStatus;

	@Mapper(PspPaymentOptionType)
	@Table()
	type!: PspPaymentOptionType;

	@Default([])
	@Mapper(PspCredential)
	@Table()
	credentials!: PspCredential[];

	/**
	 * Present only on credit card psps
	 */
	@Control(FieldControlType.brandChips) // Items source is added lazily
	@Mapper(PaymentCardBrand)
	@ViewEmptyValue('Any')
	@Default([])
	brands!: PaymentCardBrand[];

	@Control(
		FieldControlType.chip, // Items source is added lazily
		{
			placeholder: 'Add currency...',
		},
	)
	@Mapper(currenciesFactory)
	@ViewEmptyValue('Any')
	@Default([])
	currencies!: ICurrency[];

	@Control(FieldControlType.switch)
	@Default(false)
	@Hint('By enabling this flag, the system will process transactions based on currencies of credentials. Otherwise, it will process transactions through all credentials.')
	@Label('Filter Credentials By Currency')
	@Mapper(booleanMapper)
	@View(FieldViewType.boolean)
	filterMidByCurrency!: boolean;

	@Control(FieldControlType.switch)
	@Default(false)
	@Hint('By enabling this flag, the system will not use a currency of the country for processing transactions. If there is a mid with the specific currency, the system will use that mid\'s currency during the deposit.')
	@Mapper(booleanMapper)
	@View(FieldViewType.boolean)
	bypassCurrencyByCountry!: boolean;

	get displayName(): string {
		return `${ this.pspName } ${ this.type.displayName }`;
	}

	get isCreditCard(): boolean {
		return this.type === PspPaymentOptionType.creditCard;
	}

	@Mapper(ApiPropertiesMetadataSection)
	propertiesMetadata!: ApiPropertiesMetadataSection[];

	@Label('Payment Routes Participation')
	@Table()
	@Unserializable()
	@ViewEmptyValue('Not attached to any route')
	readonly paymentRoutes: INamedEntitySummary[];

	get isUsedInPaymentRoutes(): boolean {
		return this.paymentRoutes.length > 0;
	}

	@Unserializable()
	@Default(null)
	bridgerPsp!: BridgerPsp | null;

	@Unserializable()
	bridgerPspPaymentOption: BridgerPspPaymentOptions | null;

	@Default(false)
	@View(FieldViewType.boolean)
	@Unserializable()
	readonly canBeUsedInPaywith: boolean;

	constructor(dto?: DTO<MerchantPsp>) {
		super(dto);

		this.name = this.pspName;

		this.credentials = this._sortCredentials();

		this.paymentRoutes = this._aggregateUniqCredentialsPaymentRoutes();

		this.bridgerPspPaymentOption = this.bridgerPsp?.paymentOptions.find(v => v.type === this.type) ?? null;

		this.canBeUsedInPaywith = !!this.bridgerPsp?.someOptionCanBeUsedInPaywith;

		this._logicallyBindIsEnabledAndStatus();
	}

	private _logicallyBindIsEnabledAndStatus(): void {
		if (this.isEnabled === null)
			this.isEnabled = this.status === PspStatus.active;
		else
			this.status = this.isEnabled ? PspStatus.active : PspStatus.disabled;
	}

	private _sortCredentials(): PspCredential[] {
		return sortBy(
			this.credentials,
			v => !v.isEnabled,
		);
	}

	private _aggregateUniqCredentialsPaymentRoutes(): INamedEntitySummary[] {
		return uniqBy(
			this.credentials.flatMap(v => v.paymentRoutes),
			it => it.id,
		);
	}

}
