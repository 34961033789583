import type { DTO } from '@bp/shared/models/metadata';
import { isEmpty, tryDecodeBase64 } from '@bp/shared/utilities';

import type { IExternalLinkPaymentOption } from './external-link-payment-option.interface';

export function externalLinkJsonPropertyDtoSourceMapper(jsonValues: string[]): DTO<IExternalLinkPaymentOption> {
	if (isEmpty(jsonValues))
		return {};

	return JSON.parse(tryDecodeBase64(jsonValues[0]));
}
