<bp-input
	name="cc-name"
	[label]="label"
	[color]="color"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
	[hideClearButton]="hideClearButton"
	[required]="!!required"
	[hint]="hint"
	[pending]="pending"
	[nativeAutocomplete]="nativeAutocomplete"
	[mask]="mask"
	[formControl]="_internalControl"
	[isSecret]="true"
>
</bp-input>
