<bp-editable-view-card #card>
	<bp-identity-preferences-view *ngIf="card.view" [entity]="identityPreferencesFacade.identityPreferences$ | async">
	</bp-identity-preferences-view>

	<bp-identity-preferences-form
		*ngIf="card.edit"
		[entity]="identityPreferencesFacade.identityPreferences$ | async"
		(entityChange)="identityPreferencesFacade.update($event!)"
	>
	</bp-identity-preferences-form>
</bp-editable-view-card>
