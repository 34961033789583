import { distinctUntilChanged, first, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import {
	Directive,
	EmbeddedViewRef,
	Host,
	Input,
	OnInit,
	Optional,
	TemplateRef,
	ViewContainerRef
} from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

import { Feature, HasAccessStructuralDirective, Permission } from '@bp/shared-domains-identity';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { CurrentMerchantSubscriptionFacade } from '@bp/merchant-admin/domains/current-merchant-subscription';

@Directive({
	selector: '[bpBehindPaywall]',
	exportAs: 'behindPaywall',
})
export class BehindPaywallStructuralDirective extends Destroyable implements OnInit {
	@Input()
	bpBehindPaywall: Permission | '' | undefined;

	@Input()
	set bpBehindPaywallElse(templateRef: TemplateRef<any> | null) {
		this._notBehindPaywallTplRef = templateRef;

		this._updateEmbeddedViewAccordingToPermissionState(this._isPermissionBehindPaywall);
	}

	private _permission!: Permission;

	private _isPermissionBehindPaywall = false;

	private _notBehindPaywallTplRef: TemplateRef<any> | null = null;

	private _behindPaywallEmbeddedViewRef: EmbeddedViewRef<any> | null = null;

	private _notBehindPaywallEmbeddedViewRef: EmbeddedViewRef<any> | null = null;

	constructor(
		private readonly _viewContainerRef: ViewContainerRef,
		private readonly _behindPaywallTplRef: TemplateRef<any>,
		private readonly _identityFacade: IdentityFacade,
		private readonly _currentMerchantSubscription: CurrentMerchantSubscriptionFacade,
		@Host()
		@Optional()
		private readonly _hasAccessDirective?: HasAccessStructuralDirective,
	) {
		super();
	}

	ngOnInit(): void {
		this._permission = this._getPermission();

		this._whenFeatureBehindPaywallStateChangeUpdateEmbeddedView();
	}

	private _getPermission(): Permission {
		const permission = Feature.isValid(this.bpBehindPaywall)
			? this.bpBehindPaywall
			: this._hasAccessDirective?.trackedPermission ?? null;

		this._assertPermissionType(permission);

		return permission;
	}

	private _whenFeatureBehindPaywallStateChangeUpdateEmbeddedView(): void {
		combineLatest([ this._identityFacade.user$, this._currentMerchantSubscription.presentEntity$.pipe(first()) ])
			.pipe(
				map(([ user ]) => !!user?.featurePermissions.get(this._permission)?.isBehindPaywall),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(permissionIsBehindPaywall => {
				this._isPermissionBehindPaywall = permissionIsBehindPaywall;

				this._updateEmbeddedViewAccordingToPermissionState(permissionIsBehindPaywall);
			});
	}

	private _updateEmbeddedViewAccordingToPermissionState(hasAccess: boolean): void {
		if (hasAccess)
			this._createBehindPaywallEmbeddedView();
		else
			this._tryCreateNotBehindPaywallEmbeddedView();
	}

	private _createBehindPaywallEmbeddedView(): void {
		if (this._behindPaywallEmbeddedViewRef)
			return;

		this._viewContainerRef.clear();

		this._notBehindPaywallEmbeddedViewRef = null;

		this._behindPaywallEmbeddedViewRef = this._viewContainerRef.createEmbeddedView(this._behindPaywallTplRef);

		this._behindPaywallEmbeddedViewRef.detectChanges();
	}

	private _tryCreateNotBehindPaywallEmbeddedView(): void {
		if (this._notBehindPaywallEmbeddedViewRef)
			return;

		this._viewContainerRef.clear();

		this._behindPaywallEmbeddedViewRef = null;

		if (this._notBehindPaywallTplRef) {
			this._notBehindPaywallEmbeddedViewRef = this._viewContainerRef.createEmbeddedView(
				this._notBehindPaywallTplRef,
			);

			this._notBehindPaywallEmbeddedViewRef.detectChanges();
		}
	}

	private _assertPermissionType(value: any): asserts value is Permission {
		if (Feature.isValid(value))
			return;

		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		throw new Error(
			`Directive accepts only enumerations \`BaseFeature\` or \`FeatureAction\` but got \`${ value }\``,
		);
	}
}
