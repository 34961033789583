import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { SubscriptionPlanChargePeriod, SubscriptionPlan, getMaxAnnualDiscount } from '@bp/shared/domains/subscription-plans/core';
import { Currency } from '@bp/shared/models/currencies';
import { OnChanges, SimpleChanges } from '@bp/shared/models/core';

import { CurrentMerchantSubscription } from '../../../../models';

@Component({
	selector: 'bp-subscription-plans-overview',
	templateUrl: './subscription-plans-overview.component.html',
	styleUrls: [ './subscription-plans-overview.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPlansOverviewComponent implements OnInit, OnChanges {

	@Input() isUpgradeMode!: boolean;

	@Input()
	get currentMerchantSubscription(): CurrentMerchantSubscription {
		return this._currentMerchantSubscription;
	}

	set currentMerchantSubscription(value: CurrentMerchantSubscription) {
		this._currentMerchantSubscription = value;

		this._setOppositeChargePeriodToCurrent();
	}

	private _currentMerchantSubscription!: CurrentMerchantSubscription;

	@Input() subscriptionPlans!: SubscriptionPlan[];

	@Output() readonly executeSubscriptionPlanAction = new EventEmitter<SubscriptionPlan>();

	@Output() readonly selectedChargePeriodChange = new EventEmitter<SubscriptionPlanChargePeriod>();

	@Output() readonly selectedCurrencyChange = new EventEmitter<Currency>();

	get currentSubscriptionPlan(): SubscriptionPlan {
		return this.currentMerchantSubscription.subscriptionPlan;
	}

	selectedCurrency = new Currency('USD');

	// eslint-disable-next-line @typescript-eslint/naming-convention
	SubscriptionPlanChargePeriod = SubscriptionPlanChargePeriod;

	selectedChargePeriod = SubscriptionPlanChargePeriod.annually;

	oppositeChargePeriodToCurrent!: SubscriptionPlanChargePeriod;

	maxAnnualDiscount!: number;

	subscriptionPlanActionLabelMap = new Map<SubscriptionPlan, string>();

	ngOnChanges({ subscriptionPlans, currentSubscriptionPlan }: SimpleChanges<this>): void {
		if (subscriptionPlans)
			this._updateAnnualDiscount();

		if (currentSubscriptionPlan || subscriptionPlans)
			this._setSubscriptionActionLabelMap();
	}

	castToSubscription(value: unknown): SubscriptionPlan {
		return <SubscriptionPlan>value;
	}

	ngOnInit(): void {
		this.selectedCurrencyChange.emit(this.selectedCurrency);
	}

	switchToOppositeChargePeriod(subscriptionPlan: SubscriptionPlan): void {
		this.selectedChargePeriodChange.emit(this.oppositeChargePeriodToCurrent);

		this.executeSubscriptionPlanAction.emit(subscriptionPlan);
	}

	changeSubscriptionPlan(subscriptionPlan: SubscriptionPlan): void {
		this.selectedChargePeriodChange.emit(this.selectedChargePeriod);

		this.executeSubscriptionPlanAction.emit(subscriptionPlan);
	}

	private _setOppositeChargePeriodToCurrent(): void {
		this.oppositeChargePeriodToCurrent = this.currentMerchantSubscription.chargePeriod === SubscriptionPlanChargePeriod.annually
			? SubscriptionPlanChargePeriod.monthly
			: SubscriptionPlanChargePeriod.annually;
	}

	private _setSubscriptionActionLabelMap(): void {
		const currentSubscriptionPlanIndex = this.subscriptionPlans.findIndex(
			subscription => subscription.id === this.currentSubscriptionPlan.id,
		);

		this.subscriptionPlanActionLabelMap = new Map(this.subscriptionPlans.map((subscriptionPlan, index) => [
			subscriptionPlan,
			index < currentSubscriptionPlanIndex ? 'Downgrade' : 'Upgrade now!',
		]));
	}

	private _updateAnnualDiscount(): void {
		this.maxAnnualDiscount = getMaxAnnualDiscount(this.subscriptionPlans);
	}

}
