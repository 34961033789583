import { PaymentCards } from '@bp/shared/models/business';
import {
	Control, Default, DTO, FieldControlType, Label, Mapper, MetadataEntity, Required, Secret
} from '@bp/shared/models/metadata';
import { Country, countryMapper, State } from '@bp/shared/models/countries';

export class SubscriptionPlanCheckoutPaymentInformationFormVm extends MetadataEntity {

	@Label('Cardholder Name')
	@Control(FieldControlType.input, {
		required: true,
		typeControlOptions: {
			maxLength: 255,
		},
	})
	@Secret()
	paymentCardHolderName!: string;

	@Label('Card Number')
	@Required()
	@Secret()
	paymentCardNumber!: string;

	/**
	 * Separate property for the expiry `month / year` control, since its more handy when the control operates with
	 * one string value
	 */
	@Label('MM / YY')
	@Required()
	@Secret()
	paymentCardExpirationDate!: string;

	@Label('CVV')
	@Required()
	@Secret()
	paymentCardCvv!: number;

	@Label('Company Billing Address')
	@Control(FieldControlType.input, {
		required: true,
		nativeAutocomplete: 'street-address',
	})
	address!: string;

	@Control(FieldControlType.input, {
		nativeAutocomplete: 'address-level2',
	})
	city!: string;

	@Label('Postcode / ZIP')
	@Control(FieldControlType.input, {
		nativeAutocomplete: 'postal-code',
	})
	zipCode!: string;

	@Default(null)
	@Mapper(countryMapper)
	@Control(FieldControlType.country, {
		required: true,
		typeControlOptions: {
			hasWorldwide: false,
		},
	})
	country!: Country | null;

	@Label('State')
	@Default(null)
	@Control(FieldControlType.autocomplete, {
		required: true,
		nativeAutocomplete: 'address-level1',
	})
	state!: State | null;

	@Label('VAT ID')
	@Control(FieldControlType.input)
	vatId?: string;

	readonly paymentCardExpireMonth!: number;

	readonly paymentCardExpireYear!: number;

	constructor(dto?: DTO<SubscriptionPlanCheckoutPaymentInformationFormVm>) {
		super(dto);

		this._setExpireMonthAndYearExtractedFromExpireProperty();

	}

	private _setExpireMonthAndYearExtractedFromExpireProperty(): void {
		if (!this.paymentCardExpirationDate)
			return;

		const { month, year } = PaymentCards.parseExpireDateString(this.paymentCardExpirationDate);

		// @ts-expect-error - we know that the property is set by the constructor
		this.paymentCardExpireMonth = month;

		// @ts-expect-error - we know that the property is set by the constructor
		this.paymentCardExpireYear = year;
	}

}
