import { createSelector } from '@ngrx/store';

import { getEntityState } from '../feature.selectors';

export const getEntity = createSelector(
	getEntityState,
	s => s.entity,
);

export const getLoading = createSelector(
	getEntityState,
	s => s.loading,
);
