import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload, ResultActionPayload } from '@bp/shared/models/common';
import { SubscriptionPlanPurchase } from '@bp/shared/domains/subscription-plans/checkout';

import { CurrentMerchantSubscription, MerchantBillingPortal } from '../models';

import { ACTIONS_SCOPE } from './current-merchant-subscription.actions';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadSuccess = createAction(
	`${ scope } Load Success`,
	props<ResultActionPayload<CurrentMerchantSubscription>>(),
);

export const loadFailure = createAction(
	`${ scope } Load Failure`,
	props<ErrorActionPayload>(),
);

type PurchaseSubscriptionPlanActionPayload = { request: SubscriptionPlanPurchase };

export const purchaseSubscriptionPlanSuccess = createAction(
	`${ scope } Purchase Subscription Plan Success`,
	props<PurchaseSubscriptionPlanActionPayload>(),
);

export const purchaseSubscriptionPlanFailure = createAction(
	`${ scope } Purchase Subscription Plan Failure`,
	props<ErrorActionPayload & PurchaseSubscriptionPlanActionPayload>(),
);

export const downgradeSubscriptionPlanFailure = createAction(
	`${ scope } Downgrade Subscription Plan Failure`,
	props<ErrorActionPayload>(),
);
export const downgradeSubscriptionPlanSuccess = createAction(
	`${ scope } Downgrade Subscription Plan Success`,
);

export const loadBillingPortalDetailsSuccess = createAction(
	`${ ACTIONS_SCOPE } Load Billing Portal Details Success`,
	props<ResultActionPayload<MerchantBillingPortal>>(),
);

export const loadBillingPortalDetailsFailure = createAction(
	`${ ACTIONS_SCOPE } Load Billing Portal Details Failure`,
	props<ErrorActionPayload>(),
);
