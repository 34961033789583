import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, switchMap, take } from 'rxjs';
import { omit } from 'lodash-es';

import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { FADE, FADE_IN, SLIDE } from '@bp/shared/animations';
import { filterPresent } from '@bp/shared/rxjs';
import { SubscriptionPlanChargePeriod, SUBSCRIPTION_PLANS_FAQS, SubscriptionPlan, SubscriptionPlanType } from '@bp/shared/domains/subscription-plans/core';
import { Currency } from '@bp/shared/models/currencies';
import { ToastType } from '@bp/shared/components/core';

import { SubscriptionPlansSharedFacade } from '@bp/admins-shared/domains/subscription-plans';
import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { NotificationsHubService } from '@bp/admins-shared/features/notifications-hub';

import { CurrentMerchantFacade } from '@bp/merchant-admin/domains/current-merchant';

import { CurrentMerchantSubscriptionFacade } from '../../state';
import { DowngradeSubscriptionPlanApiRequest } from '../../models';

type CurrentSubscriptionManagementMode = 'management' | 'upgrade';

enum CurrentSubscriptionManagementTab {
	SubscriptionPlans,
	Checkout,
	Downgrade,
	Congratulation,
}

const checkoutSubscriptionPlanTypeRouteParam = 'checkout';

@Component({
	selector: 'bp-current-subscription-management',
	templateUrl: './current-subscription-management.component.html',
	styleUrls: [ './current-subscription-management.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE, SLIDE, FADE_IN ],
})
export class CurrentSubscriptionManagementComponent extends Destroyable {

	@Input() mode: CurrentSubscriptionManagementMode = 'management';

	@Output() readonly subscriptionPlanSuccessfullyPurchased = new EventEmitter<void>();

	@Output() readonly subscriptionPlanSuccessfullyDowngraded = new EventEmitter<void>();

	// eslint-disable-next-line @typescript-eslint/naming-convention
	protected _SUBSCRIPTION_PLANS_FAQS = SUBSCRIPTION_PLANS_FAQS;

	protected get _isUpgradeMode(): boolean {
		return this.mode === 'upgrade';
	}

	protected _selectedChargePeriod!: SubscriptionPlanChargePeriod;

	protected _selectedCurrency!: Currency;

	protected _currentTabIndex$ = new BehaviorSubject(CurrentSubscriptionManagementTab.SubscriptionPlans);

	protected get _isSubscriptionPlansTabActive(): boolean {
		return this._currentTabIndex$.value === CurrentSubscriptionManagementTab.SubscriptionPlans;
	}

	protected get _isCheckoutTabActive(): boolean {
		return this._currentTabIndex$.value === CurrentSubscriptionManagementTab.Checkout;
	}

	protected get _isDowngradeTabActive(): boolean {
		return this._currentTabIndex$.value === CurrentSubscriptionManagementTab.Downgrade;
	}

	protected _currentSubscriptionPlan$ = this._currentMerchantSubscriptionFacade.subscriptionPlan$
		.pipe(filterPresent);

	protected get _currentSubscriptionPlan(): SubscriptionPlan | null {
		return this._currentMerchantSubscriptionFacade.entity?.subscriptionPlan ?? null;
	}

	protected get _currentChargePeriod(): SubscriptionPlanChargePeriod | null {
		return this._currentMerchantSubscriptionFacade.entity?.chargePeriod ?? null;
	}

	protected _changeSubscriptionPlanPending$ = combineLatest([
		this._currentMerchantSubscriptionFacade.pending$,
		this.__identityFacade.pending$,
	])
		.pipe(
			map(([ currentSubscriptionPending, identityPending ]) => currentSubscriptionPending || identityPending),
		);

	protected _selectedSubscriptionPlan: SubscriptionPlan | null = null;

	constructor(
		protected _subscriptionPlansSharedFacade: SubscriptionPlansSharedFacade,
		protected _currentMerchantFacade: CurrentMerchantFacade,
		protected _currentMerchantSubscriptionFacade: CurrentMerchantSubscriptionFacade,
		private readonly __identityFacade: IdentityFacade,
		private readonly __notificationsHubService: NotificationsHubService,
		private readonly __router: Router,
		private readonly __activatedRoute: ActivatedRoute,
	) {
		super();

		this.mode = <CurrentSubscriptionManagementMode | undefined> this.__activatedRoute.snapshot.params['mode'] ?? this.mode;

		this._currentMerchantSubscriptionFacade.refresh();

		this.__onSubscriptionPlanPurchaseNavigateToCongratulationTab();

		this.__onSubscriptionPlanDowngradeOutputEvent();

		this.__whenCheckoutSubscriptionPlanTypeRouteParamExistsGoToCheckoutTab();
	}

	executeSubscriptionPlanAction(selectedSubscriptionPlan: SubscriptionPlan): void {
		this._selectedSubscriptionPlan = selectedSubscriptionPlan;

		void this.__setTabAccordingSelectedSubscriptionPlanAction(selectedSubscriptionPlan);
	}

	upgradeToPro(): void {
		this._selectedSubscriptionPlan = this._subscriptionPlansSharedFacade.all!.find(subscriptionPlan => subscriptionPlan.isPro)!;

		this._currentTabIndex$.next(CurrentSubscriptionManagementTab.Checkout);
	}

	downgradeSubscription(): void {
		this._currentMerchantSubscriptionFacade.downgradeSubscription(new DowngradeSubscriptionPlanApiRequest({
			subscriptionPlan: this._selectedSubscriptionPlan!,
			chargePeriod: this._selectedChargePeriod,
			currency: this._selectedCurrency,
		}));
	}

	gotoSubscriptionPlansTab(): void {
		this.__removeCheckoutSubscriptionPlanTypeRouteParam();

		this._currentTabIndex$.next(CurrentSubscriptionManagementTab.SubscriptionPlans);
	}

	onSelectedTabChange(event: MatTabChangeEvent): void {
		if (event.index === CurrentSubscriptionManagementTab.Checkout)
			this.__setCheckoutSubscriptionPlanTypeRouteParam(this._selectedSubscriptionPlan!);

		this._currentMerchantSubscriptionFacade.resetError();
	}

	private __whenCheckoutSubscriptionPlanTypeRouteParamExistsGoToCheckoutTab(): void {
		const checkoutSubscriptionPlanType = SubscriptionPlanType.parse(
			this.__activatedRoute.snapshot.params[checkoutSubscriptionPlanTypeRouteParam],
		);

		if (!checkoutSubscriptionPlanType)
			return;

		const isNonCheckoutSubscriptionPlanType = [
			SubscriptionPlanType.free,
			SubscriptionPlanType.enterprise,
		].includes(checkoutSubscriptionPlanType);

		if (isNonCheckoutSubscriptionPlanType)
			return;

		void this.__gotoSubscriptionPlanCheckoutTab(checkoutSubscriptionPlanType);
	}

	private async __gotoSubscriptionPlanCheckoutTab(subscriptionPlanType: SubscriptionPlanType): Promise<void> {
		const allPresentSubscriptionPlans = await firstValueFrom(this._subscriptionPlansSharedFacade.allPresent$);
		const subscriptionPlanFromRoute = allPresentSubscriptionPlans.find(plan => plan.type === subscriptionPlanType);

		if (subscriptionPlanFromRoute)
			this.executeSubscriptionPlanAction(subscriptionPlanFromRoute);
	}

	private async __setTabAccordingSelectedSubscriptionPlanAction(selectedSubscriptionPlan: SubscriptionPlan): Promise<void> {
		await firstValueFrom(this._currentMerchantSubscriptionFacade.presentEntity$);

		const selectedSubscriptionPlanIndex = this._subscriptionPlansSharedFacade.all!.indexOf(selectedSubscriptionPlan);
		const currentSubscriptionPlanIndex = this._subscriptionPlansSharedFacade.all!.findIndex(
			subscriptionPlan => subscriptionPlan.id === this._currentSubscriptionPlan!.id,
		);

		const isPlanUpgrade = selectedSubscriptionPlanIndex > currentSubscriptionPlanIndex;

		const isChargePeriodUpgrade = selectedSubscriptionPlanIndex === currentSubscriptionPlanIndex
			&& this._currentChargePeriod === SubscriptionPlanChargePeriod.monthly
			&& this._selectedChargePeriod === SubscriptionPlanChargePeriod.annually;

		this._currentTabIndex$.next(
			isChargePeriodUpgrade || isPlanUpgrade
				? CurrentSubscriptionManagementTab.Checkout
				: CurrentSubscriptionManagementTab.Downgrade,
		);
	}

	private __onSubscriptionPlanPurchaseNavigateToCongratulationTab(): void {
		this._currentMerchantSubscriptionFacade.purchaseSubscriptionPlanSuccess$
			.pipe(
				this.__waitForSingleAccessTokenRefresh(),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this._currentTabIndex$.next(CurrentSubscriptionManagementTab.Congratulation));
	}

	private __onSubscriptionPlanDowngradeOutputEvent(): void {
		this._currentMerchantSubscriptionFacade.downgradeSubscriptionPlanSuccess$
			.pipe(
				this.__waitForSingleAccessTokenRefresh(),
				takeUntilDestroyed(this),
			)
			.subscribe(() => {
				this.subscriptionPlanSuccessfullyDowngraded.emit();

				this.__showChangeSubscriptionPlanSuccessNotification();
			});
	}

	private __showChangeSubscriptionPlanSuccessNotification(): void {
		this.__notificationsHubService.next({
			text: `Subscription Plan has been changed to ${ this._selectedSubscriptionPlan!.name }`,
			duplicateAsToast: true,
			toastType: ToastType.success,
		});
	}

	private __waitForSingleAccessTokenRefresh() {
		return (source$: Observable<any>) => source$.pipe(
			switchMap(
				() => this.__identityFacade.refreshTokenSuccess$.pipe(take(1)),
			),
		);
	}

	private __setCheckoutSubscriptionPlanTypeRouteParam(selectedSubscriptionPlan: SubscriptionPlan): void {
		void this.__router.navigate(
			[{
				...this.__activatedRoute.snapshot.params,
				checkout: selectedSubscriptionPlan.type,
			}],
			{ relativeTo: this.__activatedRoute },
		);
	}

	private __removeCheckoutSubscriptionPlanTypeRouteParam(): void {
		void this.__router.navigate(
			[ omit(this.__activatedRoute.snapshot.params, checkoutSubscriptionPlanTypeRouteParam) ],
			{ relativeTo: this.__activatedRoute },
		);
	}
}
