import { switchMap, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiResult } from '@bp/shared/models/common';

import { SubscriptionPlanCheckoutApiService } from '../services';

import { loadConfig } from './subscription-plan-checkout.actions';
import { loadConfigFailure, loadConfigSuccess } from './subscription-plan-checkout-api.actions';

@Injectable()
export class SubscriptionPlanCheckoutEffects {

	loadConfig$ = createEffect(() => this._actions$.pipe(
		ofType(loadConfig),
		switchMap(() => this._subscriptionPlanCheckoutApiService
			.getSubscriptionPlanCheckoutConfig()
			.pipe(apiResult(loadConfigSuccess, loadConfigFailure))),
	));

	loadFailure$ = createEffect(
		() => this._actions$.pipe(
			ofType(loadConfigFailure),
			tap(({ error, type }) => this._toaster.error(error.message, type, { disableTimeOut: true })),
		),
		{ dispatch: false },
	);

	constructor(
		private readonly _actions$: Actions,
		private readonly _subscriptionPlanCheckoutApiService: SubscriptionPlanCheckoutApiService,
		private readonly _toaster: ToastrService,
	) {
	}

}
