import { createFeature, createReducer, on } from '@ngrx/store';

import { BpError } from '@bp/shared/models/core';
import { Country } from '@bp/shared/models/countries';

import { VatConfigPerCountry } from '../models';

import { loadConfigFailure, loadConfigSuccess } from './subscription-plan-checkout-api.actions';
import { loadConfig } from './subscription-plan-checkout.actions';

export interface IState {
	country: Country | null;
	vatConfigPerCountry: VatConfigPerCountry | null;
	pending: boolean;
	error: BpError | null;
}

export const initialState: IState = {
	country: null,
	vatConfigPerCountry: null,
	pending: false,
	error: null,
};

export const subscriptionPlanCheckoutFeature = createFeature({
	name: '[feature]:subscription-plan-checkout',
	reducer: createReducer(
		initialState,

		on(
			loadConfig,
			(state): IState => ({
				...state,
				pending: true,
			}),
		),

		on(
			loadConfigSuccess,
			(state, { result }): IState => ({
				...state,
				...result,
			}),
		),

		on(
			loadConfigSuccess,
			loadConfigFailure,
			(state): IState => ({ ...state, pending: false }),
		),
	),
});
