import { get, intersection, isArray, isBoolean, isString } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { isEmpty, searchIgnoringCase } from '@bp/shared/utilities';

@Pipe({
	name: 'shallowFilter',
})
export class ShallowFilterPipe implements PipeTransform {
	transform<T>(filteredArray?: T[] | null, filter?: Partial<T> | null): T[] | null {
		if (isEmpty(filter) || isEmpty(filteredArray))
			return filteredArray ?? null;

		const filterPropertyKeys = Object.keys(filter);

		return filteredArray.filter(object => filterPropertyKeys.every(propertyKey => {
			const searchTerm = <string[] | boolean | string | undefined> get(filter, propertyKey);
			const value = <string[] | boolean | string | undefined> get(object, propertyKey);

			if (isBoolean(searchTerm) || isBoolean(value))
				return searchTerm === value;

			if (isEmpty(searchTerm))
				return true;

			if (isArray(searchTerm))
				return intersection(value, searchTerm).length > 0;

			if (isString(searchTerm))
				return searchIgnoringCase(<string | undefined>value ?? '', searchTerm);

			return value === searchTerm;
		}));
	}
}
