import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import type { Params } from '@angular/router';

import { select } from '@ngrx/store';

import type { NewsSummary } from '@bp/shared/domains/newsroom';
import { NewsSummariesQueryParams } from '@bp/shared/domains/newsroom';

import { EntitiesListFacade } from '@bp/admins-shared/features/entity';

import { ANNOUNCEMENTS_LAST_NEWS_COUNT } from '@bp/firebase-functions';

import { close, open, ANNOUNCEMENTS_ACTIONS, markAnnouncementsAsSeen } from './announcements.actions';
import {
	getIsOpened, getAnnouncementsHubStateForCurrentUser, ANNOUNCEMENTS_SELECTORS
} from './announcements.selectors';

@Injectable({ providedIn: 'root' })
export class AnnouncementsFacade extends EntitiesListFacade<NewsSummary, NewsSummariesQueryParams> {

	readonly actions = ANNOUNCEMENTS_ACTIONS;

	readonly selectors = ANNOUNCEMENTS_SELECTORS;

	stateForCurrentUser$ = this._store$.pipe(select(getAnnouncementsHubStateForCurrentUser));

	isOpened$ = this._store$
		.select(getIsOpened)
		.pipe(tap(v => (this.isOpened = v)));

	isOpened = false;

	queryParamsFactory(routeParams?: Params): NewsSummariesQueryParams {
		return NewsSummariesQueryParams.published({
			...routeParams,
			limit: ANNOUNCEMENTS_LAST_NEWS_COUNT,
			showOnMerchantAdminWhatsNewHub: true,
		});
	}

	override load(): void {
		super.load(this.queryParamsFactory());
	}

	open(): void {
		this._store$.dispatch(open());
	}

	close(): void {
		this._store$.dispatch(close());
	}

	markAnnouncementsAsSeen(announcements: NewsSummary[]): void {
		this._store$.dispatch(markAnnouncementsAsSeen({ announcements }));
	}

}
