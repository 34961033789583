import { map, shareReplay, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { filterPresent } from '@bp/shared/rxjs';
import { BridgerPspScope } from '@bp/shared/domains/bridger-psps/core';

import type { Merchant } from '@bp/admins-shared/core/models';
import { BridgerPspsSharedFacade } from '@bp/admins-shared/domains/bridger-psps';

import { resetState, load, loadVirtualTerminalData, refresh, startRefreshingInInterval, stopRefreshingInInterval } from './current-merchant.actions';
import {
	selectError, selectCurrentMerchant, selectCurrentMerchantLoading, selectCurrentSubscription, selectVirtualTerminalData
} from './current-merchant.selectors';

@Injectable({
	providedIn: 'root',
})
export class CurrentMerchantFacade {

	entity$ = this._store$.select(selectCurrentMerchant);

	entity: Merchant | null = null;

	presentEntity$ = this.entity$.pipe(filterPresent);

	currentSubscriptionPlan$ = this._store$
		.select(selectCurrentSubscription)
		.pipe(filterPresent);

	currencies$ = this.entity$.pipe(map(v => v?.virtualTerminalCurrencies ?? []));

	loading$ = this._store$.select(selectCurrentMerchantLoading);

	loadingFirst$ = this.loading$.pipe(take(2));

	error$ = this._store$.select(selectError);

	virtualTerminalData$ = this._store$.select(selectVirtualTerminalData);

	bridgerPsps$ = this._bridgerPspsSharedFacade.all$.pipe(
		map(bridgerPsps => bridgerPsps.filter(bridgerPsp => bridgerPsp.scopes.includes(BridgerPspScope.merchantAdmin))),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	bridgerPspsInternalNames$ = this.bridgerPsps$.pipe(
		map(bridgerPsps => bridgerPsps.map(bridgerPsp => bridgerPsp.internalName!)),
	);

	constructor(
		private readonly _bridgerPspsSharedFacade: BridgerPspsSharedFacade,
		private readonly _store$: Store,
	) {
		this._updateEntityPropertyOnStateChange();
	}

	load(): void {
		this._store$.dispatch(load());
	}

	refresh(): void {
		this._store$.dispatch(refresh());
	}

	loadVirtualTerminalData(): void {
		this._store$.dispatch(loadVirtualTerminalData());
	}

	resetState(): void {
		this._store$.dispatch(resetState());
	}

	startRefreshingInInterval(): void {
		this._store$.dispatch(startRefreshingInInterval());
	}

	stopRefreshingInInterval(): void {
		this._store$.dispatch(stopRefreshingInInterval());
	}

	private _updateEntityPropertyOnStateChange(): void {
		this.entity$
			.subscribe(entity => (this.entity = entity));
	}

}
