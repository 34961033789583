import type { MetadataEntity } from '../metadata-entity';

import { Property } from './property-metadata.decorator';

/**
 * Marks the property as the DTO source of the instance
 * Used for entities which stores some serialized data in a property to pass it directly to the cashier bypassing
 * the backend's deserialization of the data
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function SourceOfDTO(parser?: (sourceOfDTOValue: any) => Object) {
	return function(model: MetadataEntity, property: string): void {
		Property({
			sourceOfDTO: true,
			mapper: parser,
		})(model, property);
	};
}
