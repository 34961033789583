import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FADE_IN } from '@bp/shared/animations';

import { ICalendlyOptions } from '../../models';

@Component({
	templateUrl: './calendly-dialog.component.html',
	styleUrls: [ './calendly-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class CalendlyDialogComponent implements OnInit {

	constructor(
		private readonly _dialogRef: MatDialogRef<CalendlyDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public calendlyOptions: ICalendlyOptions,
	) {
	}

	ngOnInit(): void {
		this._dialogRef.addPanelClass('calendly-panel');
	}

}
