import { isString } from '@sentry/utils';

import type { IValidatorFunc } from '@bp/shared/features/validation';
import { Validators } from '@bp/shared/features/validation';
import { Currency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import {
	booleanMapper, Default, FieldControlType, FieldViewType, MapFromDTO, Mapper, MetadataEntity, PropertyMetadata,
	PropertyMetadataControl, titleCase, Unserializable
} from '@bp/shared/models/metadata';

export interface IPropertyMetadataValidatorDTO {

	name: string | 'regex';

	value: string;

	errorMessage: string;

}

export class ApiPropertyMetadata extends MetadataEntity {

	@MapFromDTO()
	name!: string;

	@Mapper(titleCase)
	title!: string;

	@Mapper(titleCase)
	description!: string;

	@Mapper(FieldControlType)
	@Default(FieldControlType.input)
	controlType!: FieldControlType;

	@Mapper(booleanMapper)
	@Default(true)
	required!: boolean;

	@MapFromDTO()
	default?: unknown[] | boolean | number | string;

	@MapFromDTO()
	items?: string[];

	@Mapper(booleanMapper)
	@Default(false)
	isSecret?: boolean;

	@MapFromDTO()
	validators!: IPropertyMetadataValidatorDTO[] | null;

	@Unserializable()
	readonly propertyMetadata: PropertyMetadata;

	@Unserializable()
	readonly validator: IValidatorFunc | null;

	constructor(dto?: DTO<ApiPropertyMetadata>) {
		super(dto);

		this.validator = this._buildValidatorFunc();

		this.propertyMetadata = this._buildPropertyMetadata();
	}

	private _buildValidatorFunc(): IValidatorFunc | null {
		return this.validators
			? Validators.compose(
				this.validators.map(v => v.name === 'regex'
					? Validators.pattern(v.value, v.errorMessage)
					: null),
			)
			: null;
	}

	private _buildPropertyMetadata(): PropertyMetadata {
		return new PropertyMetadata({
			property: this.name,
			label: this.title,
			longHint: this.description,
			viewType: this.controlType === FieldControlType.switch ? FieldViewType.boolean : FieldViewType.text,
			defaultPropertyValue: this._tryGetDefaultPropertyValue(),
			isSecret: this.isSecret,
			control: new PropertyMetadataControl({
				required: this.required,
				type: this._inferControlType(),
				list: this.controlType === FieldControlType.currency ? Currency.list : this.items,
				validator: this.validator,
				isSecret: this.isSecret,
			}),
		});
	}

	private _inferControlType(): FieldControlType {
		switch (this.controlType) {
			case FieldControlType.currency:
				return FieldControlType.autocomplete;

			case FieldControlType.input:
				return this.name === 'password' ? FieldControlType.password : FieldControlType.input;

			default:
				return this.controlType;
		}
	}

	private _tryGetDefaultPropertyValue(): unknown {
		if (!this.default || !isString(this.default))
			return this.default;

		try {
			return JSON.parse(this.default);
		} catch {
			return this.default;
		}
	}

}
