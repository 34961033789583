import { isNil } from 'lodash-es';

import { Currency } from '@bp/shared/models/currencies';
import { booleanMapper, Default, DTO, Mapper, MetadataEntity, numberMapper } from '@bp/shared/models/metadata';

import { PricePerCurrencyMap, pricePerCurrencyMapper } from './mappers';
import { SubscriptionPlanTransactionsAmountTier } from './subscription-plan-transactions-amount-tier';

export class SubscriptionPlanPrice extends MetadataEntity {

	@Mapper(booleanMapper)
	isBasePrice!: boolean;

	@Mapper(numberMapper)
	@Default(1)
	discount!: number;

	@Mapper(numberMapper)
	@Default(null)
	refundableForDays!: number | null;

	@Mapper(pricePerCurrencyMapper)
	pricesPerCurrency!: PricePerCurrencyMap;

	@Mapper(SubscriptionPlanTransactionsAmountTier)
	@Default(null)
	transactionsAmountTiers!: SubscriptionPlanTransactionsAmountTier[] | null;

	readonly currencies: Currency[];

	readonly firstTierWithPricePerTransaction: SubscriptionPlanTransactionsAmountTier | null;

	constructor(dto?: DTO<SubscriptionPlanPrice>) {
		super(dto);

		this.currencies = [ ...this.pricesPerCurrency.keys() ];

		this.firstTierWithPricePerTransaction = this.transactionsAmountTiers?.find(tier => !isNil(tier.pricePerTransaction)) ?? null;
	}

	getPriceFor(currency: Currency): number {
		return this.pricesPerCurrency.get(currency) ?? 0;
	}

	getTransactionPriceFor(currency: Currency): number {
		return this.firstTierWithPricePerTransaction?.pricePerTransaction?.get(currency) ?? 0;
	}
}
