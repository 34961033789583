import { sortBy } from 'lodash-es';

import type { ISortQueryParams } from './query-params-mixins';

export function sortArray<T>(array: T[], { sortField, sortDir }: ISortQueryParams): T[] {
	let sortedArray = <T[]>sortBy(array, [ sortField ]);

	if (sortDir === 'desc')
		sortedArray = sortedArray.reverse();

	return sortedArray;
}
