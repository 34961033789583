import { camelCase, isMap } from 'lodash-es';
import { Moment } from 'moment';

import { Country, countryMapper } from '@bp/shared/models/countries';
import { Currency } from '@bp/shared/models/currencies';
import {
	Default,
	Entity,
	FieldViewType,
	Label,
	MapFromDTO,
	Mapper,
	Table,
	momentMapper,
	View,
	Alias,
	DTO
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';

import { FeatureVersion, MerchantAdminFeature } from '@bp/shared-domains-identity';

import { MerchantCompany } from './merchant-company';
import { MerchantFinancial } from './merchant-financial';
import { MerchantStatus } from './merchant-status';
import { MerchantType } from './merchant-type';
import { MerchantSubscription } from './merchant-subscription';

export type MerchantKeys = NonFunctionPropertyNames<Merchant>;

export class Merchant extends Entity {
	@Mapper(MerchantStatus)
	@View(FieldViewType.status)
	@Table({
		headless: true,
		alwaysShown: true,
	})
	status!: MerchantStatus;

	@Mapper(countryMapper)
	@View(FieldViewType.country)
	@Table({ alwaysShown: true })
	country!: Country;

	@Mapper(momentMapper)
	@View(FieldViewType.moment)
	@Table()
	launchDate!: Moment;

	@Table()
	website!: string;

	@View(FieldViewType.email)
	@Table()
	email!: string;

	@Mapper(MerchantType)
	@Table()
	type!: MerchantType;

	@View(FieldViewType.currency)
	@Table()
	monthlyFee!: number;

	@Label('Company Name')
	@Mapper(MerchantCompany)
	@Default(new MerchantCompany())
	@Table()
	company!: MerchantCompany;

	@Table({ optional: true })
	brandName!: string;

	@Table({ optional: true })
	transactionFee!: string;

	@MapFromDTO()
	virtualTerminalCashierKey!: string;

	@Mapper(Currency)
	virtualTerminalCurrencies!: Currency[];

	@Mapper(MerchantFinancial)
	@Default(new MerchantFinancial())
	financial!: MerchantFinancial;

	@Mapper(Currency)
	@Default([])
	currencies!: Currency[];

	@Default(false)
	allowRefunds!: boolean;

	@Default(false)
	allowPayouts!: boolean;

	@Default(false)
	allowCreateCreditCardToken!: boolean;

	@Default(false)
	allowPaywith!: boolean;

	@Mapper((dto?: Object) => isMap(dto)
		? dto
		: new Map(
			Object.entries(dto ?? {}).map(([ dtoFeatureName, version ]) => [
				MerchantAdminFeature.parseStrict(camelCase(dtoFeatureName)),
				FeatureVersion.parseStrict(version),
			]),
			  ))
	@Default(new Map())
	featuresVersions!: Map<MerchantAdminFeature, FeatureVersion>;

	@Mapper(Currency)
	@Alias('currency')
	baseCurrency!: Currency;

	@Mapper(MerchantSubscription)
	@Default(null)
	currentSubscription!: MerchantSubscription | null;

	constructor(dto?: DTO<Merchant>) {
		super(dto);
	}
}
