<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<button type="submit" class="hidden"></button>

	<bp-alert [errors]="errors" type="danger"> </bp-alert>

	<fieldset>
		<bp-property-metadata-controls-section
			heading="Portal"
			[form]="form"
			[metadata]="metadata"
			[sectionScheme]="sectionScheme"
		>
		</bp-property-metadata-controls-section>
	</fieldset>
</form>
