import { camelCase, mapKeys } from 'lodash-es';

import type { DTO } from '@bp/shared/models/metadata';
import { isEmpty, tryDecodeBase64 } from '@bp/shared/utilities';

import type { CryptoWalletLegacyDTO } from './crypto-wallet';
import { CryptoWallet } from './crypto-wallet';
import type { ICryptoWalletsPaymentOption } from './crypto-wallets-payment-option.interface';

export function cryptoWalletsJsonPropertyDtoSourceMapper(jsonValues: string[]): DTO<ICryptoWalletsPaymentOption> {
	if (isEmpty(jsonValues))
		return {};

	const dtos = jsonValues.map((dto): CryptoWalletLegacyDTO | DTO<ICryptoWalletsPaymentOption> => JSON.parse(
		tryDecodeBase64(dto),
	));

	if (isEmpty(dtos))
		return {};

	const paymentOptionProperty: keyof ICryptoWalletsPaymentOption = 'wallets';
	const [ possiblePaymentOption ] = dtos;

	if (paymentOptionProperty in possiblePaymentOption)
		return possiblePaymentOption;

	return {
		wallets: dtos
			.map(dto => mapKeys(dto, (_v, k) => camelCase(k)))
			.map(dto => CryptoWallet.fromLegacy(dto)!),
	};
}
