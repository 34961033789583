import { createAction, props } from '@ngrx/store';

import type { ErrorActionPayload, ResultActionPayload } from '@bp/shared/models/common';

import type { Merchant, VirtualTerminalData } from '@bp/admins-shared/core/models';

import { ACTIONS_SCOPE } from './current-merchant.actions';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadSuccess = createAction(
	`${ scope } Load Success`,
	props<ResultActionPayload<Merchant>>(),
);

export const loadFailure = createAction(
	`${ scope } Load Failure`,
	props<ErrorActionPayload>(),
);

export const refreshSuccess = createAction(
	`${ scope } Refresh Success`,
	props<ResultActionPayload<Merchant>>(),
);

export const refreshFailure = createAction(
	`${ scope } Refresh Failure`,
	props<ErrorActionPayload>(),
);

export const loadVirtualTerminalDataSuccess = createAction(
	`${ scope } Load Virtual Terminal Data Success`,
	props<ResultActionPayload<VirtualTerminalData>>(),
);

export const loadVirtualTerminalDataFailure = createAction(
	`${ scope } Load Virtual Terminal Data Failure`,
	props<ErrorActionPayload>(),
);
