import { Validators } from '@bp/shared/features/validation';
import type { IExternalLinkPaymentOption } from '@bp/shared/models/business';
import { externalLinkJsonPropertyDtoSourceMapper, PaymentOptionType } from '@bp/shared/models/business';
import { Currency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import {
	 SourceOfDTO, MapFromDTO, Mapper, Control, FieldControlType, FieldViewType, Label, Required, Validator, View, titleCase
} from '@bp/shared/models/metadata';
import { isEmpty } from '@bp/shared/utilities';

import { StaticPaymentOptionBase } from './static-payment-option-base';

export class ExternalLinkPaymentOption extends StaticPaymentOptionBase implements IExternalLinkPaymentOption {

	static type = PaymentOptionType.externalLink;

	readonly type = ExternalLinkPaymentOption.type;

	@SourceOfDTO(externalLinkJsonPropertyDtoSourceMapper)
	override json!: string[];

	@Label(null)
	@View(FieldViewType.thumbnail)
	@Required()
	override logoUrl!: string;

	@Label('URL')
	@Validator(Validators.url)
	@View(FieldViewType.link)
	@Required()
	url!: string;

	@Control(FieldControlType.chip, {
		list: Currency.list,
		placeholder: 'Add currency...',
	})
	@Mapper(Currency)
	@Required()
	currencies!: Currency[];

	@MapFromDTO()
	provider!: string;

	override get displayName(): string {
		return `${ this.type.displayName }${ isEmpty(this.name) ? '' : `: ${ this.name }` }`;
	}

	constructor(dto?: DTO<ExternalLinkPaymentOption>) {
		super(dto);

		this.name = this.name ?? titleCase(this.pspName);
	}

}
