import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ModalHostComponent } from '@bp/shared/features/modal';

import { CURRENT_SUBSCRIPTION_MANAGEMENT_OVERLAY_PANE_CLASS } from '../../models';

@Component({
	selector: 'bp-current-subscription-management-modal',
	templateUrl: './current-subscription-management-modal.component.html',
	styleUrls: [ './current-subscription-management-modal.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSubscriptionManagementModalComponent extends ModalHostComponent {

	override panelClass = [ CURRENT_SUBSCRIPTION_MANAGEMENT_OVERLAY_PANE_CLASS ];

}
