import { IFrequentlyAskedQuestion } from '../models/faq.interface';

export const SUBSCRIPTION_PLANS_FAQS = <IFrequentlyAskedQuestion[]> [
	{
		question: 'Can I change my plan? How?',
		answer: `
				Yes! In the top bar of your Bridger Admin, click on “Manage Plan” and follow the on-screen instructions.
				You can upgrade to a higher-tier plan at any time, but switching to a lower-tier plan will only take
				effect at the end of your current billing cycle.
			`,
	},
	{
		question: 'Do I need to know how to code?',
		answer: `
				Nope! BridgerPay makes it easy for you to integrate and manage your payments without being a developer.
				We may need you to locate specific information for certain providers, but we'll guide you through any
				additional steps required and our support champions are here to help you.
			`,
	},
	{
		question: 'What counts as a transaction?',
		answer: `
				We log a transaction when your BridgerPay instance interacts with a PSP
				(e.g. a transaction both successful and declined, a refund, a payout, etc.).
			`,
	},

	{
		question: 'What happens if I exceed my transaction limit?',
		answer: `
				If it looks like you’re about to exceed your transaction limit,
				we’ll be sure to let you know by email and through in-app messages.
				In the event that you go over your limit in a given month we will automatically bill you for your
				overages based on the Pro Plan transaction price.
				As long as you pay for your overages on time, we will not stop your transactions or pause your account.
			`,
	},
	{
		question: 'What if I change my mind and want to cancel?',
		answer: `
				If you’ve paid for a yearly subscription in the last 30-days, and need to close your account,
				you are entitled to a prorated refund. We will automatically refund you the remaining balance from your
				original purchase. Simply cancel the account from the Admin section, and your account will be
				automatically refunded. Please be aware that if you close your account, this will be permanently deleted
				and all your data will be removed from our servers. If you have any questions, you can always reach out
				to <a class="link" href="mailto:hello@bridgerpay.com">hello@bridgerpay.com</a>.
			`,
	},
	{
		question: 'Do you offer any discounts?',
		answer: `
				Yes! When you choose the yearly subscription, you’ll receive a discount.
				We also offer an additional 15% discount for nonprofits.
			`,
	},
	{
		question: 'Can I pay for BridgerPay monthly?',
		answer: `
				Yes, you can pay monthly for your account. Choose the monthly option on the subscriptions page and you will be
				able to pay monthly. The monthly plan is not discounted so if you are looking to save, we recommend
				the yearly plan.
			`,
	},
	{
		question: 'What payment methods do you offer?',
		answer: `
				We accept all major credit cards, PayPal, and (in selected countries) direct debit.
				We also support transactions in a range of currencies.
			`,
	},
];
