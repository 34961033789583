import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AnnouncementEffects } from './state/entity/announcement.effects';
import { AnnouncementsEffects } from './state/list/announcements.effects';
import { FEATURE_KEY, reducer } from './state/feature.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([
			AnnouncementEffects,
			AnnouncementsEffects,
		]),
	],
})
export class MerchantAdminFeaturesAnnouncementsRootModule {
}
