import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IState, FEATURE_KEY } from './subscription-plans-shared.reducer';

export const selectFeatureState = createFeatureSelector<IState>(FEATURE_KEY);

export const selectAllSubscriptions = createSelector(
	selectFeatureState,
	state => state.all,
);

export const selectFreeSubscriptionPlan = createSelector(
	selectAllSubscriptions,
	subscriptionPlans => subscriptionPlans?.find(subscriptionPlan => subscriptionPlan.isFree),
);

export const selectProSubscriptionPlan = createSelector(
	selectAllSubscriptions,
	subscriptionPlans => subscriptionPlans?.find(subscriptionPlan => subscriptionPlan.isPro),
);

export const selectEnterpriseSubscriptionPlan = createSelector(
	selectAllSubscriptions,
	subscriptionPlans => subscriptionPlans?.find(subscriptionPlan => subscriptionPlan.isEnterprise),
);

export const selectLoading = createSelector(
	selectFeatureState,
	state => state.loading,
);

export const selectError = createSelector(
	selectFeatureState,
	state => state.error,
);
