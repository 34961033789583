import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NewsSummary } from '@bp/shared/domains/newsroom';

@Component({
	selector: 'bp-announcements-hub-item',
	templateUrl: './announcements-hub-item.component.html',
	styleUrls: [ './announcements-hub-item.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsHubItemComponent {

	@Input() announcement!: NewsSummary;

}
