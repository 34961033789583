<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert [errors]="errors" type="danger"> </bp-alert>

	<!-- to kick in the browsers save password feature upon login -->
	<input type="text" name="email" [value]="email" hidden />

	<content class="flex-column mt-5">
		<bp-password-input
			required
			label="Current Password"
			nativeAutocomplete="current-password"
			formControlName="currentPassword"
		></bp-password-input>

		<bp-password-input
			required
			class="mt-3"
			label="New Password"
			nativeAutocomplete="new-password"
			formControlName="newPassword"
		></bp-password-input>

		<bp-password-input
			required
			label="Confirm New Password"
			nativeAutocomplete="new-password"
			formControlName="confirmNewPassword"
		></bp-password-input>
	</content>

	<footer class="text-center mt-3 mb-4">
		<button
			type="submit"
			mat-flat-button
			color="primary"
			class="bp-round-button bp-button-lg long-button"
			[disabled]="(canSave$ | async) === false"
		>
			Save
		</button>
	</footer>
</form>
