import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FADE } from '@bp/shared/animations';
import { BridgerPsp } from '@bp/shared/domains/bridger-psps/core';
import type { PaymentCardBrand } from '@bp/shared/models/business';
import type { ICurrency } from '@bp/shared/models/currencies';

import { MerchantAdminFeature } from '@bp/shared-domains-identity';

@Component({
	selector: 'bp-bridger-psps-filter',
	templateUrl: './bridger-psps-filter.component.html',
	styleUrls: [ './bridger-psps-filter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class BridgerPspsFilterComponent {

	@Input() availableCurrencies?: Set<ICurrency>;

	@Input() availableBrands?: Set<PaymentCardBrand> | null;

	@Output() readonly changed = new EventEmitter<Partial<BridgerPsp>>();

	// eslint-disable-next-line @typescript-eslint/naming-convention
	MerchantAdminFeature = MerchantAdminFeature;

	filterState: Partial<BridgerPsp> = {};

	emitFilterChanged(partialFilter: Partial<BridgerPsp>): void {
		this.changed.emit(this.filterState = {
			...this.filterState,
			...partialFilter,
		});
	}
}
