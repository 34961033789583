import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE } from '@bp/shared/animations';

import { AnnouncementsFacade } from '../../state';

@Component({
	selector: 'bp-announcements-hub-open-button',
	templateUrl: './announcements-hub-open-button.component.html',
	styleUrls: [ './announcements-hub-open-button.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class AnnouncementsHubOpenButtonComponent {

	constructor(public announcementsFacade: AnnouncementsFacade) { }

}
