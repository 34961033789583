<mat-progress-bar *ngIf="identityFacade.pending$ | async" @slideUp color="primary" mode="indeterminate">
</mat-progress-bar>

<h2 mat-dialog-title>Access OTP Verification</h2>

<mat-dialog-content>
	<bp-resource-access-otp-verification-form
		#resourceAccessOtpVerificationForm
		[resourceName]="data.featurePermission.resourceName"
		[pending]="identityFacade.pending$ | async"
		[error]="identityFacade.error$ | async"
		(submitted)="
			identityFacade.resourceAccessOtpVerification({
				code: $event.code,
				resourceName: data.featurePermission.resourceName
			})
		"
	>
	</bp-resource-access-otp-verification-form>
</mat-dialog-content>

<mat-dialog-actions class="mt-3">
	<button class="mr-auto" mat-stroked-button [mat-dialog-close]="false">Cancel</button>

	<button
		mat-stroked-button
		color="primary"
		[disabled]="(resourceAccessOtpVerificationForm.formDirtyAndValid$ | async) !== true"
		(click)="resourceAccessOtpVerificationForm.submit()"
	>
		Access
	</button>
</mat-dialog-actions>
