import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiMockPluginsManagerService } from '@bp/shared/services';

import { MerchantPspsEffects } from './state/merchant-psps.effects';
import { FEATURE_KEY, reducer } from './state/merchant-psps.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([ MerchantPspsEffects ]),
	],
})
export class MerchantAdminDomainsMerchantPspsRootModule {

	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(
			async () => import('./api-mock-plugin/merchant-psps-api-mock-plugin'),
		);
	}
}
