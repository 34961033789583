import type { Moment } from 'moment';

import { YoutubeHelper } from '@bp/shared/features/youtube-player';
import type { DTO } from '@bp/shared/models/metadata';
import {
	Control, Default, FieldControlType, FieldViewType, FirebaseEntity, Hint, Label, MapFromDTO, Mapper, momentMapper, Required, Table,
	Unserializable, View
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import { RouteMetatags } from '@bp/shared/features/metatags';

import type { IPublishableArticle } from '@bp/firebase-functions';

import { ArticleStatus } from './article-status';

export type ArticleKeys = NonFunctionPropertyNames<Article>;

export const YOUTUBE_LINK_TRANSFORMER = (value: string | null): string | null => value
	? YoutubeHelper.extractVideoIdFromUrl(value) ?? value
	: value;

export class Article extends FirebaseEntity implements IPublishableArticle {

	@Unserializable()
	override name!: string | null;

	@Required()
	@Table({ ellipsis: true })
	title!: string;

	@View(FieldViewType.link, propertyValue => YoutubeHelper.getVideoUrl(propertyValue))
	@Control(FieldControlType.input, { controlValueTransformer: YOUTUBE_LINK_TRANSFORMER })
	youtubeVideoId!: string | null;

	@Default(null)
	@Control(FieldControlType.textarea)
	@Hint('Used as a preview text and a SEO meta description')
	summary!: string | null;

	@MapFromDTO()
	createdBy!: string;

	@MapFromDTO()
	coverImgUrl!: string | null;

	@Table({ sortable: true })
	override updatedBy!: string;

	@Label('Updated')
	@View(FieldViewType.momentFromNow)
	@Table({ sortable: true, defaultSortField: true })
	override updatedAt!: Moment | null;

	@Mapper(momentMapper)
	@Table({ sortable: true })
	@Control(FieldControlType.date)
	@View(FieldViewType.moment, () => 'DD MMM YYYY')
	@Hint('If not provided the date of the first publishing is used')
	publicationDate!: Moment | null;

	@Default(false)
	@Label('Status')
	@Table({ sortable: true })
	@View(
		FieldViewType.booleanCircle,
		v => v ? ArticleStatus.published : ArticleStatus.notPublished,
	)
	isPublished!: boolean;

	@MapFromDTO()
	readonly searchTerms!: string[];

	metatags!: RouteMetatags;

	constructor(dto?: DTO<Article>) {
		super(dto);

		this.name = this.title;

		this.searchTerms = dto?.title
			?.toLowerCase()
			.split(/[\s/:-]/u)
			.filter(v => !!v) ?? [];
	}

}
