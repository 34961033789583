<div class="cards">
	<ng-container *ngFor="let provider of providers">
		<bp-bridger-psp-card
			*bpDelayedRender="0; stagger: 25; allStagger: true"
			@fadeInSemiSlow
			[psp]="provider"
			[paymentOptionType]="paymentOptionType"
			[selected]="selected.has(provider)"
			[selectable]="selected.has(provider) ? true : selectable"
			(click)="toggleProvider(provider)"
		>
		</bp-bridger-psp-card>
	</ng-container>
</div>
