<mat-card
	class="bp-card mild-box-shadow hoverable"
	matRipple
	[matRippleDisabled]="!selectable"
	(click)="!selectable && $event.stopPropagation()"
>
	<mat-card-title class="mat-primary flex-align-items-center">
		<bp-psp-thumbnail [name]="psp.internalName" [showTooltip]="false"></bp-psp-thumbnail>

		<h3
			class="ml-3 mb-0 mr-3 text-ellipsis link interactive primary-text"
			[matMenuTriggerFor]="detailsPanel"
			(click)="$event.stopPropagation()"
		>
			{{ psp.name }}
		</h3>

		<mat-pseudo-checkbox *ngIf="selectable" @fade [state]="selected ? 'checked' : 'unchecked'">
		</mat-pseudo-checkbox>

		<mat-menu #detailsPanel="matMenu" class="psp-details-panel">
			<ng-template matMenuContent>
				<h2>
					<a bpTargetBlank class="link" [href]="psp.websiteUrl">{{ psp.name }}</a>
				</h2>
				<div *ngIf="psp.description" class="mb-3">
					{{ psp.description }}
				</div>
				<ng-container *ngTemplateOutlet="brandsAndCurrenciesTpl; context: { isPopup: true }"></ng-container>
			</ng-template>
		</mat-menu>
	</mat-card-title>

	<mat-card-content>
		<ng-container *ngTemplateOutlet="brandsAndCurrenciesTpl"></ng-container>
	</mat-card-content>

	<ng-template #brandsAndCurrenciesTpl let-isPopup="isPopup">
		<ng-container *ngIf="psp.paymentOptionByTypeMap.get(paymentOptionType) as paymentOption">
			<div *ngIf="paymentOption.type === PspPaymentOptionType.creditCard" class="overlay-at-right mb-1">
				<bp-payment-card-brands
					*ngIf="getCreditCardTypedOption(paymentOption).brands?.length; else anyBrandTpl"
					[ngClass]="isPopup ? 'flex-wrap-1' : 'flex-1'"
					[brands]="getCreditCardTypedOption(paymentOption).brands"
				>
				</bp-payment-card-brands>

				<ng-template #anyBrandTpl>
					<span class="boxed-value">Any Brand</span>
				</ng-template>
			</div>

			<div class="overlay-at-right">
				<bp-boxed-items
					*ngIf="paymentOption.currencies?.length; else anyCurrencyTpl"
					class="nowrap"
					[ngClass]="isPopup ? 'flex-wrap-1' : 'flex-1'"
					[items]="paymentOption.currencies"
				>
				</bp-boxed-items>

				<ng-template #anyCurrencyTpl>
					<span class="boxed-value">Any Currency</span>
				</ng-template>
			</div>

			<bp-paywith-badge *ngIf="psp.someOptionCanBeUsedInPaywith" class="mt-2 mb-n2" size="md"></bp-paywith-badge>
		</ng-container>
	</ng-template>
</mat-card>
