<a class="reset-default-decoration" [routerLink]="[{ outlets: { modal: ['announcement', announcement.id] } }]">
	<div *ngIf="announcement.coverImgUrl" class="cover-img-container mb-2">
		<bp-img [url]="announcement.coverImgUrl"></bp-img>
	</div>

	<h3>
		{{ announcement.title }}
	</h3>

	<div class="mb-3">
		{{ announcement.summary }}
	</div>

	<div class="flex-align-items-baseline">
		<span class="link">Learn more</span>
		<div class="ml-auto mb-0 mat-h5 font-weight-slim uppercase">
			{{ announcement.kind.displayName }} @
			{{ announcement.publicationDate | momentFormat: 'LL' | async }}
		</div>
	</div>
</a>
