import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDomainsSubscriptionPlansCoreModule } from '@bp/shared/domains/subscription-plans/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesLottiePlayerModule } from '@bp/shared/features/lottie-player';
import { SharedPipesModule } from '@bp/shared/pipes';

import { SubscriptionPlanCongratulationComponent } from './subscription-plan-congratulation.component';
import { SubscriptionPlanCongratulationContentComponent } from './components';

const EXPOSED = [
	SubscriptionPlanCongratulationComponent,
	SubscriptionPlanCongratulationContentComponent,
];

@NgModule({
	imports: [
		CommonModule,
		MatButtonModule,

		SharedComponentsCoreModule,
		SharedDirectivesModule,
		SharedDomainsSubscriptionPlansCoreModule,
		SharedFeaturesLottiePlayerModule,
		SharedFeaturesSvgIconsModule,
		SharedPipesModule,

		RouterModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
	providers: [],
})
export class SharedDomainsSubscriptionPlansCongratulationModule {}
