import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE_IN } from '@bp/shared/animations';

import { AnnouncementsFacade } from '../../../../state';

@Component({
	selector: 'bp-announcements-hub-header',
	templateUrl: './announcements-hub-header.component.html',
	styleUrls: [ './announcements-hub-header.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class AnnouncementsHubHeaderComponent {

	constructor(public announcementsFacade: AnnouncementsFacade) { }

}
