import type { DTO } from '@bp/shared/models/metadata';
import { Label, MapFromDTO, MetadataEntity, Table } from '@bp/shared/models/metadata';

export class MerchantCompany extends MetadataEntity {

	@Label('Contact name')
	contact!: string;

	@Table()
	name?: string;

	@MapFromDTO()
	phone!: string;

	@MapFromDTO()
	address!: string;

	@MapFromDTO()
	website!: string;

	@MapFromDTO()
	city!: string;

	@MapFromDTO()
	zipCode!: string;

	constructor(data?: DTO<MerchantCompany>) {
		super(data);
	}

}
