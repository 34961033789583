/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { forOwn, get, isEqual, sum } from 'lodash-es';
import { fromEvent, merge, Observable, BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, max, mergeMap, startWith, subscribeOn, switchMap, tap } from 'rxjs/operators';

import type { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import type { AfterViewInit, OnChanges, OnDestroy, SimpleChanges, TrackByFunction } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, Input, Output, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';

import { FADE_IN_LIST_STAGGERED } from '@bp/shared/animations';
import type { ISwipeEvent, TouchManager } from '@bp/shared/features/touch';
import { TouchBuilder } from '@bp/shared/features/touch';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { Dimensions, Direction } from '@bp/shared/models/core';
import { BpScheduler, fromMeasure, fromResize, measure, mutate } from '@bp/shared/rxjs';
import type { Dictionary } from '@bp/shared/typings';
import { $, bpQueueMicrotask } from '@bp/shared/utilities';

export enum CarouselArrowType {
	None = 'none',
	Inner = 'inner',
	Circled = 'circled',
	LongCircled = 'long-circled',
}

type SlidesDimensions = { slideMaxWidth: number; slidesDimensions: Dimensions[] };

@Component({
	selector: 'bp-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: [ './carousel.component.scss' ],
	animations: [ FADE_IN_LIST_STAGGERED ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent
	extends Destroyable
	implements AfterViewInit, OnChanges, OnDestroy {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	CarouselArrowType = CarouselArrowType;

	@Input() itemsPerViewport: number | 'unlimited' = 1;

	@Input() looped = false;

	@Input() bullets: boolean | 'always' = false;

	@Input() arrowType = CarouselArrowType.Inner;

	@Input() arrowSize: 'lg' | 'md' | 'sm' = 'md';

	@Input() arrowTemplate?: TemplateRef<any>;

	@Input() mobileWidth = 414;

	@Input() responsive = true;

	@Input() autoheight = false;

	@Input() showArrows = true;

	@Input() resetActiveOnItemsChange = true;

	@Input('autoplay') autoplayInterval = 0;

	@Input() slideClass?: string;

	@Input() sortable = false;

	@Input() sortableItem?: (item: any) => boolean;

	@Input() slideInAnimation = true;

	@Input() disableRipple = false;

	@Output('sort') readonly sort$ = new Subject<any[]>();

	@Input()
	get items(): any[] {
		return this.items$.value;
	}

	set items(value: any[] | null) {
		this.items$.next(value ?? []);
	}

	@Input()
	get activeItem() {
		if (this.items.length === 0)
			// eslint-disable-next-line getter-return
			return;

		// eslint-disable-next-line consistent-return
		return get(this.items, this.activeIndex);
	}

	set activeItem(value: any) {
		if (value && !this.items.includes(value))
			throw new Error('The item does not belong to the carousel items');

		this._activeIndex = this.items.indexOf(value);
	}

	@Output('activeItemChange')
	readonly activeItemChange$ = new Subject<any>();

	@Output('scrolled')
	readonly scroll$ = new Subject<Readonly<ICarouselViewportItemsVisibility>>();

	private readonly _animating$ = new BehaviorSubject(false);

	@Output('animating')
	readonly animating$: Observable<boolean> = this._animating$.asObservable();

	private _activeIndex = -1;

	get activeIndex() {
		if (this.items.length === 0)
			return -1;

		return Math.min(this._activeIndex, this.items.length - 1);
	}

	$host: HTMLElement = this._host.nativeElement;

	get $slides() {
		return this._$slides$.value;
	}

	get isShowBullets() {
		return this.bullets === 'always' || this.bullets && this.items.length > 1;
	}

	@Output('slidesVisibilityChange')
	readonly slidesVisibility$ = new BehaviorSubject<Readonly<ICarouselViewportItemsVisibility>>({});

	get slidesVisibility() {
		return this.slidesVisibility$.value;
	}

	items$ = new BehaviorSubject<any[]>([]);

	prevButtonDisabled$ = this.slidesVisibility$.pipe(
		map(({ firstFullyVisible }) => (firstFullyVisible === undefined || firstFullyVisible === 0) && !this.looped),
		distinctUntilChanged(),
	);

	nextButtonDisabled$ = combineLatest([
		this.slidesVisibility$,
		this.items$,
	])
		.pipe(
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			map(([{ lastFullyVisible }, items ]) => (lastFullyVisible === undefined || lastFullyVisible === (items && items.length - 1)) && !this.looped),
			distinctUntilChanged(),
		);

	showArrowButtons$ = combineLatest([
		this.prevButtonDisabled$,
		this.nextButtonDisabled$,
	])
		.pipe(map(([ previousDisabled, nextDisabled ]) => this.showArrows
				&& this.arrowType !== CarouselArrowType.None
				&& !(previousDisabled && nextDisabled)));

	animate$ = new BehaviorSubject(false);

	viewportHeight$ = new BehaviorSubject<number | null>(null);

	currentItemsPerView$ = new BehaviorSubject<number | 'unlimited'>(this.itemsPerViewport);

	get currentItemsPerView() {
		return this.currentItemsPerView$.value;
	}

	@ContentChild(TemplateRef) template!: TemplateRef<any>;

	@ViewChildren('slide') private readonly _slidesQuery?: QueryList<ElementRef>;

	@ViewChildren(CdkDrag) private readonly _cdkDragSlidesQuery?: QueryList<CdkDrag>;

	@ViewChild('slidesContainer', { static: true })
	private readonly _slidesContainerRef?: ElementRef;

	private get _$slidesContainer(): HTMLElement {
		return this._slidesContainerRef?.nativeElement;
	}

	private readonly _slideMaxWidth$ = new BehaviorSubject<number | null>(null);

	get slideMaxWidth() {
		return this._slideMaxWidth$.value;
	}

	private readonly _$slides$ = new BehaviorSubject<HTMLElement[]>([]);

	private _shouldUpdateScroll = false;

	private readonly _slideStyle$ = new Subject<Dictionary<string>>();

	private readonly _touch: TouchManager;

	private _autoplayTask!: number;

	private _updateScrollSlideMaxWidthChangeSubscription = Subscription.EMPTY;

	private _lastSlidesDimensions?: SlidesDimensions;

	constructor(
		private readonly _host: ElementRef<HTMLElement>,
		private readonly _cdr: ChangeDetectorRef,
		private readonly _touchBuilder: TouchBuilder,
		private readonly _renderer: Renderer2,
	) {
		super();

		this._touch = this._touchBuilder.build(this.$host)!;

		this._touch.swipe$
			.pipe(takeUntilDestroyed(this))
			.subscribe(swipeEvent => void this._onSwipe(swipeEvent));
	}

	ngOnChanges({ items, activeItem, itemsPerViewport }: Partial<SimpleChanges>) {
		if (items && (items.firstChange ? !activeItem : this.resetActiveOnItemsChange))
			this.activateItem(this.items[0], false);

		bpQueueMicrotask(() => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (itemsPerViewport || (items?.previousValue?.length) !== (items?.currentValue?.length))
				this._updateItemsPerView();

			if (items && !items.firstChange)
				this._updateScroll({ animate: false, distinctVisibility: false });
			else if (activeItem && !activeItem.firstChange)
				this._updateScroll({ animate: false });
		});
	}

	ngAfterViewInit(): void {
		this._onCarouselSlidesContainerAnimateEmitEvent();

		this._slidesQuery!.changes
			.pipe(
				startWith<QueryList<ElementRef>>(this._slidesQuery!),
				map(q => q
					.toArray()
					.map(reference => reference.nativeElement)),
				takeUntilDestroyed(this),
			)
			.subscribe(this._$slides$);

		this._$slides$
			.pipe(
				switchMap($slides => fromResize(...$slides)),
				filter(({ target }) => !target.classList.contains('cdk-drag-dragging')),
				takeUntilDestroyed(this),
			)
			.subscribe(() => this._shouldUpdateScroll && void this._updateScroll());

		combineLatest([
			this._$slides$,
			this._slideStyle$,
		])
			.pipe(
				mutate(([ $slides, style ]) => void $slides
					.forEach($slide => forOwn(style, (v, k) => void this._renderer.setStyle($slide, k, v)))),
				takeUntilDestroyed(this),
			)
			.subscribe();

		fromResize(this._$slidesContainer)
			.pipe(
				subscribeOn(BpScheduler.outside),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this._onResize());

		this._updateItemsPerView();

		setTimeout(
			() => {
				this._updateItemsPerView(); // Required second time because slides container width is not determined in modal

				this._updateScroll();
			},
			100, // 100ms required for modal
		);

		this.startAutoplay();

		this._cdr.detectChanges();
	}

	override ngOnDestroy() {
		super.ngOnDestroy();

		this._autoplayTask && this.stopAutoplay();

		this._touch.destroy();
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	trackBy: TrackByFunction<any> = (index, item) => item.id || item.key || item;

	startAutoplay() {
		this.stopAutoplay();

		if (this.autoplayInterval)
			this._autoplayTask = Number(setInterval(() => void this.activateNext(true), this.autoplayInterval));
	}

	stopAutoplay() {
		this._autoplayTask && clearInterval(this._autoplayTask);
	}

	activateItem(item: any, animate = true) {
		if (item === this.activeItem)
			return;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		this.activeItem = item;

		this._shouldUpdateScroll && this._updateScroll({ animate });

		this.activeItemChange$.next(this.activeItem);
	}

	activateIndex(index: number) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const item = index >= 0 ? this.items[index] : undefined;

		this.activateItem(item);
	}

	activateNext(forceLooped?: boolean) {
		if (this.slidesVisibility.lastFullyVisible !== this.items.length - 1)
			this.activateIndex(this.activeIndex + 1);
		else if (this.looped || forceLooped)
			this.activateIndex(0);
	}

	activatePrev(forceLooped?: boolean) {
		if (this.slidesVisibility.firstFullyVisible! > 0)
			this.activateIndex(this.slidesVisibility.firstFullyVisible! - 1);
		else if (this.looped || forceLooped)
			this.activateIndex(this.items.length - 1);
	}

	drop({ previousIndex, currentIndex }: CdkDragDrop<any[]>) {
		if (previousIndex === currentIndex)
			return;

		if (this.sortableItem && !this.sortableItem(this.items[currentIndex]))
			// eslint-disable-next-line no-param-reassign
			currentIndex = previousIndex;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const copy = [ ...this.items ];

		moveItemInArray(copy, previousIndex, currentIndex);

		this.items = copy;

		if (previousIndex === currentIndex)
			return;

		this.sort$.next(this.items);
	}

	private _updateScroll({ animate = false, distinctVisibility = true } = {}) {
		if (!this._slidesQuery)
			return;

		if (!animate) {
			this.animate$.next(false);

			setTimeout(() => void this.animate$.next(true), 50);
		}

		this._updateScrollSlideMaxWidthChangeSubscription.unsubscribe();

		this._updateScrollSlideMaxWidthChangeSubscription = this._slideMaxWidth$
			.pipe(
				switchMap((slideMaxWidth): Observable<SlidesDimensions | null> => fromMeasure(() => slideMaxWidth
					&& this.items.length > 0
					&& this.items.length === this._$slides$.value.length
					&& this.activeIndex > -1
					? {
						slideMaxWidth,
						slidesDimensions: this._$slides$.value.map($slide => new Dimensions({
							left: $slide.offsetLeft >= 0
								? $slide.offsetLeft
								: this._cdkDragSlidesQuery
									?.find(v => v.element.nativeElement === $slide)
									?.getPlaceholderElement()
									?.offsetLeft ?? 0,
							width: Math.floor($slide.getBoundingClientRect().width),
						})),
					}
					: null)),
				filter((v): v is SlidesDimensions => !!v && (animate || !isEqual(v, this._lastSlidesDimensions))),
				tap(v => (this._lastSlidesDimensions = v)),
				takeUntilDestroyed(this),
			)
			.subscribe(({ slideMaxWidth, slidesDimensions }) => {
				let maxOffset = this.currentItemsPerView === 'unlimited'
					? sum(slidesDimensions.map(({ width }) => width)) - slideMaxWidth
					: slideMaxWidth / this.currentItemsPerView * this.items.length - slideMaxWidth;

				if (maxOffset < 0)
					maxOffset = 0;

				const slideOffset = slidesDimensions[this.activeIndex];
				const offset = Math.min(slideOffset.left, maxOffset);

				// Calculate visibility indexes
				const offsetLeft = Math.ceil(offset); // Required because slide offset is always integer
				const offsetRight = offsetLeft + slideMaxWidth;
				const lastIndex = this.items.length - 1;

				const visibilityIndexes: ICarouselViewportItemsVisibility = {};

				let index = this.activeIndex - 1;

				while (index >= 0 && offsetLeft <= slidesDimensions[index].left)
					index--;

				visibilityIndexes.firstFullyVisible = index + 1;

				index = this.activeIndex + 1;
				while (index <= lastIndex && slidesDimensions[index].right <= offsetRight)
					index++;

				visibilityIndexes.lastFullyVisible = index - 1;

				index = this.activeIndex - 1;
				while (index >= 0 && offsetLeft < slidesDimensions[index].right)
					index--;

				visibilityIndexes.firstPartiallyVisible = index + 1;

				index = this.activeIndex + 1;
				while (index <= lastIndex && slidesDimensions[index].left < offsetRight)
					index++;

				visibilityIndexes.lastPartiallyVisible = index - 1;

				this._renderer.setStyle(this._$slidesContainer, 'transform', `translateX(${ -offset }px)`);

				if (!distinctVisibility || !isEqual(visibilityIndexes, this.slidesVisibility$.value)) {
					this.slidesVisibility$.next(visibilityIndexes);

					this.scroll$.next(visibilityIndexes);

					this._shouldUpdateScroll = true;
				}

				this.autoheight && this._setViewportHeightByCurrentView();

				this._cdr.detectChanges();
			});
	}

	private _updateItemsPerView() {
		fromMeasure(() => {
			const viewportWidth = Math.min(window.innerWidth, document.documentElement.clientWidth);

			this.currentItemsPerView$.next(viewportWidth <= this.mobileWidth ? 1 : this.itemsPerViewport);

			return this._$slidesContainer.offsetWidth === 0 ? null : this._$slidesContainer.offsetWidth;
		})
			.pipe(takeUntilDestroyed(this))
			.subscribe(slideMaxWidth => {
				let css: Dictionary<any>;

				if (this.currentItemsPerView === 'unlimited') {
					css = {
						'-ms-flex': null,
						'-webkit-flex': null,
						flex: null,
						'-ms-flex-shrink': 0,
						'-webkit-flex-shrink': 0,
						'flex-shrink': 0,
						width: 'initial',
					};
				} else {

					/*
					 * Use width instead of flex-basis because IE 11 doesn't respect padding on flex-item
					 * @link https://github.com/philipwalton/flexbugs#7-flex-basis-doesnt-account-for-box-sizingborder-box
					 */
					const flex = '0 0 auto';

					css = {
						flex,
						'-ms-flex': flex,
						'-webkit-flex': flex,
						width: `${ Math.trunc(100 / this.currentItemsPerView) }%`,
					};
				}

				if (slideMaxWidth)
					css['max-width'] = `${ slideMaxWidth }px`;

				this._slideStyle$.next(css);

				this._slideMaxWidth$.next(slideMaxWidth);
			});
	}

	private _onResize() {
		this._updateItemsPerView();

		this._updateScroll({ animate: false });
	}

	private _onSwipe(swipeEvent: ISwipeEvent) {
		switch (swipeEvent.bpDirection) {
			case Direction.Right:
				this.activatePrev();
				break;

			case Direction.Left:
				this.activateNext();
				break;

			default:
				// Does nothing
		}
	}

	private _setViewportHeightByCurrentView() {
		this._$slides$
			.pipe(
				first(),
				mergeMap(slides => slides),
				filter((item, index) => this.slidesVisibility.firstPartiallyVisible! <= index
					&& index <= this.slidesVisibility.lastPartiallyVisible!),
				measure($slide => $.outerSize($slide).height),
				max(),
				takeUntilDestroyed(this),
			)
			.subscribe(height => {
				this.viewportHeight$.next(height);

				this._cdr.detectChanges();
			});
	}

	private _onCarouselSlidesContainerAnimateEmitEvent(): void {
		merge(
			fromEvent<TransitionEvent>(this._$slidesContainer, 'transitionstart'),
			fromEvent<TransitionEvent>(this._$slidesContainer, 'transitionend'),
		)
			.pipe(
				filter(({ target, propertyName }) => target === this._$slidesContainer && propertyName === 'transform'),
				takeUntilDestroyed(this),
			)
			.subscribe(({ type }) => void this._animating$.next(type === 'transitionstart'));
	}

}

export interface ICarouselViewportItemsVisibility {
	firstFullyVisible?: number;
	lastFullyVisible?: number;
	firstPartiallyVisible?: number;
	lastPartiallyVisible?: number;
}
