import { PaymentOptionType } from '@bp/shared/models/business';
import type { ICurrency } from '@bp/shared/models/currencies';
import { Currency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import { Control, Default, FieldControlType, Mapper } from '@bp/shared/models/metadata';

import { PspPaymentOptionBase } from './psp-payment-option-base';

export class VoucherPspPaymentOption extends PspPaymentOptionBase {

	static type = PaymentOptionType.voucher;

	readonly type = VoucherPspPaymentOption.type;

	@Mapper(Currency)
	@Default([])
	@Control(
		FieldControlType.chip,
		{
			placeholder: 'Add currency...',
		},
	)
	currencies!: ICurrency[];

	constructor(dto?: DTO<VoucherPspPaymentOption>) {
		super(dto);
	}

}
