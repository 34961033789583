import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesModalModule } from '@bp/shared/features/modal';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedFeaturesSvgPaymentCardBrandsIconsModule } from '@bp/shared/features/svg-payment-card-brands-icons';
import { SharedPipesModule } from '@bp/shared/pipes';

import { AdminsSharedCoreComponentsModule } from '@bp/admins-shared/core/components';
import { AdminsSharedDomainsIdentityModule } from '@bp/admins-shared/domains/identity';
import { AdminsSharedFeaturesLayoutModule } from '@bp/admins-shared/features/layout';
import { AdminsSharedFeaturesNotificationsHubModule } from '@bp/admins-shared/features/notifications-hub';

import { MerchantAdminDomainsIdentityModule } from '@bp/merchant-admin/domains/identity';
import { MerchantAdminDomainsCurrentMerchantSubscriptionModule } from '@bp/merchant-admin/domains/current-merchant-subscription';
import { MerchantAdminFeaturesAnnouncementsModule } from '@bp/merchant-admin/features/announcements';

import { MenuComponent, RootComponent } from './components';
import { SubscriptionSuspendedPageComponent } from './pages';

export const COMPONENTS = [
	RootComponent,
	MenuComponent,
	SubscriptionSuspendedPageComponent,
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MatIconModule,
		MatProgressBarModule,
		MatButtonModule,
		MatMenuModule,

		SharedComponentsCoreModule,
		SharedPipesModule,
		SharedDirectivesModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesModalModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesSvgPaymentCardBrandsIconsModule,

		AdminsSharedDomainsIdentityModule,
		AdminsSharedCoreComponentsModule,
		AdminsSharedFeaturesLayoutModule,
		AdminsSharedFeaturesNotificationsHubModule,

		MerchantAdminFeaturesAnnouncementsModule,
		MerchantAdminDomainsCurrentMerchantSubscriptionModule,
		MerchantAdminDomainsIdentityModule,
	],
	declarations: COMPONENTS,
	exports: COMPONENTS,
})
export class CoreModule { }
