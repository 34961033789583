<h1 class="mb-0 font-weight-normal text-center">
	Downgrading from
	<span class="font-weight-extra-bold"
		>{{ currentChargePeriod.displayName }} {{ currentSubscriptionPlan.displayName }}</span
	>
	to
	<span class="font-weight-extra-bold">{{ chargePeriod.displayName }} {{ subscriptionPlan.displayName }}</span>
</h1>

<div class="mt-5 w-100 relative">
	<bp-alert class="w-100 absolute" type="danger" [errors]="error?.messages"> </bp-alert>
</div>

<content>
	<h3>Are you sure you want to downgrade?</h3>

	<div class="buttons mt-4 mb-5">
		<button type="button" class="bp-round-button" color="primary" mat-flat-button (click)="cancel.emit()">
			No
		</button>

		<button type="button" class="bp-round-button" color="warn" mat-flat-button (click)="downgrade.emit()">
			Yes
		</button>
	</div>
</content>
