import { exhaustMap, filter, map, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiResult, apiVoidResult, apiVoidResultWithRequest } from '@bp/shared/models/common';
import { GoogleTagService, GtagEvents } from '@bp/shared/features/analytics';
import { SubscriptionPlanPurchase } from '@bp/shared/domains/subscription-plans/checkout';

import { SubscriptionPlansSharedFacade } from '@bp/admins-shared/domains/subscription-plans';

import { CurrentMerchantFacade } from '@bp/merchant-admin/domains/current-merchant';

import { CurrentMerchantSubscriptionApiService } from '../services';
import { CurrentMerchantSubscription } from '../models';

import {
	purchaseSubscriptionPlanFailure, purchaseSubscriptionPlanSuccess, downgradeSubscriptionPlanFailure, downgradeSubscriptionPlanSuccess,
	loadFailure, loadSuccess, loadBillingPortalDetailsSuccess, loadBillingPortalDetailsFailure
} from './current-merchant-subscription-api.actions';
import { purchaseSubscriptionPlan, downgradeSubscriptionPlan, refresh, openBillingPortal, loadBillingPortalDetails } from './current-merchant-subscription.actions';

@Injectable()
export class CurrentMerchantSubscriptionEffects {

	load$ = createEffect(() => combineLatest([
		this._currentMerchantFacade.entity$,
		this._subscriptionPlansSharedFacade.all$,
	])
		.pipe(
			filter(([ currentMerchant, subscriptionPlans ]) => !!currentMerchant && !!subscriptionPlans),
			map(([ currentMerchant, subscriptionPlans ]) => new CurrentMerchantSubscription({
				...currentMerchant!.currentSubscription,
				subscriptionPlan: subscriptionPlans!.find(
					subscriptionPlan => subscriptionPlan.id === currentMerchant!.currentSubscription?.subscriptionPlanId,
				),
			})),
			filter(currentMerchantSubscription => !!currentMerchantSubscription.subscriptionPlan),
			apiResult(loadSuccess, loadFailure),
		));

	refresh$ = createEffect(
		() => this._actions$.pipe(
			ofType(refresh),
			tap(() => void this._currentMerchantFacade.refresh()),
		),
		 { dispatch: false },
	 );

	purchaseSubscriptionPlan$ = createEffect(() => this._actions$.pipe(
		ofType(purchaseSubscriptionPlan),
		exhaustMap(request => this._currentMerchantSubscriptionApiService
			.purchaseSubscriptionPlan(request)
			.pipe(apiVoidResultWithRequest({
				request,
				successAction: purchaseSubscriptionPlanSuccess,
				failureAction: purchaseSubscriptionPlanFailure,
			}))),
	));

	downgradeSubscription$ = createEffect(() => this._actions$.pipe(
		ofType(downgradeSubscriptionPlan),
		exhaustMap(request => this._currentMerchantSubscriptionApiService
			.downgradeSubscription(request)
			.pipe(apiVoidResult(downgradeSubscriptionPlanSuccess, downgradeSubscriptionPlanFailure))),
	));

	openBillingPortal$ = createEffect(() => this._actions$.pipe(
		ofType(openBillingPortal),
		map(() => loadBillingPortalDetails()),
	));

	loadBillingPortalDetails$ = createEffect(() => this._actions$.pipe(
		ofType(loadBillingPortalDetails),
		exhaustMap(() => this._currentMerchantSubscriptionApiService
			.loadBillingPortalDetails()
			.pipe(apiResult(loadBillingPortalDetailsSuccess, loadBillingPortalDetailsFailure))),
	));

	onLoadBillingPortalDetailsOpenBillingPortal$ = createEffect(
		() => this._actions$.pipe(
			ofType(loadBillingPortalDetailsSuccess),
			tap(({ result: { url } }) => window.open(url, '_blank', 'noreferrer')),
		),
		{ dispatch: false },
	);

	onPurchaseSubscriptionPlan$ = createEffect(
		() => this._actions$.pipe(
			ofType(purchaseSubscriptionPlanSuccess, purchaseSubscriptionPlanFailure),
			tap(({ type, request }) => void this._dispatchPurchaseGoogleTagEvent(
				request,
				type === purchaseSubscriptionPlanSuccess.type ? 'success' : 'failure',
			)),
		),
		{ dispatch: false },
	);

	constructor(
		private readonly _currentMerchantFacade: CurrentMerchantFacade,
		private readonly _subscriptionPlansSharedFacade: SubscriptionPlansSharedFacade,
		private readonly _currentMerchantSubscriptionApiService: CurrentMerchantSubscriptionApiService,
		private readonly _actions$: Actions,
		private readonly _googleTagService: GoogleTagService,
	) {
	}

	private _dispatchPurchaseGoogleTagEvent(
		{ subscriptionPlan, chargePeriod, currency }: SubscriptionPlanPurchase,
		result: 'failure' | 'success',
	): void {
		const payload: GtagEvents.IEventPayloadMap['purchase'] = {
			subscriptionPlan: subscriptionPlan.type.name,
			chargePeriod: chargePeriod.name,
			value: subscriptionPlan.getMonthPriceFor(chargePeriod, currency),
			transactionsLimit: subscriptionPlan.limits.transactions ?? 0,
			transactionPrice: subscriptionPlan.getTransactionPriceFor(chargePeriod, currency) ?? 0,
			currency: currency.code,
		};

		const purchaseEventName: GtagEvents.List = `purchase${ result === 'success' ? '' : '_failed' }`;

		this._googleTagService.dispatchEvent(purchaseEventName, payload);

		this._googleTagService.dispatchEvent(`${ payload.subscriptionPlan }_plan_${ purchaseEventName }`, payload);
	}
}
