import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatPseudoCheckboxModule, MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';

import { AdminsSharedCoreComponentsModule } from '@bp/admins-shared/core/components';

import { PspThumbnailModule } from '../../../psp-thumbnail';
import { PaywithBadgeModule } from '../../../paywith-badge';

import { BridgerPspCardComponent } from './bridger-psp-card';
import { BridgerPspsMultipickerComponent } from './bridger-psps-multipicker.component';

const EXPOSED = [ BridgerPspsMultipickerComponent ];

@NgModule({
	imports: [
		CommonModule,
		MatCardModule,
		MatPseudoCheckboxModule,
		MatMenuModule,
		MatRippleModule,

		SharedDirectivesModule,
		SharedComponentsCoreModule,
		SharedFeaturesTooltipModule,
		AdminsSharedCoreComponentsModule,

		PspThumbnailModule,
		PaywithBadgeModule,
	],
	exports: EXPOSED,
	declarations: [ EXPOSED, BridgerPspCardComponent ],
})
export class BridgerPspsMultipickerModule { }
