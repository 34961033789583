import { ToastrService } from 'ngx-toastr';

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { ControlsSectionScheme, FormMetadataEntityBaseComponent } from '@bp/shared/components/metadata';
import { DTO } from '@bp/shared/models/metadata';
import { PaymentCardBrand } from '@bp/shared/models/business';
import { FADE } from '@bp/shared/animations';
import { Countries, Country } from '@bp/shared/models/countries';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { OnChanges, SimpleChanges } from '@bp/shared/models/core';
import { FORM_FIELD_DEFAULT_OPTIONS, IFormFieldDefaultOptions } from '@bp/shared/components/core';

import { ICountryVatConfig, VatConfigPerCountry } from '../../../../models';
import { SubscriptionPlanCheckoutPaymentInformationFormVm } from '../../../../models/subscription-plan-checkout-payment-information-form-vm';

@Component({
	selector: 'bp-payment-information-form',
	templateUrl: './payment-information-form.component.html',
	styleUrls: [ './payment-information-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
	providers: [
		{
			provide: FORM_FIELD_DEFAULT_OPTIONS,
			useValue: <IFormFieldDefaultOptions>{
				appearance: 'round',
				compact: true,
			},
		},
	],
})
export class PaymentInformationFormComponent
	extends FormMetadataEntityBaseComponent<SubscriptionPlanCheckoutPaymentInformationFormVm> implements OnChanges {

	@Input()
	set defaultCountry(value: Country | null) {
		if (value && !this.selectedCountry)
			this.form?.patchValue({ country: value });
	}

	@Input() vatConfigPerCountry!: VatConfigPerCountry | null;

	get selectedCountry(): Country | null {
		return <Country | null> this.controls!.country.value;
	}

	get countryVatConfig(): ICountryVatConfig | null {
		return this.selectedCountry
			? this.vatConfigPerCountry?.[this.selectedCountry.code] ?? null
			: null;
	}

	countryHasStates = false;

	override metadata = SubscriptionPlanCheckoutPaymentInformationFormVm.getClassMetadata();

	paymentCardBrand: PaymentCardBrand | null = null;

	override onSubmitShowInvalidInputsToast = false;

	baseUiScheme: ControlsSectionScheme<SubscriptionPlanCheckoutPaymentInformationFormVm> = [
		[ 'address' ],
		[ 'city', 'zipCode' ],
	];

	// Despite the fact there are more countries with states, we process only those cases.
	countriesWithStates = Countries.fromCodes([ 'US', 'CA', 'BR' ]);

	constructor(
		fb: FormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(fb, cdr, toaster);

		this.entity = this.factory();

		this._setupForm({
			paymentCardNumber: null,
			paymentCardHolderName: null,
			paymentCardExpirationDate: null,
			paymentCardCvv: null,
			address: null,
			city: null,
			zipCode: null,
			country: null,
			state: null,
			vatId: null,
		});

		this._onCountryChangeUpdateScheme();
	}

	override factory = (
		dto?: DTO<SubscriptionPlanCheckoutPaymentInformationFormVm>,
	): SubscriptionPlanCheckoutPaymentInformationFormVm => new SubscriptionPlanCheckoutPaymentInformationFormVm(dto);

	protected override _getSubmittedValidFormValue(): SubscriptionPlanCheckoutPaymentInformationFormVm {
		return this.entity!;
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		if (changes.vatConfigPerCountry)
			this._updateScheme();
	}

	private _onCountryChangeUpdateScheme(): void {
		this.controls!.country.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this._updateScheme());
	}

	private _updateScheme(): void {
		if (!this.countryVatConfig)
			this._resetValueAndErrors(this.controls!.vatId);

		this.countryHasStates = this.countriesWithStates.includes(this.selectedCountry!);

		if (!this.countryHasStates)
			this._resetValueAndErrors(this.controls!.state);

		this._cdr.detectChanges();
	}

	private _resetValueAndErrors(control: FormControl): void {
		control.setValue(null);

		control.setErrors(null);
	}

}
