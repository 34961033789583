import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { PermissionBasedNavigationService } from '@bp/shared-domains-identity';

@Injectable({
	providedIn: 'root',
})
export class NavigationToRootRedirectionGuard implements CanActivate {
	constructor(private readonly _permissionBasedDefaultNavigationService: PermissionBasedNavigationService) {}

	canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
		this._permissionBasedDefaultNavigationService.tryNavigate();

		return false;
	}
}
