<h1 class="text-center">
	Purchase {{ chargePeriod.displayName }}
	{{ subscriptionPlan.displayName }}
	<ng-container *ngIf="merchantName">for {{ merchantName }}</ng-container>
</h1>

<content>
	<bp-payment-information-form
		#resourcePaywallDialogForm
		[vatConfigPerCountry]="subscriptionPlanCheckoutFacade.vatConfigPerCountry$ | async"
		[defaultCountry]="subscriptionPlanCheckoutFacade.presentCountry$ | async"
		[pending]="pending"
		[error]="(subscriptionPlanCheckoutFacade.error$ | async) || error"
		(submitted)="buildAndSubmitSubscriptionPlanPurchase($event)"
	>
	</bp-payment-information-form>

	<div class="purchase-summary flex-column ml-auto mr-auto">
		<bp-img url="{{ _assetsDir }}/pricing-rocket.png" class="pricing-rocket"></bp-img>

		<bp-charge-period-switcher
			class="mt-4"
			[@fadeInFixedTriggered]="!fixedChargePeriod"
			[(chargePeriod)]="chargePeriod"
		></bp-charge-period-switcher>

		<bp-price-breakdown
			@fadeIn
			class="mt-4"
			[subscriptionPlan]="subscriptionPlan"
			[chargePeriod]="chargePeriod"
			[vatConfigPerCountry]="subscriptionPlanCheckoutFacade.vatConfigPerCountry$ | async"
			[currency]="currency"
			[paymentInformation]="resourcePaywallDialogForm.entity"
		></bp-price-breakdown>

		<hr class="mt-3 mb-4" />

		<button
			type="button"
			class="complete-purchase-btn bp-round-button w-100 mt-2 mr-auto ml-auto"
			color="primary"
			mat-flat-button
			[bpGoogleTagOnClick]="
				subscriptionPlan.isEnterprise
					? 'enterprise_subscription_checkout_purchase_click'
					: 'subscription_management_checkout_purchase_click'
			"
			[disabled]="
				pending ||
				(subscriptionPlanCheckoutFacade.presentVatConfig$ | async) === null ||
				(subscriptionPlanCheckoutFacade.pending$ | async)
			"
			(click)="resourcePaywallDialogForm.submit()"
		>
			Complete Purchase
		</button>

		<bp-img url="{{ _assetsDir }}/trust-logos-desktop.png" class="trust-logos mt-4 mr-auto ml-auto"></bp-img>
	</div>
</content>
