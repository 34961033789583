import { ClipboardService } from 'ngx-clipboard';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FADE_IN_STAGGERING_CHILDREN } from '@bp/shared/animations';
import { ModalHostComponent, ModalService } from '@bp/shared/features/modal';

import { AnnouncementFacade } from '../../state/entity/announcement.facade';

@Component({
	selector: 'bp-announcement-modal',
	templateUrl: './announcement-modal.component.html',
	styleUrls: [ './announcement-modal.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_STAGGERING_CHILDREN ],
})
export class AnnouncementModalComponent extends ModalHostComponent {

	urlCopied = false;

	override panelClass = [ 'announcement-modal-overlay-pane', 'modal-overlay-pane-lg' ];

	readonly announcementId = <string> this._activatedRoute.snapshot.params['id'];

	constructor(
		public announcementFacade: AnnouncementFacade,
		private readonly _clipboardService: ClipboardService,
		private readonly _router: Router,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _cdr: ChangeDetectorRef,
		modalService: ModalService,
	) {
		super(modalService);

		this.announcementFacade.load(this.announcementId);
	}

	createAnnouncementUrlAndCopyToClipboard(): void {
		this._clipboardService.copy(this._createAnnouncementUrl());

		this._markAsUrlCopiedForShortDuration();
	}

	private _markAsUrlCopiedForShortDuration(): void {
		this.urlCopied = true;

		setTimeout(() => {
			this.urlCopied = false;

			this._cdr.detectChanges();
		}, 5000);
	}

	private _createAnnouncementUrl(): string {
		const url = this._router.createUrlTree([{
			outlets: {
				primary: 'transactions',
				modal: [ 'announcement', this.announcementId ],
			},
		}]).toString();

		return window.location.origin + url;
	}

}
