import { camelCase, kebabCase } from 'lodash-es';

import { Injectable } from '@angular/core';

import { LocalStorageService } from '@bp/shared/services';
import { UrlHelper } from '@bp/shared/utilities';

import { IUtmTags } from '@bp/firebase-functions';

type UtmSearchParamEntry = [ utmTagName: string, utmValue: string ];

const REFERER_KEY = 'referer';

@Injectable({
	providedIn: 'root',
})
export class UtmService {

	private static readonly _timeToLive = 60 * 60 * 24 * 45; // 45 days

	private readonly _initialPageURL = new URL(window.location.toString());

	private readonly _searchParamsEntries: UtmSearchParamEntry[] = [];

	readonly tags: IUtmTags = {
		id: this._getUtmTagFromStorageOrURL('utm_id'),
		source: this._getUtmTagFromStorageOrURL('utm_source'),
		medium: this._getUtmTagFromStorageOrURL('utm_medium'),
		campaign: this._getUtmTagFromStorageOrURL('utm_campaign'),
		term: this._getUtmTagFromStorageOrURL('utm_term'),
		content: this._getUtmTagFromStorageOrURL('utm_content'),
		adsetName: this._getUtmTagFromStorageOrURL('utm_adset_name'),
		campaignid: this._getUtmTagFromStorageOrURL('utm_campaignid'),
		adname: this._getUtmTagFromStorageOrURL('utm_adname'),
		placement: this._getUtmTagFromStorageOrURL('utm_placement'),
		adid: this._getUtmTagFromStorageOrURL('utm_adid'),
		liFatId: this._getUtmTagFromStorageOrURL('li_fat_id'),
		gclid: this._getUtmTagFromStorageOrURL('gclid'),
		fbclid: this._getUtmTagFromStorageOrURL('fbclid'),
		variant: this._getUtmTagFromStorageOrURL('variant'),
		[REFERER_KEY]: UrlHelper.stripQueryParamsFromURL(this._getUtmTagFromStorageOrURL(REFERER_KEY)),
	};

	constructor(private readonly _localStorageService: LocalStorageService) { }

	init(): void {
		// intentionally blank
	}

	getUtmSearchParamsEntries(): UtmSearchParamEntry[] {
		return this._searchParamsEntries;
	}

	private _getUtmTagFromStorageOrURL(utmTagName: string): string | undefined {
		let utmTagValueFromURL = this._findInURLInDifferentCases(utmTagName);

		if (!utmTagValueFromURL && utmTagName === REFERER_KEY)
			utmTagValueFromURL = document.referrer;

		if (utmTagValueFromURL)
			this._localStorageService.setDying(utmTagName, utmTagValueFromURL, UtmService._timeToLive);

		const utmValue = this._localStorageService.getAlive<string>(utmTagName);

		if (utmValue)
			this._searchParamsEntries.push([ utmTagName, utmValue ]);

		return utmValue;
	}

	private _findInURLInDifferentCases(utmTagName: string): string | undefined {
		for (const casedUtmTagName of [ utmTagName, camelCase(utmTagName), kebabCase(utmTagName) ]) {
			const utmTagValueFromURL = this._initialPageURL.searchParams.get(casedUtmTagName);

			if (utmTagValueFromURL)
				return utmTagValueFromURL;
		}

		return undefined;
	}

}
