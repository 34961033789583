import { Component, ChangeDetectionStrategy } from '@angular/core';

import { MockedBackendState } from '@bp/shared/services';
import { JwtToken } from '@bp/shared/utilities';
import { SLIDE, SLIDE_IN, SLIDE_UP } from '@bp/shared/animations';

import {
	getMockUsersCredentials,
	getNeverExpiringEpoch,
	getProMockUserCredentials,
	IdentityJwtPayloadDTO,
	MerchantAdminFeature,
	MockUserEmail
} from '@bp/shared-domains-identity';

import type { OtpProvider } from '@bp/admins-shared/domains/identity';

import { IntroPageStep } from '../../../../models';
import { IntroFacade } from '../../../../state';

interface IDevLink {

	title: string;

	url: string;

}

@Component({
	selector: 'bp-intro-shell-page-footer',
	templateUrl: './intro-shell-page-footer.component.html',
	styleUrls: [ './intro-shell-page-footer.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE, SLIDE_IN, SLIDE_UP ],
})
export class IntroShellPageFooterComponent {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	MockedBackendState = MockedBackendState;

	mockUsersCredentials = getMockUsersCredentials();

	mockUserEmails = <MockUserEmail[]>Object.keys(getMockUsersCredentials());

	devLinks: IDevLink[] = [
		{
			title: 'Accept Invite',
			url: `intro/${ IntroPageStep.acceptInvite.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.acceptInvite.claim,
			]) }`,
		},
		{
			title: IntroPageStep.createAccount.titleDisplayName,
			url: `intro/${ IntroPageStep.createAccount.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.createAccount.claim,
			]) }`,
		},

		// #region deprecated, remove after accepting invite implementation by backend

		{
			title: `Deprecated: ${ IntroPageStep.emailVerification.titleDisplayName }`,
			url: `intro/${ IntroPageStep.emailVerification.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.confirmationEmail.claim,
			]) }`,
		},
		{
			title: `Deprecated: ${ IntroPageStep.createPassword.titleDisplayName }`,
			url: `intro/${ IntroPageStep.createPassword.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.setPasswordOnLogin.claim,
			]) }`,
		},

		// #endregion !deprecated

		{
			title: IntroPageStep.setSecurityQuestionsAnswers.titleDisplayName,
			url: `intro/${ IntroPageStep.setSecurityQuestionsAnswers.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.setSecurityQuestionsAnswers.claim,
			]) }`,
		},
		{
			title: IntroPageStep.registerAuthenticatorApp.titleDisplayName,
			url: `intro/${ IntroPageStep.registerAuthenticatorApp.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.registerAuthenticator.claim,
			]) }`,
		},
		{
			title: IntroPageStep.sendResetAuthenticatorAppLink.titleDisplayName,
			url: `intro/${ IntroPageStep.sendResetAuthenticatorAppLink.kebabName }`,
		},
		{
			title: IntroPageStep.resetPassword.titleDisplayName,
			url: `intro/${ IntroPageStep.resetPassword.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.resetPassword.claim,
			]) }`,
		},
		{
			title: 'Reset Expired Password',
			url: `intro/${ IntroPageStep.resetPassword.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.resetExpiredPassword.claim,
			]) }`,
		},
		{
			title: `${ IntroPageStep.resetPasswordVerification.titleDisplayName }`,
			url: `intro/${ IntroPageStep.resetPasswordVerification.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.verifyAnswerBeforeResetPassword.claim,
				MerchantAdminFeature.otpResetPasswordVerification.claim,
			]) }`,
		},
		{
			title: `${ IntroPageStep.resetPasswordVerification.titleDisplayName } via only answers`,
			url: `intro/${ IntroPageStep.resetPasswordVerification.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.verifyAnswerBeforeResetPassword.claim,
			]) }`,
		},
		{
			title: '2-step Reset Authenticator',
			url: `intro/${ IntroPageStep.resetAuthenticatorAppVerification.kebabName }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.verifyAnswerBeforeResetAuthenticator.claim,
			]) }`,
		},
		{
			title: 'Login via JWT',
			url: `intro/${ IntroPageStep.login.kebabName }?jwt=${ JwtToken.encode(getProMockUserCredentials()) }`,
		},
	];

	constructor(public introFacade: IntroFacade) { }

	getMockUserFullname(email: unknown): string | undefined {
		return this.mockUsersCredentials[<MockUserEmail>email].userFullName;
	}

	private _generateMockAuthJWT(permissions: string[], otpProvider?: OtpProvider): string {
		return JwtToken.encode<IdentityJwtPayloadDTO>({
			userIdentityId: '5778191a-d31f-46e3-8313-0757cab97486',
			userFullName: 'Mock Auth Test',
			userEmail: MockUserEmail.Pro,
			otpProvider: otpProvider?.name,
			permissions,
			exp: getNeverExpiringEpoch(),
		});
	}
}
