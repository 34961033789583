import { firstValueFrom, map } from 'rxjs';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
	MerchantAdminFeature,
	FeaturePermission,
	IIdentityGetAccessDialogGuard,
	Permission
} from '@bp/shared-domains-identity';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import {
	ResourceAccessOtpVerificationDialogData,
	ResourcePaywallDialogComponent,
	ResourcePaywallDialogData,
	ResourceAccessOtpVerificationDialogComponent
} from '../components';

@Injectable({
	providedIn: 'root',
})
export class IdentityGetAccessDialogGuard implements IIdentityGetAccessDialogGuard {
	constructor(private readonly _identityFacade: IdentityFacade, private readonly _dialog: MatDialog) {}

	shouldShowGetAccessDialog(permission: Permission): boolean {
		const featurePermission = this._identityFacade.user!.featurePermissions.get(permission);

		return !!featurePermission?.isRequiredOTP || !!featurePermission?.isBehindPaywall;
	}

	async getAccess(permission: Permission): Promise<boolean> {
		let featurePermission = this._identityFacade.user!.featurePermissions.get(permission);

		let hasAccess = true;

		hasAccess = featurePermission?.isBehindPaywall ? await this._showPaywallDialogAndWaitForResult() : hasAccess;

		if (!hasAccess)
			return false;

		featurePermission = this._identityFacade.user!.featurePermissions.get(permission);

		hasAccess = featurePermission?.isRequiredOTP
			? await this._showOtpVerificationDialogAndWaitForResult(featurePermission)
			: hasAccess;

		return hasAccess;
	}

	private async _showOtpVerificationDialogAndWaitForResult(
		featurePermission: FeaturePermission<MerchantAdminFeature>,
	): Promise<boolean> {
		return firstValueFrom(
			this._dialog
				.open<ResourceAccessOtpVerificationDialogComponent, ResourceAccessOtpVerificationDialogData, boolean>(
				ResourceAccessOtpVerificationDialogComponent,
				{
					data: {
						featurePermission,
					},
				},
			)
				.afterClosed()
				.pipe(map(result => !!result)),
		);
	}

	private async _showPaywallDialogAndWaitForResult(): Promise<boolean> {
		return firstValueFrom(
			this._dialog
				.open<ResourcePaywallDialogComponent, ResourcePaywallDialogData, boolean>(
				ResourcePaywallDialogComponent,
			)
				.afterClosed()
				.pipe(map(result => !!result)),
		);
	}
}
