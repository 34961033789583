import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { FADE_IN } from '@bp/shared/animations';

import { EditableCardBaseComponent } from '../editable-card-base-component';

@Component({
	selector: 'bp-editable-view-list-card',
	templateUrl: './editable-view-list-card.component.html',
	styleUrls: [ '../editable-view-card/editable-view-card.component.scss', './editable-view-list-card.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class EditableViewListCardComponent extends EditableCardBaseComponent {

	@HostBinding('class.card-view-list')
	get isCardViewList(): boolean {
		return this.view;
	}
}
