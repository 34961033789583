<table class="feature-comparison-table">
	<thead>
		<th></th>

		<th></th>

		<th *ngFor="let subscriptionPlan of subscriptionPlans">{{ subscriptionPlan.name }}</th>
	</thead>

	<tbody>
		<tr *ngFor="let featureRowItem of featureRowItems">
			<td class="feature-name">{{ featureRowItem.featureName }}</td>

			<td>
				<bp-svg-icon
					*ngIf="featureRowItem.featureDescription"
					name="more-info"
					[bpTooltip]="featureRowItem.featureDescription"
				></bp-svg-icon>
			</td>

			<td *ngFor="let limit of featureRowItem.limitsPerSubscriptionPlan">
				<bp-feature-presence-or-count [value]="limit"></bp-feature-presence-or-count>
			</td>

			<td *ngFor="let existsOrCount of featureRowItem.presenceOrCountPerSubscriptionPlan">
				<bp-feature-presence-or-count [value]="existsOrCount"></bp-feature-presence-or-count>
			</td>
		</tr>
	</tbody>
</table>
