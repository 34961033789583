import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ApiMockPluginsManagerService } from '@bp/shared/services';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesAnalyticsModule } from '@bp/shared/features/analytics';
import { SharedComponentsPaymentCardModule } from '@bp/shared/components/payment-card';
import { SharedComponentsMetadataModule } from '@bp/shared/components/metadata';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedPipesModule } from '@bp/shared/pipes';

import {
	SubscriptionPlanCheckoutComponent, PaymentInformationFormComponent, ChargePeriodSwitcherComponent, PriceBreakdownComponent
} from './components';
import { subscriptionPlanCheckoutFeature } from './state/subscription-plan-checkout.feature';
import { SubscriptionPlanCheckoutEffects } from './state/subscription-plan-checkout.effects';

const EXPOSED = [
	SubscriptionPlanCheckoutComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatSlideToggleModule,

		StoreModule.forFeature(subscriptionPlanCheckoutFeature),
		EffectsModule.forFeature([ SubscriptionPlanCheckoutEffects ]),

		SharedFeaturesAnalyticsModule,
		SharedComponentsCoreModule,
		SharedComponentsMetadataModule,
		SharedComponentsPaymentCardModule,
		SharedDirectivesModule,
		SharedPipesModule,
	],
	declarations: [
		EXPOSED,
		PaymentInformationFormComponent,
		ChargePeriodSwitcherComponent,
		PriceBreakdownComponent,
	],
	exports: EXPOSED,
})
export class SharedDomainsSubscriptionPlansCheckoutModule {
	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(
			async () => import('./api-mock-plugin/subscription-plan-checkout-api-mock-plugin'),
		);
	}
}
