import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';

import { SLIDE } from '@bp/shared/animations';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { UserFacade } from '../../state';

import { ChangePasswordFormComponent } from './components';

@Component({
	selector: 'bp-change-password-page',
	templateUrl: './change-password-page.component.html',
	styleUrls: [ './change-password-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		class: 'right-drawer-content',
	},
	animations: [ SLIDE ],
})
export class ChangePasswordPageComponent extends Destroyable {

	@ViewChild(ChangePasswordFormComponent)
	private readonly _formComponent!: ChangePasswordFormComponent;

	constructor(
		public identityFacade: IdentityFacade,
		public userFacade: UserFacade,
	) {
		super();

		this.userFacade.changePasswordSuccess$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this._formComponent.resetForm());
	}

}
