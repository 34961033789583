<ng-container *ngIf="currentMerchantSubscriptionFacade.entity$ | async as currentMerchantSubscription">
	<bp-current-subscription-indicator-button
		@fadeIn
		cdkOverlayOrigin
		#trigger="cdkOverlayOrigin"
		[currentMerchantSubscription]="currentMerchantSubscription"
		(click)="isLimitsMeterPopupOpen = !isLimitsMeterPopupOpen"
	></bp-current-subscription-indicator-button>

	<ng-template
		cdkConnectedOverlay
		cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
		[cdkConnectedOverlayOffsetY]="5"
		[cdkConnectedOverlayOrigin]="trigger"
		[cdkConnectedOverlayOpen]="isLimitsMeterPopupOpen"
		[cdkConnectedOverlayHasBackdrop]="true"
		[cdkConnectedOverlayPositions]="popupPosition"
		(backdropClick)="isLimitsMeterPopupOpen = false"
	>
		<bp-current-subscription-limits-meter-popup
			@fade
			[currentMerchantSubscription]="currentMerchantSubscription"
		></bp-current-subscription-limits-meter-popup>
	</ng-template>
</ng-container>
