import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { CurrentMerchantSubscription, CurrentMerchantSubscriptionFeatureLimit } from '../../../../models';

@Component({
	selector: 'bp-current-subscription-indicator-button',
	templateUrl: './current-subscription-indicator-button.component.html',
	styleUrls: [ './current-subscription-indicator-button.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSubscriptionIndicatorButtonComponent {

	@Input() currentMerchantSubscription!: CurrentMerchantSubscription;

	get currentSubscriptionPlan(): SubscriptionPlan {
		return this.currentMerchantSubscription.subscriptionPlan;
	}

	get transactionsLimit(): CurrentMerchantSubscriptionFeatureLimit {
		return this.currentMerchantSubscription.featureLimitsMap.get('transactions')!;
	}

	get isWarningOrDangerThresholdReached(): boolean {
		return this.transactionsLimit.isWarningThresholdReached || this.transactionsLimit.isDangerThresholdReached;
	}
}
