import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import type { RecordsPage } from '@bp/shared/models/common';
import type { DTO } from '@bp/shared/models/metadata';
import { mapTo } from '@bp/shared/rxjs';

import { Merchant, PaymentRoute } from '@bp/admins-shared/core/models';

@Injectable({
	providedIn: 'root',
})
export class CurrentMerchantApiService {

	private readonly _namespace = 'merchant';

	constructor(
		private readonly _http: HttpClient,
	) { }

	getCurrent(): Observable<Merchant> {
		return this._http
			.get<DTO<Merchant>>(this._namespace)
			.pipe(mapTo(Merchant));
	}

	 getPaymentRoutes(): Observable<PaymentRoute[]> {
		return this._http
			.get<RecordsPage<DTO<PaymentRoute>>>('payment-routes', { params: { limit: 99_999 } })
			.pipe(map(it => it.records.map(v => new PaymentRoute(v))));
	}

}
