import { PaymentCardBrand, PaymentOptionType } from '@bp/shared/models/business';
import type { ICurrency } from '@bp/shared/models/currencies';
import { Currency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import {
	Control, Default, FieldControlType, FieldViewType, Label, Mapper, View
} from '@bp/shared/models/metadata';

import { PspPaymentOptionBase } from './psp-payment-option-base';

export class CreditCardPspPaymentOption extends PspPaymentOptionBase {

	static type = PaymentOptionType.creditCard;

	readonly type = CreditCardPspPaymentOption.type;

	@Mapper(PaymentCardBrand)
	@Default([])
	@Control(FieldControlType.brandChips)
	brands!: PaymentCardBrand[];

	@Mapper(Currency)
	@Default([])
	@Control(
		FieldControlType.chip,
		{
			placeholder: 'Add currency...',
		},
	)
	currencies!: ICurrency[];

	@Default(false)
	@View(FieldViewType.boolean)
	@Label('Is 3D Secure')
	is3dSecure!: boolean;

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	usedInPaywith!: boolean;

	constructor(dto?: DTO<CreditCardPspPaymentOption>) {
		super(dto);
	}

}
