import { createAction, props } from '@ngrx/store';

import type { ErrorActionPayload } from '@bp/shared/models/common';

import { ACTIONS_SCOPE } from './user.actions';

const apiScope = `[API]${ ACTIONS_SCOPE }`;

export const changePasswordSuccess = createAction(
	`${ apiScope } Change Password Success`,
);

export const changePasswordFailure = createAction(
	`${ apiScope } Change Password Failure`,
	props<ErrorActionPayload>(),
);
