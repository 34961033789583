import { distinctUntilChanged, map } from 'rxjs';

import { Injectable } from '@angular/core';

import { EnvironmentService, IntercomService, LocalStorageService, TelemetryService } from '@bp/shared/services';

import { GoogleTagService } from './google-tag.service';
import { UiExperimentsService } from './ui-experiments.service';
import { UtmService } from './utm.service';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

	private readonly _enabled = !!this._environmentService.googleTagAnalyticsId && !!this._environmentService.googleTagTransportUrl;

	private _inited = false;

	constructor(
		private readonly _uiExperiments: UiExperimentsService,
		private readonly _googleTagService: GoogleTagService,
		private readonly _utmService: UtmService,
		private readonly _environmentService: EnvironmentService,
		private readonly _intercomService: IntercomService,
		private readonly _telemetryService: TelemetryService,
		private readonly _localStorageService: LocalStorageService,
	) {

	}

	init(): void {
		if (!this._enabled || this._inited)
			return;

		this._initGoogleTagService();

		this._setGoogleTagGlobalVariables();

		this._setUiExperimentsToAnalyticChannels();

		this._setGTagBackendVersionVariable();

		this._setGTagUserSessionRecordingURLVariable();

		this._setIntercomUtmTags();

		this._inited = true;
	}

	private _initGoogleTagService(): void {
		this._googleTagService.init({
			environment: this._environmentService.name,
			transportUrl: this._environmentService.googleTagTransportUrl!,
			analyticsId: this._environmentService.googleTagAnalyticsId!,
			platform: this._environmentService.platform,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			debug_mode: this._tryGetDebugModeFromURLorSessionStorage(),
		});
	}

	private _setGoogleTagGlobalVariables(): void {
		const utmTags = this._utmService.tags;

		this._googleTagService.setGlobalVariables({
			utmTags,
			frontendVersion: this._environmentService.appVersion.release,
			referer: utmTags.referer,
			/* eslint-disable @typescript-eslint/naming-convention */
			campaign_id: utmTags.id,
			campaign_name: utmTags.campaign,
			campaign_source: utmTags.source,
			campaign_medium: utmTags.medium,
			campaign_term: utmTags.term,
			campaign_content: utmTags.content,
			gclid: utmTags.gclid,
			/* eslint-enable @typescript-eslint/naming-convention */
		});
	}

	private _setUiExperimentsToAnalyticChannels(): void {
		this._uiExperiments.experiments$
			.pipe(
				map(() => this._uiExperiments.getExperimentsDisplayNames().toString()),
				distinctUntilChanged(),
			)
			.subscribe(uiExperiments => {
				void this._googleTagService.setGlobalVariables({ uiExperiments });

				void this._intercomService.update({ uiExperiments });
			});
	}

	private _setGTagBackendVersionVariable(): void {
		this._environmentService.backendVersion$
			.subscribe(version => void this._googleTagService.setGlobalVariables({
				backendVersion: version,
			}));
	}

	private _setGTagUserSessionRecordingURLVariable(): void {
		this._telemetryService.userSessionRecordingUrl$
			?.subscribe(userSessionRecordingUrl => void this._googleTagService.setGlobalVariables({
				userSessionRecordingUrl,
			}));
	}

	private _setIntercomUtmTags(): void {
		void this._intercomService.update({
			platform: this._environmentService.platform,
			environment: this._environmentService.name,
			adsetName: this._utmService.tags.adsetName,
			campaignid: this._utmService.tags.campaignid,
			adname: this._utmService.tags.adname,
			placement: this._utmService.tags.placement,
			adid: this._utmService.tags.adid,
			gclid: this._utmService.tags.gclid,
			fbclid: this._utmService.tags.fbclid,
			liFatId: this._utmService.tags.liFatId,
			variant: this._utmService.tags.variant,
		});
	}

	private _tryGetDebugModeFromURLorSessionStorage(): boolean | undefined {
		const debugModeKey = 'analyticsDebugMode';
		const queryDebugMode = new URL(location.href).searchParams.get(debugModeKey) === 'true';

		if (queryDebugMode)
			this._localStorageService.setDying(debugModeKey, true, 60 * 60 * 1 /* 1 hour */);

		return queryDebugMode || this._localStorageService.getAlive(debugModeKey);
	}
}
