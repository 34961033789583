import { take } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { filterPresent } from '@bp/shared/rxjs';
import { SubscriptionPlanPurchase } from '@bp/shared/domains/subscription-plans/checkout';

import { CurrentMerchantSubscription, DowngradeSubscriptionPlanApiRequest } from '../models';

import {
	resetState, load, purchaseSubscriptionPlan, downgradeSubscriptionPlan, refresh, openBillingPortal, resetError
} from './current-merchant-subscription.actions';
import {
	selectError, selectCurrentChargePeriod, selectCurrentMerchantSubscription, selectCurrentMerchantSubscriptionLoading, selectCurrentMerchantSubscriptionPending, selectCurrentSubscriptionPlan
} from './current-merchant-subscription.selectors';
import {
	purchaseSubscriptionPlanSuccess, downgradeSubscriptionPlanSuccess
} from './current-merchant-subscription-api.actions';

@Injectable({
	providedIn: 'root',
})
export class CurrentMerchantSubscriptionFacade {

	entity$ = this._store$.select(selectCurrentMerchantSubscription);

	entity: CurrentMerchantSubscription | null = null;

	presentEntity$ = this.entity$.pipe(filterPresent);

	subscriptionPlan$ = this._store$.select(selectCurrentSubscriptionPlan);

	chargePeriod$ = this._store$.select(selectCurrentChargePeriod);

	loading$ = this._store$.select(selectCurrentMerchantSubscriptionLoading);

	loadingFirst$ = this.loading$.pipe(take(2));

	pending$ = this._store$.select(selectCurrentMerchantSubscriptionPending);

	error$ = this._store$.select(selectError);

	purchaseSubscriptionPlanSuccess$ = this._actions$.pipe(ofType(purchaseSubscriptionPlanSuccess));

	downgradeSubscriptionPlanSuccess$ = this._actions$.pipe(ofType(downgradeSubscriptionPlanSuccess));

	changeSubscriptionPlanSuccess$ = this._actions$.pipe(
		ofType(
			purchaseSubscriptionPlanSuccess,
			downgradeSubscriptionPlanSuccess,
		),
	);

	constructor(
		private readonly _store$: Store,
		private readonly _actions$: Actions,
	) {
		this._updateEntityPropertyOnStateChange();
	}

	load(): void {
		this._store$.dispatch(load());
	}

	resetState(): void {
		this._store$.dispatch(resetState());
	}

	refresh(): void {
		this._store$.dispatch(refresh());
	}

	purchaseSubscriptionPlan(request: SubscriptionPlanPurchase): void {
		this._store$.dispatch(purchaseSubscriptionPlan(request));
	}

	downgradeSubscription(request: DowngradeSubscriptionPlanApiRequest): void {
		this._store$.dispatch(downgradeSubscriptionPlan(request));
	}

	openBillingPortal(): void {
		this._store$.dispatch(openBillingPortal());
	}

	resetError(): void {
		this._store$.dispatch(resetError());
	}

	private _updateEntityPropertyOnStateChange(): void {
		this.entity$
			.subscribe(entity => (this.entity = entity));
	}

}
