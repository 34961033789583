import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedComponentsMetadataModule } from '@bp/shared/components/metadata';
import { SharedFeaturesCarouselModule } from '@bp/shared/features/carousel';
import { SharedFeaturesModalModule } from '@bp/shared/features/modal';
import { SharedComponentsPaymentCardModule } from '@bp/shared/components/payment-card';
import { SharedFeaturesValidationModule } from '@bp/shared/features/validation';
import { SharedPipesModule } from '@bp/shared/pipes';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedDomainsSubscriptionPlansCoreModule } from '@bp/shared/domains/subscription-plans/core';
import { SharedFeaturesAnalyticsModule } from '@bp/shared/features/analytics';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesCalendlyBookDemoModule } from '@bp/shared/features/calendly-book-demo';
import { SharedDomainsSubscriptionPlansCongratulationModule } from '@bp/shared/domains/subscription-plans/congratulation';
import { SharedDomainsSubscriptionPlansCheckoutModule } from '@bp/shared/domains/subscription-plans/checkout';

import { AdminsSharedCoreComponentsModule } from '@bp/admins-shared/core/components';

import {
	SubscriptionPlanChargePeriodSwitcherComponent,
	CurrentSubscriptionManagementComponent,
	CurrentSubscriptionManagementModalComponent,
	SubscriptionPlansOverviewCardsComponent,
	SubscriptionPlansOverviewComponent,
	SubscriptionDowngradeComponent,
	EnterpriseSubscriptionPaneComponent
} from './components';
import {
	CurrentSubscriptionLimitsMeterComponent,
	CurrentSubscriptionLimitsMeterPopupComponent,
	CurrentSubscriptionIndicatorButtonComponent,
	CurrentMerchantSubscriptionFeatureLimitMeterComponent
} from './components/current-subscription-limits-meter';

const EXPOSED_COMPONENTS = [ CurrentSubscriptionManagementComponent, CurrentSubscriptionLimitsMeterComponent ];

@NgModule({
	imports: [
		CommonModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		RouterModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCheckboxModule,
		MatTabsModule,
		MatIconModule,
		OverlayModule,

		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		SharedComponentsMetadataModule,
		SharedComponentsPaymentCardModule,
		SharedDirectivesModule,
		SharedDomainsSubscriptionPlansCheckoutModule,
		SharedDomainsSubscriptionPlansCoreModule,
		SharedDomainsSubscriptionPlansCongratulationModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesCarouselModule,
		SharedFeaturesModalModule,
		SharedFeaturesValidationModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesAnalyticsModule,
		SharedFeaturesCalendlyBookDemoModule,
		SharedPipesModule,

		AdminsSharedCoreComponentsModule,
	],
	declarations: [
		EXPOSED_COMPONENTS,

		CurrentSubscriptionManagementModalComponent,
		SubscriptionPlansOverviewCardsComponent,
		SubscriptionPlanChargePeriodSwitcherComponent,
		SubscriptionPlansOverviewComponent,
		SubscriptionDowngradeComponent,

		CurrentSubscriptionIndicatorButtonComponent,
		CurrentSubscriptionLimitsMeterPopupComponent,
		CurrentMerchantSubscriptionFeatureLimitMeterComponent,
		EnterpriseSubscriptionPaneComponent,
	],
	exports: [ EXPOSED_COMPONENTS ],
})
export class MerchantAdminDomainsCurrentMerchantSubscriptionModule {}
