import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { SharedPipesModule } from '@bp/shared/pipes';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';

import { CalendlyDirective } from './directives';
import { CalendlyComponent, CalendlyDialogComponent } from './components';

const EXPORTS = [
	CalendlyComponent,
	CalendlyDirective,
];

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		SharedPipesModule,
		MatIconModule,
		MatButtonModule,
		SharedDirectivesModule,
		SharedComponentsCoreModule,
	],
	declarations: [
		EXPORTS,
		CalendlyDialogComponent,
	],
	exports: EXPORTS,

})
export class SharedFeaturesCalendlyModule { }
