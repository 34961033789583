import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedDirectivesModule } from '@bp/shared/directives';

import {
	IDENTITY_GET_ACCESS_DIALOG_GUARDS,
	PermissionBasedNavigationService
} from '@bp/shared-domains-identity';

import { AdminsSharedDomainsIdentityModule } from '@bp/admins-shared/domains/identity';

import { MerchantAdminDomainsCurrentMerchantSubscriptionModule } from '@bp/merchant-admin/domains/current-merchant-subscription';

import {
	PaywallBadgeComponent,
	PaywallButtonComponent,
	ResourceAccessOtpVerificationDialogComponent,
	ResourceAccessOtpVerificationFormComponent,
	ResourcePaywallDialogComponent
} from './components';
import { IdentityGetAccessDialogGuard } from './guards';
import { ButtonIdentityGetAccessDialogGuardDirective, BehindPaywallStructuralDirective } from './directives';
import {
	HttpResponsePaywallGuardInterceptor,
	HttpPostRequestIdentityRefreshInterceptor,
	MerchantAdminPermissionBasedNavigationService
} from './services';

const EXPOSED_COMPONENTS = [
	ButtonIdentityGetAccessDialogGuardDirective,
	PaywallBadgeComponent,
	PaywallButtonComponent,
	BehindPaywallStructuralDirective,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RouterModule,
		MatDialogModule,
		MatProgressBarModule,
		MatButtonModule,

		SharedComponentsCoreModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesTooltipModule,
		SharedDirectivesModule,

		AdminsSharedDomainsIdentityModule,
		MerchantAdminDomainsCurrentMerchantSubscriptionModule,
	],
	exports: [ EXPOSED_COMPONENTS ],
	declarations: [
		EXPOSED_COMPONENTS,
		ResourcePaywallDialogComponent,
		ResourceAccessOtpVerificationDialogComponent,
		ResourceAccessOtpVerificationFormComponent,
	],
	providers: [
		{
			provide: PermissionBasedNavigationService,
			useClass: MerchantAdminPermissionBasedNavigationService,
		},
		{ provide: IDENTITY_GET_ACCESS_DIALOG_GUARDS, useClass: IdentityGetAccessDialogGuard, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpResponsePaywallGuardInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpPostRequestIdentityRefreshInterceptor, multi: true },
	],
})
export class MerchantAdminDomainsIdentityModule {}
