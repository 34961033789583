<bp-input
	type="password"
	name="cvv"
	[label]="paymentCardBrand?.scheme?.code?.displayName ?? label"
	[color]="color"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
	[required]="!!required"
	[hideClearButton]="hideClearButton"
	[hint]="hint"
	[pending]="pending"
	[nativeAutocomplete]="nativeAutocomplete"
	[mask]="mask"
	[formControl]="_internalControl"
>
</bp-input>
