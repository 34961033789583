import type { IPaymentOption } from '@bp/shared/models/business';
import { PaymentOptionType } from '@bp/shared/models/business';
import type { DTO } from '@bp/shared/models/metadata';
import { Unserializable, Default, Entity, FieldViewType, Label, MapFromDTO, View } from '@bp/shared/models/metadata';
import { uuid } from '@bp/shared/utilities';

export const PSP_NAME_NONE = 'none';

export abstract class PaymentOptionBase extends Entity implements IPaymentOption {

	abstract readonly type: PaymentOptionType;

	@Default(false)
	@View(FieldViewType.booleanCircle)
	@Label('Status')
	override isEnabled!: boolean;

	@MapFromDTO()
	// we are having this here cause the back is lazy to come up with a proper structure
	pspName!: string | typeof PSP_NAME_NONE;

	get displayName(): string {
		return this.pspName === PSP_NAME_NONE ? this.type.displayName : `${ this.pspName } ${ this.type.displayName }`;
	}

	get isSortable(): boolean {
		return this.type === PaymentOptionType.creditCard && this.isEnabled;
	}

	@MapFromDTO()
	logoUrl?: string;

	@Unserializable()
	readonly isPspBased: boolean = false;

	constructor(dto?: DTO<PaymentOptionBase>) {
		super(dto);

		this.id = this.id ?? uuid();
	}

	override valueOf(): string {
		return this.pspName;
	}

}
