import { range } from 'lodash-es';

import { Component, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormFieldControlComponent } from '@bp/shared/components/core';
import { TextMaskConfig } from '@bp/shared/features/text-mask';
import { InputComponent } from '@bp/shared/components/controls';

@Component({
	selector: 'bp-payment-card-holder-input',
	templateUrl: './payment-card-holder-input.component.html',
	styleUrls: [ './payment-card-holder-input.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PaymentCardHolderInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PaymentCardHolderInputComponent,
			multi: true,
		},
	],
})
export class PaymentCardHolderInputComponent extends FormFieldControlComponent<string | null> {

	@Input() allowAnyCharInValue?: boolean;

	@Input() maxValueLength = 255;

	@ViewChild(InputComponent, { static: true }) private readonly _input!: InputComponent;

	mask = new TextMaskConfig({
		placeholderChar: TextMaskConfig.whitespace,
		guide: false,
		mask: (rawValue: string) => range(Math.min(rawValue.length, this.maxValueLength))
			.map(() => this.allowAnyCharInValue
				? /./u
				: /[ '.A-Z_a-z-]/u),
	});

	focus(): void {
		this._input.focus();
	}

}
