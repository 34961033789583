import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { BpError } from '@bp/shared/models/core';
import { Currency } from '@bp/shared/models/currencies';
import { GoogleTagService, GtagEvents } from '@bp/shared/features/analytics';
import { FADE_IN, FADE_IN_FIXED_TRIGGERED } from '@bp/shared/animations';
import { attrBoolValue } from '@bp/shared/utilities';
import { SubscriptionPlan, SubscriptionPlanChargePeriod } from '@bp/shared/domains/subscription-plans/core';

import { SubscriptionPlanPurchase } from '../../models';
import { SubscriptionPlanCheckoutFacade } from '../../state';
import { SubscriptionPlanCheckoutPaymentInformationFormVm } from '../../models/subscription-plan-checkout-payment-information-form-vm';

@Component({
	selector: 'bp-subscription-plan-checkout[subscriptionPlan][currency]',
	templateUrl: './subscription-plan-checkout.component.html',
	styleUrls: [ './subscription-plan-checkout.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN, FADE_IN_FIXED_TRIGGERED ],
})
export class SubscriptionPlanCheckoutComponent implements OnInit {

	@Input() subscriptionPlan!: SubscriptionPlan;

	@Input() merchantName?: string | null;

	@Input() chargePeriod!: SubscriptionPlanChargePeriod;

	@Input() currency!: Currency;

	@Input() pending: boolean | null = false;

	@Input() error: BpError | null = null;

	@Input() fixedChargePeriod: boolean | '' = false;

	@Output() readonly purchaseSubscriptionPlan = new EventEmitter<SubscriptionPlanPurchase>();

	protected readonly _assetsDir = '/assets/domains/subscription-plans/checkout';

	constructor(
		public subscriptionPlanCheckoutFacade: SubscriptionPlanCheckoutFacade,
		private readonly _googleTagService: GoogleTagService,
	) {
		this.subscriptionPlanCheckoutFacade.loadConfig();
	}

	ngOnInit(): void {
		this._dispatchGoogleTagEvents();

		this.fixedChargePeriod = attrBoolValue(this.fixedChargePeriod);
	}

	private _getChargePeriodPrice(): number | null {
		return this.subscriptionPlan.prices?.get(this.chargePeriod)?.getPriceFor(this.currency) ?? null;
	}

	private _dispatchGoogleTagEvents(): void {
		const payload: GtagEvents.IEventPayloadMap['begin_checkout'] = {
			value: this._getChargePeriodPrice()!,
			currency: this.currency.code,
		};

		this._googleTagService.dispatchEvent('begin_checkout', payload);

		this._googleTagService.dispatchEvent(`begin_${ this.subscriptionPlan.type.name }_plan_checkout`, payload);
	}

	buildAndSubmitSubscriptionPlanPurchase($event: SubscriptionPlanCheckoutPaymentInformationFormVm): void {
		this.purchaseSubscriptionPlan.emit(new SubscriptionPlanPurchase({
			...$event,
			chargePeriod: this.chargePeriod,
			subscriptionPlan: this.subscriptionPlan,
			currency: this.currency,
		}));
	}

}
