import { Injectable } from '@angular/core';
import type { Params } from '@angular/router';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import type { PaymentOptionType } from '@bp/shared/models/business';

import type { MerchantPsp } from '@bp/admins-shared/core/models';
import { EntitiesInMemoryPagedListFacade } from '@bp/admins-shared/features/entity';

import type { MerchantPspsByPaymentOptionTypeByPspNameMap } from '../models';
import { MerchantPspsQueryParams } from '../models';

import { addMerchantPspsToList, MERCHANT_PSPS_LIST_ACTIONS } from './merchant-psps.actions';
import {
	selectAllEnabledMerchantPsps, selectAllEnabledMerchantPspsByPaymentOptionTypeByPspNameMap,
	selectAllMerchantPspsByPaymentOptionTypeByPspNameMap, MERCHANT_PSPS_IN_MEMORY_PAGED_LIST_SELECTORS
} from './merchant-psps.selectors';

@Injectable({
	providedIn: 'root',
})
export class MerchantPspsFacade extends EntitiesInMemoryPagedListFacade<MerchantPsp, MerchantPspsQueryParams> {

	readonly actions = MERCHANT_PSPS_LIST_ACTIONS;

	readonly selectors = MERCHANT_PSPS_IN_MEMORY_PAGED_LIST_SELECTORS;

	allByPaymentOptionTypeByPspNameMap$ = this._store$.select(selectAllMerchantPspsByPaymentOptionTypeByPspNameMap);

	allByPaymentOptionTypeByPspNameMap: MerchantPspsByPaymentOptionTypeByPspNameMap | null = null;

	allEnabled$ = this._store$.select(selectAllEnabledMerchantPsps);

	allEnabledByPaymentOptionTypeByPspName$ = this._store$
		.select(selectAllEnabledMerchantPspsByPaymentOptionTypeByPspNameMap);

	constructor(
		store$: Store,
		actions$: Actions,
	) {
		super(store$, actions$);

		this._updateAllByPaymentOptionTypeByPspNameMapOnStateChange();
	}

	queryParamsFactory(params: Params): MerchantPspsQueryParams {
		return new MerchantPspsQueryParams(params);
	}

	getByPaymentOptionTypeAndPspName(paymentOptionType: PaymentOptionType, pspName: string): MerchantPsp | null {
		return this.allByPaymentOptionTypeByPspNameMap
			?.get(paymentOptionType)
			?.get(pspName)
			?? null;
	}

	addPspsToList(psps: MerchantPsp[]): void {
		this._store$.dispatch(addMerchantPspsToList({ psps }));
	}

	private _updateAllByPaymentOptionTypeByPspNameMapOnStateChange(): void {
		this.allByPaymentOptionTypeByPspNameMap$
			.subscribe(v => (this.allByPaymentOptionTypeByPspNameMap = v));
	}

}
