import { PspPaymentOptionType } from '@bp/shared/models/business';
import { Currency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import { Default, FieldViewType, Hint, Label, View, Control, FieldControlType, Mapper, Required } from '@bp/shared/models/metadata';

import { BridgerPspPaymentOptionApmSummary } from '../summaries';

import { BridgerPspPaymentOptionBase } from './bridger-psp-payment-option-base';

export class BridgerPspPaymentOptionApm extends BridgerPspPaymentOptionBase implements BridgerPspPaymentOptionApmSummary {

	static override readonly type = PspPaymentOptionType.apm;

	readonly type = BridgerPspPaymentOptionApm.type;

	@Control(FieldControlType.chip, {
		list: Currency.list,
		placeholder: 'Add currency...',
	})
	@Mapper(Currency)
	@Required()
	currencies!: Currency[];

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Label('Process As Credit Card')
	@Hint('The cashier will show the credit card screen for this psp')
	processAsCreditCard!: boolean;

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Label('Has Currency Conversion')
	@Hint('Can convert a currency to the target currency')
	currencyConversion!: boolean;

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Label('Supports BNPL')
	supportsBnpl!: boolean;

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	canVoid!: boolean;

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	canCapture!: boolean;

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Hint('PSP must be able to void, capture and work in iframe')
	canBeUsedInPaywith!: boolean;

	constructor(dto: DTO<BridgerPspPaymentOptionApm>) {
		super(dto);
	}

}
