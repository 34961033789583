import { Enumeration } from '@bp/shared/models/core/enum';

export class MerchantType extends Enumeration {
	static forex = new MerchantType();

	static exchange = new MerchantType();

	static ecommerce = new MerchantType();

	static gaming = new MerchantType();

	static travel = new MerchantType();
}
