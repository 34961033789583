import { ChangeDetectionStrategy, Component, HostBinding, HostListener } from '@angular/core';

import { FADE_IN } from '@bp/shared/animations';

import { EditableCardBaseComponent } from '../editable-card-base-component';

@Component({
	selector: 'bp-editable-view-card',
	templateUrl: './editable-view-card.component.html',
	styleUrls: [ './editable-view-card.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class EditableViewCardComponent extends EditableCardBaseComponent {

	@HostBinding('class.card-view')
	get isCardView(): boolean {
		return this.view;
	}

	@HostListener('click', [ '$event.target' ])
	onClick($target: Node): void {
		if (this.edit && this._$host.contains($target))
			return;

		this.edit = this.canEdit ? !this.edit : false;
	}

	@HostListener('mouseenter')
	onMouseEnter(): void {
		this.showShadow = true;
	}

	@HostListener('mouseleave')
	onMouseLeave(): void {
		this.showShadow = false;
	}

}
