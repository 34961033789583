import { createAction, props } from '@ngrx/store';

import type { ErrorActionPayload, ResultActionPayload } from '@bp/shared/models/common';
import type { News } from '@bp/shared/domains/newsroom';

export const ACTIONS_SCOPE = '[Feature][AnnouncementsHub][Announcement]';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadSuccess = createAction(
	`${ scope } Load Success`,
	props<ResultActionPayload<News>>(),
);

export const loadFailure = createAction(
	`${ scope } Load Failure`,
	props<ErrorActionPayload>(),
);
