import { Directive, ElementRef, Optional } from '@angular/core';

import { HasAccessStructuralDirective } from '@bp/shared-domains-identity';

import { IdentityGetAccessDialogGuard } from '../guards';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'a, button',
})
export class ButtonIdentityGetAccessDialogGuardDirective {

	private readonly _$host = this._host.nativeElement;

	constructor(
		private readonly _identityGetAccessDialogGuard: IdentityGetAccessDialogGuard,
		private readonly _host: ElementRef<HTMLAnchorElement | HTMLButtonElement>,
		@Optional() private readonly _hasAccessGuard?: HasAccessStructuralDirective,
	) {

		if (this._hasAccessGuard)
			this._$host.addEventListener('click', event => void this.guardClickWithIdentityGetAccessDialogs(event));

	}

	async guardClickWithIdentityGetAccessDialogs(event: Event): Promise<void> {
		if (!this._identityGetAccessDialogGuard.shouldShowGetAccessDialog(this._hasAccessGuard!.trackedPermission!))
			return;

		event.stopPropagation();

		event.preventDefault();

		event.stopImmediatePropagation();

		const identityGotAccess = await this._identityGetAccessDialogGuard.getAccess(
			this._hasAccessGuard!.trackedPermission!,
		);

		if (identityGotAccess)
			this._$host.click();
	}
}
