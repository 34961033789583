import { createAction, props } from '@ngrx/store';

import type { MerchantPsp } from '@bp/admins-shared/core/models';
import type { EntitiesListActions } from '@bp/admins-shared/features/entity';
import { composeEntitiesListActions } from '@bp/admins-shared/features/entity';

import type { MerchantPspsQueryParams } from '../models';

import { ACTIONS_SCOPE, MERCHANT_PSPS_LIST_API_ACTIONS } from './merchant-psps-api.actions';

export const MERCHANT_PSPS_LIST_ACTIONS: EntitiesListActions<MerchantPsp, MerchantPspsQueryParams> = {

	...composeEntitiesListActions(ACTIONS_SCOPE),

	api: MERCHANT_PSPS_LIST_API_ACTIONS,
};

export const addMerchantPspsToList = createAction(
	`${ ACTIONS_SCOPE } Add PSPs To The List`,
	props<{ psps: MerchantPsp[] }>(),
);
