import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { FeatureVersion, MerchantAdminFeature } from '@bp/shared-domains-identity';

import { CurrentMerchantFacade } from '../state';

@Injectable({
	providedIn: 'root',
})
export class FeatureVersionsService {
	isDashboardV2$ = this._getFeatureVersion$(MerchantAdminFeature.dashboard, FeatureVersion.v2);

	isReportsV2$ = this._getFeatureVersion$(MerchantAdminFeature.reports, FeatureVersion.v2);

	isPaymentRoutesV2$ = this._getFeatureVersion$(MerchantAdminFeature.paymentRoutes, FeatureVersion.v2);

	isCashiersV2$ = this._getFeatureVersion$(MerchantAdminFeature.cashiers, FeatureVersion.v2);

	constructor(private readonly _currentMerchantFacade: CurrentMerchantFacade) {}

	private _getFeatureVersion$(feature: MerchantAdminFeature, version: FeatureVersion): Observable<boolean> {
		return this._currentMerchantFacade.presentEntity$.pipe(
			map(
				merchant => (version === FeatureVersion.v2 && !!merchant.currentSubscription)
					|| merchant.featuresVersions.get(feature) === version,
			),
		);
	}
}
