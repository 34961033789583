import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IFrequentlyAskedQuestion } from '../../models';

@Component({
	selector: 'bp-faq',
	templateUrl: './faq.component.html',
	styleUrls: [ './faq.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent {

	@Input() faqs!: IFrequentlyAskedQuestion[];

}
