import { DescribableEnumeration } from '@bp/shared/models/core';

export class PaymentRouteRuleType extends DescribableEnumeration {

	static block = new PaymentRouteRuleType({
		description: 'If the condition is met and the rule targets a PSP, exclude the PSP from payments processing; otherwise payment is aborted completely.',
		affectsPspCaption: 'exclude from payments processing this psp:',
		affectsAnyPspCaption: 'abort payment',
	});

	static card = new PaymentRouteRuleType({
		displayName: 'Payment Card',
		description: 'If the condition is met, payments will be processed by the above PSP',
		affectsPspCaption: 'to process payments, use only this psp:',
	});

	static volume = new PaymentRouteRuleType({
		displayName: 'PSP\'s Processed Volume ',
		description: 'If the volume reaches it\'s limit, the above PSP will be excluded from payments processing',
		affectsPspCaption: 'exclude from payments processing this psp:',
	});

	static transaction = new PaymentRouteRuleType({
		displayName: 'Payment Amount',
		description: 'If the condition is met, payments will be processed by the above PSP',
		affectsPspCaption: 'to process payments, use only this psp:',
	});

	affectsPspCaption: string;

	affectsAnyPspCaption?: string;

	constructor(config: {
		displayName?: string;
		description: string;
		affectsPspCaption: string;
		affectsAnyPspCaption?: string;
	}) {
		super(config.displayName, config.description);

		this.affectsPspCaption = config.affectsPspCaption;

		this.affectsAnyPspCaption = config.affectsAnyPspCaption;
	}

}
