import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { filterPresent } from '@bp/shared/rxjs';

import { loadConfig } from './subscription-plan-checkout.actions';
import { selectCountry, selectError, selectPending, selectVatConfigPerCountry } from './subscription-plan-checkout.selectors';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionPlanCheckoutFacade {

	pending$ = this._store$.select(selectPending);

	error$ = this._store$.select(selectError);

	vatConfigPerCountry$ = this._store$.select(selectVatConfigPerCountry);

	presentVatConfig$ = this._store$.select(selectVatConfigPerCountry).pipe(filterPresent);

	country$ = this._store$.select(selectCountry);

	presentCountry$ = this.country$.pipe(filterPresent);

	constructor(protected readonly _store$: Store) {
	}

	loadConfig(): void {
		this._store$.dispatch(loadConfig());
	}

}
