
import { ToastrService } from 'ngx-toastr';

import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import type { ControlsSectionScheme } from '@bp/shared/components/metadata';
import { FormMetadataEntityBaseComponent } from '@bp/shared/components/metadata';
import type { DTO } from '@bp/shared/models/metadata';

import { IdentityPreferences } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-identity-preferences-form',
	templateUrl: './identity-preferences-form.component.html',
	styleUrls: [ './identity-preferences-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentityPreferencesFormComponent extends FormMetadataEntityBaseComponent<IdentityPreferences> {

	override metadata = IdentityPreferences.getClassMetadata();

	sectionScheme: ControlsSectionScheme<IdentityPreferences> = [
		[ 'timezone' ],
	];

	constructor(
		formBuilder: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(formBuilder, cdr, toaster);

		this.setFormScheme({
			timezone: null,
		});
	}

	override factory = (dto?: DTO<IdentityPreferences>): IdentityPreferences => new IdentityPreferences(dto);

}
