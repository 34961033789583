import { ClipboardModule } from 'ngx-clipboard';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedPipesModule } from '@bp/shared/pipes';
import { SharedFeaturesModalModule } from '@bp/shared/features/modal';

import {
	AnnouncementsHubItemComponent, AnnouncementModalComponent, AnnouncementsHubBodyComponent,
	AnnouncementsHubOpenButtonComponent, AnnouncementsHubHeaderComponent, AnnouncementsHubComponent
} from './components';

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		MatButtonModule,
		MatIconModule,
		MatListModule,
		MatProgressBarModule,
		MatSidenavModule,
		MatToolbarModule,
		ClipboardModule,

		SharedDirectivesModule,
		SharedComponentsCoreModule,
		SharedFeaturesModalModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesSvgIconsModule,
		SharedPipesModule,

		RouterModule.forChild([]),
	],
	exports: [
		AnnouncementsHubOpenButtonComponent,
		AnnouncementsHubComponent,
	],
	declarations: [
		AnnouncementsHubOpenButtonComponent,
		AnnouncementsHubBodyComponent,
		AnnouncementsHubComponent,
		AnnouncementsHubHeaderComponent,
		AnnouncementsHubItemComponent,
		AnnouncementModalComponent,
	],
})
export class MerchantAdminFeaturesAnnouncementsModule {
}
