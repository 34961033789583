import { uniqBy } from 'lodash-es';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OnChanges, SimpleChanges } from '@bp/shared/models/core';
import { isInstanceOf } from '@bp/shared/utilities';

import { SubscriptionPlan, SubscriptionPlanFeature, SubscriptionPlanLimitedFeature } from '../../models';

type FeatureRowItem = {
	featureName: string;
	featureDescription: string;
	limitsPerSubscriptionPlan?: (number | string)[];
	presenceOrCountPerSubscriptionPlan?: (boolean | number)[];
};

@Component({
	selector: 'bp-feature-comparison-table',
	templateUrl: './feature-comparison-table.component.html',
	styleUrls: [ './feature-comparison-table.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureComparisonTableComponent implements OnChanges {

	@Input() subscriptionPlans!: SubscriptionPlan[];

	featureRowItems: FeatureRowItem[] = [];

	ngOnChanges({ subscriptionPlans }: SimpleChanges<this>): void {
		if (subscriptionPlans) {
			this.featureRowItems = [
				...this._buildLimitedFeaturesRowItems(),
				...this._buildRegularFeaturesRowItems(),
			];
		}
	}

	private _buildLimitedFeaturesRowItems(): FeatureRowItem[] {
		const limitedFeatures = uniqBy(
			this.subscriptionPlans
				.flatMap(
					subscription => subscription.features.filter(isInstanceOf(SubscriptionPlanLimitedFeature)),
				),
			feature => feature.name,
		);

		return limitedFeatures
			.map(limitedFeature => ({
				featureName: limitedFeature.name,
				featureDescription: limitedFeature.description,
				limitsPerSubscriptionPlan: this.subscriptionPlans.flatMap(
					subscription => subscription.features
						.filter(isInstanceOf(SubscriptionPlanLimitedFeature))
						.find(feature => feature.name === limitedFeature.name)?.limit ?? 'Unlimited',
				),
			}));
	}

	private _buildRegularFeaturesRowItems(): FeatureRowItem[] {
		const featureRowItems = this.subscriptionPlans
			.map(subscriptionPlan => subscriptionPlan.features)
			.flatMap((features: SubscriptionPlanFeature[], baseSubscriptionPlanIndex) => features
				.filter(feature => !(feature instanceof SubscriptionPlanLimitedFeature))
				.map(({ name, description, count }): FeatureRowItem => ({
					featureName: name,
					featureDescription: description,
					presenceOrCountPerSubscriptionPlan: this.subscriptionPlans.map(
						(subscriptionPlan, checkSubscriptionPlanIndex) => {
							const subscriptionPlanHasFeature = checkSubscriptionPlanIndex >= baseSubscriptionPlanIndex;

							return subscriptionPlanHasFeature
								? (count && subscriptionPlan.features.find(feature => feature.name === name)?.count) ?? true
								: false;
						},
					),
				})));

		return uniqBy(
			featureRowItems,
			featureRowItem => featureRowItem.featureName,
		);
	}

}
