import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
	MerchantAdminFeature,
	FeaturePermissionsService,
	PermissionBasedNavigationService
} from '@bp/shared-domains-identity';

@Injectable({
	providedIn: 'root',
})
export class MerchantAdminPermissionBasedNavigationService extends PermissionBasedNavigationService {

	constructor(
		private readonly _router: Router,
		private readonly _featurePermissionsService: FeaturePermissionsService,
	) {
		super();
	}

	tryNavigate(): boolean {
		switch (true) {
			case this._featurePermissionsService.hasAccess(MerchantAdminFeature.enterpriseSubscriptionCheckout):
				void this._router.navigateByUrl('/enterprise-subscription-plan-checkout');
				break;

			case this._featurePermissionsService.hasAccess(MerchantAdminFeature.dashboard):
				void this._router.navigateByUrl('/dashboard');
				break;

			case this._featurePermissionsService.hasAccess(MerchantAdminFeature.transactions):
				void this._router.navigateByUrl('/transactions');
				break;

			default:
				return false;
		}

		return true;
	}
}
