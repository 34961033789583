import { without } from 'lodash-es';

import { PaymentCardBrand } from '@bp/shared/models/business';
import type { INamedEntitySummary } from '@bp/shared/models/core';
import { Countries, Country, countryMapper } from '@bp/shared/models/countries';
import type { ICurrency } from '@bp/shared/models/currencies';
import { ANY_CURRENCY, currencyFactory } from '@bp/shared/models/currencies';
import {
	Control, Default, Entity, FieldControlType, FieldViewType, Hint, Label, MapFromDTO, Mapper, Required,
	Unserializable, View, ViewEmptyValue, DTO, Secret
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import { Dictionary } from '@bp/shared/typings';
import { isEmpty } from '@bp/shared/utilities';

import { PayoutMethodType } from '../enums';

export type PspCredentialKeys = NonFunctionPropertyNames<PspCredential>;

export class PspCredential extends Entity {

	@MapFromDTO()
	override id!: string;

	@MapFromDTO()
	pspId!: string;

	@Control(FieldControlType.input)
	override name!: string;

	@Control(FieldControlType.switch)
	@Label('Status')
	@View(FieldViewType.booleanCircle)
	@Default(true)
	override isEnabled!: boolean;

	@Control(FieldControlType.switch)
	@Label('Used For Payouts')
	@Hint('This credential details will be used also for paying out.')
	@View(FieldViewType.boolean)
	allowPayouts!: boolean;

	@Mapper(PayoutMethodType)
	@Control(FieldControlType.select, {
		list: without(PayoutMethodType.getList(), PayoutMethodType.none),
		required: true,
	})
	@Default(null)
	payoutMethodType!: PayoutMethodType | null;

	@Control(FieldControlType.country, {
		typeControlOptions: {
			hasWorldwide: true,
		},
	})
	@Mapper(countryMapper)
	@View(FieldViewType.country)
	@Required()
	@Default(Countries.worldwide)
	country!: Country;

	@Mapper(currencyFactory)
	@Default(null)
	currency!: ICurrency | null;

	@Control(FieldControlType.autocomplete)
	@Label('Currency')
	@Required()
	get anyMappedCurrency(): ICurrency | typeof ANY_CURRENCY {
		return this.currency ?? ANY_CURRENCY;
	}

	set anyMappedCurrency(value: ICurrency | typeof ANY_CURRENCY) {
		this.currency = value === ANY_CURRENCY ? null : value;
	}

	@Control(FieldControlType.brandChips)
	@Mapper(PaymentCardBrand)
	@ViewEmptyValue('Any')
	@Default(null)
	brands!: PaymentCardBrand[] | null;

	@Default({})
	@Secret()
	metadata!: Dictionary<Object[] | boolean | string>;

	@Default([])
	@ViewEmptyValue('Not attached to any route')
	@Label('Payment Routes Participation')
	paymentRoutes!: INamedEntitySummary[];

	@Required()
	apiUrl!: string;

	@Unserializable()
	readonly isWorldwide: boolean;

	@Unserializable()
	readonly isUsedInPaymentRoutes: boolean;

	constructor(dto?: DTO<PspCredential>) {
		super(dto);

		this.isWorldwide = this.country === Countries.worldwide;

		this.isUsedInPaymentRoutes = this.paymentRoutes.length > 0;

		this.brands = isEmpty(this.brands) ? null : this.brands;
	}

}
