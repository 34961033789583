<mat-sidenav
	mode="over"
	position="end"
	[opened]="announcementsFacade.isOpened$ | async"
	[fixedInViewport]="true"
	(keydown.escape)="announcementsFacade.close()"
>
	<bp-announcements-hub-header></bp-announcements-hub-header>

	<bp-announcements-hub-body></bp-announcements-hub-body>
</mat-sidenav>
