/* eslint-disable @typescript-eslint/promise-function-async */
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { TelemetryService } from '@bp/shared/services';

import {
	MerchantAdminFeature, identityHasAccessGuardConfig, IdentityHasAccessGuard, IdentityLoggedInGuard, NON_REDIRECTED_URLS_AFTER_LOGIN_TOKEN
} from '@bp/shared-domains-identity';

import { rightDrawerRouteDataOptions } from '@bp/admins-shared/features/layout';

import { AnnouncementModalComponent } from '@bp/merchant-admin/features/announcements';
import { CurrentSubscriptionManagementModalComponent } from '@bp/merchant-admin/domains/current-merchant-subscription';

import { SubscriptionSuspendedPageComponent, NavigationToRootRedirectionGuard, HomePageComponent } from './core';

export const SUBSCRIPTION_SUSPENDED_ROUTE_PATH = 'subscription-suspended';

// App routing module is for lazy routes
const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: HomePageComponent,
		canActivate: [ IdentityLoggedInGuard, NavigationToRootRedirectionGuard ],
	},
	{
		path: 'subscription-management',
		canActivate: [ IdentityLoggedInGuard ],
		component: CurrentSubscriptionManagementModalComponent,
		data: { title: 'Manage Your Plan' },
		outlet: 'modal',
	},
	{
		path: 'announcement/:id',
		canActivate: [ IdentityLoggedInGuard ],
		component: AnnouncementModalComponent,
		outlet: 'modal',
	},
	{
		path: SUBSCRIPTION_SUSPENDED_ROUTE_PATH,
		component: SubscriptionSuspendedPageComponent,
		data: { title: 'License Expired' },
	},
	{
		path: 'enterprise-subscription-plan-checkout',
		data: {
			...identityHasAccessGuardConfig({
				permission: MerchantAdminFeature.enterpriseSubscriptionCheckout,
				permissionBasedRedirectionOnNoAccess: true,
				dontRequireRunGuardsAlways: true,
			}),
		},
		canActivate: [ IdentityHasAccessGuard ],
		loadChildren: () => import('@bp/merchant-admin/sections/enterprise-subscription-plan-checkout')
			.then(m => m.EnterpriseSubscriptionPlanCheckoutModule),
	},
	{
		path: 'dashboard',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.dashboard),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/sections/dashboard')
			.then(m => m.DashboardSectionModule),
	},
	{
		path: 'transactions',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.transactions),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/sections/transactions')
			.then(m => m.TransactionsSectionModule),
	},
	{
		path: 'payouts',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.payouts),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/sections/payouts')
			.then(m => m.PayoutsSectionModule),
	},
	{
		path: 'payment-routes',
		data: {
			preload: false,
			...identityHasAccessGuardConfig(MerchantAdminFeature.paymentRoutes),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/sections/payment-routes')
			.then(m => m.PaymentRoutesSectionModule),
	},
	{
		path: 'checkouts',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.cashiers),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/sections/checkouts')
			.then(m => m.CheckoutsSectionModule),
	},
	{
		path: 'reports',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.reports),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/sections/reports')
			.then(m => m.ReportsSectionModule),
	},
	{
		path: 'virtual-terminal',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.virtualTerminal),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		outlet: '[right-drawer]',
		loadChildren: () => import('@bp/merchant-admin/sections/virtual-terminal')
			.then(m => m.VirtualTerminalModule),
	},
	{
		path: 'merchant-psps',
		data: {
			preload: false,
			...identityHasAccessGuardConfig(MerchantAdminFeature.psps),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/sections/merchant-psps')
			.then(m => m.MerchantPspsSectionModule),
	},
	{
		path: 'merchant-psps-drawer-outlet',
		data: {
			preload: false,
			...rightDrawerRouteDataOptions({
				drawersGroups: [
					{
						groupName: 'payment-route/psp-payment-option',
					},
				],
			}),
		},
		outlet: '[right-drawer][3]',
		canActivate: [ IdentityLoggedInGuard ],
		loadChildren: () => import('@bp/merchant-admin/sections/merchant-psps')
			.then(m => m.MerchantPspsSectionModule),
	},
	{
		path: 'credit-card-tokens',
		data: {
			preload: false,
			...identityHasAccessGuardConfig(MerchantAdminFeature.creditCardTokens),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/sections/credit-card-tokens')
			.then(m => m.CreditCardTokensSectionModule),
	},
	{
		path: 'members',
		data: {
			preload: false,
			...identityHasAccessGuardConfig(MerchantAdminFeature.members),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/sections/members')
			.then(m => m.MembersSectionModule),
	},
];

@NgModule({
	imports: [
		QuicklinkModule,
		RouterModule.forRoot(routes, {
			enableTracing: false,
			initialNavigation: 'enabledNonBlocking',
			paramsInheritanceStrategy: 'always',
			onSameUrlNavigation: 'reload',
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			scrollOffset: [ 0, 150 ],
			canceledNavigationResolution: 'replace',
			preloadingStrategy: QuicklinkStrategy,
			errorHandler: TelemetryService.routerErrorHandler,
		}),
	],
	exports: [ RouterModule, QuicklinkModule ],
	providers: [
		{
			provide: NON_REDIRECTED_URLS_AFTER_LOGIN_TOKEN,
			multi: true,
			useValue: [ `/${ SUBSCRIPTION_SUSPENDED_ROUTE_PATH }` ],
		},
	],
})
export class AppRoutingModule {}
