import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiMockPluginsManagerService } from '@bp/shared/services';

import { CurrentMerchantEffects } from './state/current-merchant.effects';
import { FEATURE_KEY, reducer } from './state/current-merchant.reducer';
import { HttpPostRequestMerchantRefreshInterceptor } from './services';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([ CurrentMerchantEffects ]),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: HttpPostRequestMerchantRefreshInterceptor, multi: true },
	],
})
export class MerchantAdminDomainsCurrentMerchantRootModule {

	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(async () => import('./api-mock-plugin/current-merchant-api-mock-plugin'));
	}

}
