
import { createReducer, on } from '@ngrx/store';

import { BpError } from '@bp/shared/models/core';

import { CurrentMerchantSubscription } from '../models';

import {
	purchaseSubscriptionPlanFailure, purchaseSubscriptionPlanSuccess, downgradeSubscriptionPlanFailure, downgradeSubscriptionPlanSuccess, loadFailure, loadSuccess, loadBillingPortalDetailsFailure, loadBillingPortalDetailsSuccess
} from './current-merchant-subscription-api.actions';
import {
	purchaseSubscriptionPlan, downgradeSubscriptionPlan, load, resetState, loadBillingPortalDetails, resetError
} from './current-merchant-subscription.actions';

export const FEATURE_KEY = '[domain]:current-merchant-subscription';

export interface IState {

	entity: CurrentMerchantSubscription | null;

	loading: boolean;

	pending: boolean;

	error: BpError | null;

	loadingBillingPortalDetails: boolean;

}

export const initialState: IState = {
	entity: null,
	loading: false,
	pending: false,
	error: null,
	loadingBillingPortalDetails: false,
};

export const reducer = createReducer(

	initialState,

	on(resetState, (): IState => initialState),

	on(resetError, (state): IState => ({
		...state,
		error: null,
	})),

	on(
		load,
		(state): IState => ({
			...state,
			loading: true,
			error: null,
		}),
	),

	on(loadSuccess, (state, { result }): IState => ({
		...state,
		entity: result,
	})),

	on(
		loadSuccess,
		loadFailure,
		(state): IState => ({
			...state,
			loading: false,
		}),
	),

	on(
		loadFailure,
		downgradeSubscriptionPlanFailure,
		(state, { error }): IState => ({
			...state,
			error,
			loading: false,
			pending: false,
		}),
	),

	on(
		purchaseSubscriptionPlanFailure,
		(state, { error }): IState => ({
			...state,
			error,
			pending: false,
		}),
	),

	on(
		purchaseSubscriptionPlan,
		downgradeSubscriptionPlan,
		(state): IState => ({
			...state,
			pending: true,
			error: null,
		}),
	),

	on(
		purchaseSubscriptionPlanSuccess,
		downgradeSubscriptionPlanSuccess,
		(state): IState => ({
			...state,
			pending: false,
		}),
	),

	on(
		loadBillingPortalDetails,
		loadBillingPortalDetailsSuccess,
		loadBillingPortalDetailsFailure,
		(state, action): IState => ({
			...state,
			loadingBillingPortalDetails: action.type === loadBillingPortalDetails.type,
		}),
	),

);
