import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedPipesModule } from '@bp/shared/pipes';
import { ApiMockPluginsManagerService } from '@bp/shared/services';

const EXPOSED_COMPONENTS: any[] | Type<any> = [

];

@NgModule({
	imports: [
		CommonModule,

		SharedDirectivesModule,
		SharedPipesModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
	],
	exports: [ EXPOSED_COMPONENTS ],
	declarations: EXPOSED_COMPONENTS,
})
export class AdminsSharedSubscriptionPlansModule {

	static isMockRegistered = false;

	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		if (!AdminsSharedSubscriptionPlansModule.isMockRegistered) {
			void this._apiMocksManagerService.register(
				async () => import('./api-mock-plugin/subscription-plans-api-mock-plugin'),
			);

			AdminsSharedSubscriptionPlansModule.isMockRegistered = true;
		}
	}

}
