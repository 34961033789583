import { ICalendlyOptions } from '@bp/shared/features/calendly';

import { BookDemoCalendarKind } from './book-demo-calendar-kind.enum';

export const BOOK_DEMO_CALENDAR_OPTIONS_STAGING: ICalendlyOptions = {
	accountId: 'bridgerpaydemo',
	eventId: 'bridgerpay-merchant-admin-demo',
};

export const DEFAULT_BOOK_DEMO_CALENDAR_OPTIONS_PER_KIND: Map<BookDemoCalendarKind, ICalendlyOptions> = new Map([
	[
		BookDemoCalendarKind.bdm,
		{
			accountId: 'd/dj7-q72-4g2',
			eventId: 'bridgerpay-demo',
		},
	],
	[
		BookDemoCalendarKind.bdr,
		{
			accountId: 'd/cmr-2sr-7j8',
			eventId: 'bridgerpay-demo',
		},
	],
]);
