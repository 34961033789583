<mat-progress-bar *ngIf="simulatedUserPreferenceUpdating$ | async" @slideUp color="primary" mode="indeterminate">
</mat-progress-bar>

<div class="right-drawer-header">
	<div class="right-drawer-subheader">
		<h1>Preferences</h1>
	</div>

	<!-- <nav mat-tab-nav-bar bpRightDrawerNavBar>
		<a
			#portalTabLink="routerLinkActive"
			mat-tab-link
			routerLinkActive
			routerLink="./{{ IdentityPreferencesPageTab.portal }}"
			[active]="portalTabLink.isActive"
		>
			{{ IdentityPreferencesPageTab.portal.displayName }}
		</a>
	</nav> -->
</div>

<content class="right-drawer-body gray">
	<router-outlet> </router-outlet>
</content>
