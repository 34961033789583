import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, ChangeDetectionStrategy } from '@angular/core';

import { FADE, FADE_IN } from '@bp/shared/animations';

import { CurrentMerchantSubscriptionFacade } from '../../state';

@Component({
	selector: 'bp-current-subscription-limits-meter',
	templateUrl: './current-subscription-limits-meter.component.html',
	styleUrls: [ './current-subscription-limits-meter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN, FADE ],
})
export class CurrentSubscriptionLimitsMeterComponent {

	isLimitsMeterPopupOpen = false;

	popupPosition: ConnectedPosition[] = [{
		originX: 'center',
		originY: 'bottom',
		overlayX: 'center',
		overlayY: 'top',
	}];

	constructor(

		public currentMerchantSubscriptionFacade: CurrentMerchantSubscriptionFacade,
	) {

	}
}
