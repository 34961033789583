import { Observable, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpResponse } from '@angular/common/http';

import { IDENTITY_API_AUTH_NAMESPACE } from '@bp/admins-shared/domains/identity';

import { CurrentMerchantFacade } from '../state';

@Injectable()
export class HttpPostRequestMerchantRefreshInterceptor implements HttpInterceptor {

	constructor(private readonly _currentMerchantFacade: CurrentMerchantFacade) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const shouldRefreshMerchantAfterSuccessRequest = !request.url.includes(IDENTITY_API_AUTH_NAMESPACE)
			&& [ 'DELETE', 'POST' ].includes(request.method);

		return next.handle(request)
			.pipe(tap(response => {
				if (shouldRefreshMerchantAfterSuccessRequest && response instanceof HttpResponse)
					this._currentMerchantFacade.refresh();
			}));
	}

}
