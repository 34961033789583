<bp-lottie-player
	eager
	looped
	class="animated-logo"
	[animationName]="congratulationVm.logoLottieAnimation.kebabName"
></bp-lottie-player>

<h2 class="title text-center uppercase">{{ congratulationVm.title }}</h2>

<div class="features-wrapper mb-4">
	<div class="features">
		<div class="title mb-3" [innerHTML]="congratulationVm.subtitle"></div>

		<ul class="features">
			<li *ngFor="let feature of congratulationVm.features | takePresent" class="feature mb-3">
				<bp-feature-presence-or-count [value]="true"></bp-feature-presence-or-count>

				<div class="description" [innerHTML]="feature"></div>
			</li>
		</ul>
	</div>

	<bp-img url="{{ assetsDir }}router.png" class="router"></bp-img>
</div>

<div *ngIf="isWizardContext" class="continue-on-laptop-call">
	<bp-img url="{{ assetsDir }}continue-on-laptop.svg"></bp-img>
	<p class="mt-3 mb-3">Continue from your desktop for a better experience</p>
</div>

<div [innerHTML]="congratulationVm.additionalInfo"></div>
