import { PaymentOptionType } from '@bp/shared/models/business';
import type { ClassMetadata, DTO } from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities';

import type { PaymentOptions, PspPaymentOptionsIntersection } from './payment-options';
import {
	ApmPspPaymentOption, CreditCardPspPaymentOption, CryptoPspPaymentOption, VoucherPspPaymentOption
} from './psp-based';
import { CryptoWalletsPaymentOption, ExternalLinkPaymentOption, WireTransferPaymentOption } from './static';

const paymentOptionsConstructors = [
	ApmPspPaymentOption,
	CreditCardPspPaymentOption,
	CryptoPspPaymentOption,
	VoucherPspPaymentOption,

	WireTransferPaymentOption,
	CryptoWalletsPaymentOption,
	ExternalLinkPaymentOption,
];

export function paymentOptionsFactory(dtos: DTO<PaymentOptions>[]): PaymentOptions[] {
	return dtos
		.filter(dto => isPresent(dto) && dto.type)
		.map(dto => paymentOptionFactory(<string> dto.type!, dto))
		.filter(isPresent);
}

export function paymentOptionFactory<T extends PaymentOptions = PaymentOptions>(
	type: PaymentOptionType | string,
	dto?: DTO<PspPaymentOptionsIntersection>,
): T {
	const paymentOptionType = PaymentOptionType.parseStrict(type);

	const targetPaymentOptionConstructor = paymentOptionsConstructors.find(
		paymentOption => paymentOption.type === paymentOptionType,
	);

	if (targetPaymentOptionConstructor)
		return (<T> new targetPaymentOptionConstructor(<any> dto));

	throw new Error(`No appropriate constructor for ${ paymentOptionType }`);
}

export function getPaymentOptionClassMetadata(
	type: PaymentOptionType,
): ClassMetadata<PaymentOptions> {
	const targetPaymentOptionConstructor = paymentOptionsConstructors.find(
		paymentOption => paymentOption.type === type,
	);

	if (targetPaymentOptionConstructor)
		return targetPaymentOptionConstructor.getClassMetadata();

	throw new Error(`No appropriate class metadata for ${ type }`);
}
