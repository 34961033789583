<ng-container *ngIf="pspsByPaymentOptionTypeMap">
	<div @fadeIn class="filters mb-3 flex-justify-content-between flex-align-items-baseline">
		<bp-bridger-psps-filter
			[availableBrands]="isCreditCardPaymentOptionTypeSelected ? availableCreditCardBrands : null"
			[availableCurrencies]="availableCurrenciesPerPaymentOptionType.get(selectedPaymentOptionType)"
			(changed)="pspsFilter = $event"
		></bp-bridger-psps-filter>

		<bp-payment-option-type-toggle-group
			*ngIf="paymentOptionTypes.length > 1"
			[optionTypes]="paymentOptionTypes"
			[selectedPspsByPaymentOptionTypeMap]="selectedPspsByPaymentOptionTypeMap"
			[(selectedOptionType)]="selectedPaymentOptionType"
		>
		</bp-payment-option-type-toggle-group>
	</div>

	<bp-alert *ngIf="error" class="right-drawer-alert" type="danger" withBorder [errors]="error.messages"> </bp-alert>

	<mat-tab-group
		class="headless-mat-tab-group"
		[selectedIndex]="paymentOptionTypes.indexOf(selectedPaymentOptionType)"
		[disablePagination]="true"
	>
		<mat-tab *ngFor="let paymentOptionType of paymentOptionTypes">
			<ng-template matTabContent>
				<bp-bridger-psps-multipicker
					[providers]="pspsByPaymentOptionTypeMap!.get(paymentOptionType) | shallowFilter: pspsFilter"
					[paymentOptionType]="paymentOptionType"
					[initiallySelected]="selectedPspsByPaymentOptionTypeMap.get(paymentOptionType)"
					[selectable]="pspsLimit ? selectedPspsCount < pspsLimit : true"
					(selected)="storeSelectedPaymentOptionPsps(paymentOptionType, $event)"
				></bp-bridger-psps-multipicker>
			</ng-template>
		</mat-tab>
	</mat-tab-group>
</ng-container>
