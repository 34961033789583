import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IntercomService } from '@bp/shared/services';

@Component({
	selector: 'bp-enterprise-subscription-pane',
	templateUrl: './enterprise-subscription-pane.component.html',
	styleUrls: [ './enterprise-subscription-pane.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterpriseSubscriptionPaneComponent {

	constructor(public readonly intercomService: IntercomService) { }

}
