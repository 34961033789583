import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

import { CurrentMerchantSubscriptionFeatureLimit } from '../../../../../../models';

@Component({
	selector: 'bp-current-merchant-subscription-feature-limit-meter',
	templateUrl: './current-merchant-subscription-feature-limit-meter.component.html',
	styleUrls: [ './current-merchant-subscription-feature-limit-meter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentMerchantSubscriptionFeatureLimitMeterComponent {

	@Input() featureLimit!: CurrentMerchantSubscriptionFeatureLimit;

	@Input() reflectWarning!: boolean;

	@HostBinding('class.warning')
	get isWarningThresholdReached(): boolean {
		return this.reflectWarning && this.featureLimit.isWarningThresholdReached;
	}

	@HostBinding('class.danger')
	get isDangerThresholdReached(): boolean {
		return this.reflectWarning && this.featureLimit.isDangerThresholdReached;
	}

}
