import type { IPageQueryParamsDTO, StringifyQuery } from '@bp/shared/models/common';
import { mixinPageQueryParams, QueryParamsBase } from '@bp/shared/models/common';
import { ISortQueryParamsDTO, mixinSortQueryParams } from '@bp/shared/models/metadata';

import { MerchantPsp, PspStatus } from '@bp/admins-shared/core/models';

interface IMerchantPspsQueryParams {

	status?: PspStatus | null;

}

type MerchantPspsQueryParamsDTO = IPageQueryParamsDTO
& ISortQueryParamsDTO
& StringifyQuery<IMerchantPspsQueryParams>;

class MerchantPspsQueryParamsBase extends QueryParamsBase<MerchantPspsQueryParamsDTO> { }

export class MerchantPspsQueryParams extends mixinPageQueryParams(
	mixinSortQueryParams(MerchantPspsQueryParamsBase, MerchantPsp),
) implements IMerchantPspsQueryParams {

	status?: PspStatus | null;

	constructor(dto?: MerchantPspsQueryParamsDTO) {
		super(dto);

		this.status = PspStatus.parse(dto?.status);
	}

}
