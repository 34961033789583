import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-subscription-plan-logos',
	templateUrl: './subscription-plan-logos.component.html',
	styleUrls: [ './subscription-plan-logos.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPlanLogosComponent {

	logos = [
		'woo-commerce-row', 'paypal', 'stripe', 'magento-row', 'klarna', 'checkout', 'visa', 'mastercard', 'worldline',
	];

}
