<bp-layout [@routeHitAnimations]="shouldAnimateOutlet" (@routeHitAnimations.done)="shouldAnimateOutlet = false">
	<bp-svg-payment-card-brands-icons-definitions></bp-svg-payment-card-brands-icons-definitions>

	<bp-right-drawers-orchestrator></bp-right-drawers-orchestrator>

	<bp-notifications-hub></bp-notifications-hub>

	<bp-announcements-hub></bp-announcements-hub>

	<bp-modal-outlet>
		<router-outlet name="modal"></router-outlet>
	</bp-modal-outlet>

	<bp-menu></bp-menu>

	<bp-toolbar
		*ngIf="identityFacade.userIsLoggedIn$ | async"
		[userName]="(identityFacade.user$ | async)!.email"
		(logout)="identityFacade.confirmLogout()"
		(clickMenu)="layoutFacade.openSidenav()"
	>
		<div class="ng-content-toolbar-left-section">
			<ng-container *ngIf="environment.isStagingOrLocal; else merchNameTpl">
				<a
					*ngIf="currentMerchantFacade.entity$ | async as merch"
					@fade
					mat-button
					class="merch font-weight-normal"
				>
					{{ merch!.name }}
				</a>
			</ng-container>
			<ng-template #merchNameTpl>
				<span *ngIf="currentMerchantFacade.entity$ | async as merch" @fade class="merch">
					{{ merch!.name }}
				</span>
			</ng-template>
		</div>

		<div class="ng-content-toolbar-right-section flex">
			<div class="mr-2 flex-align-items-center">
				<bp-current-subscription-limits-meter> </bp-current-subscription-limits-meter>
			</div>

			<bp-announcements-hub-open-button class="mr-2"></bp-announcements-hub-open-button>

			<bp-notifications-hub-bell-btn class="mr-2"></bp-notifications-hub-bell-btn>

			<a
				mat-icon-button
				bpTooltip="Preferences"
				class="bp-icon-button-md mr-2"
				[routerLink]="[{ outlets: { '[right-drawer]': ['identity', 'preferences'] } }]"
			>
				<bp-svg-icon name="gear"></bp-svg-icon>
			</a>
		</div>

		<div class="ng-content-toolbar-user-menu-items">
			<ng-container
				*ngIf="currentMerchantSubscriptionFacade.presentEntity$ | async as currentMerchantSubscription"
			>
				<a
					*ngIf="!currentMerchantSubscription.isEnterprise"
					mat-menu-item
					[routerLink]="[{ outlets: { modal: ['subscription-management'] } }]"
				>
					Manage Your Plan
				</a>

				<a
					*ngIf="currentMerchantSubscription.hasBillingPortal"
					mat-menu-item
					class="flex-align-items-center"
					(click)="currentMerchantSubscriptionFacade.openBillingPortal()"
				>
					Billing

					<mat-icon class="material-icons-round bp-icon-sm ml-auto mr-0"> open_in_new </mat-icon>
				</a>
			</ng-container>
		</div>
	</bp-toolbar>

	<router-outlet (activate)="shouldAnimateOutlet = true"></router-outlet>

	<bp-footer></bp-footer>
</bp-layout>
