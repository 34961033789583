import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FADE } from '@bp/shared/animations';
import { BridgerPsp, BridgerPspPaymentOptionCreditCard, BridgerPspPaymentOptions } from '@bp/shared/domains/bridger-psps/core';
import { PspPaymentOptionType } from '@bp/shared/models/business';

@Component({
	selector: 'bp-bridger-psp-card',
	templateUrl: './bridger-psp-card.component.html',
	styleUrls: [ './bridger-psp-card.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class BridgerPspCardComponent {

	@Input() psp!: BridgerPsp;

	@Input() paymentOptionType!: PspPaymentOptionType;

	@Input() selected!: boolean;

	@Input() selectable = true;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	PspPaymentOptionType = PspPaymentOptionType;

	getCreditCardTypedOption(option: BridgerPspPaymentOptions): BridgerPspPaymentOptionCreditCard {
		return <BridgerPspPaymentOptionCreditCard> option;
	}

}
