import { Label, MapFromDTO, MetadataEntity } from '@bp/shared/models/metadata';

export class MerchantFinancial extends MetadataEntity {

	@Label('Contact name')
	contact!: string;

	@MapFromDTO()
	email!: string;

}
