import { max, range } from 'lodash-es';

import { Component, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormFieldControlComponent } from '@bp/shared/components/core';
import type { TextMask } from '@bp/shared/features/text-mask';
import { TextMaskConfig } from '@bp/shared/features/text-mask';
import type { PaymentCardBrand } from '@bp/shared/models/business';
import type { OnChanges, SimpleChanges } from '@bp/shared/models/core';
import type { IValidatorFunc } from '@bp/shared/features/validation';
import { Validators } from '@bp/shared/features/validation';
import { InputComponent } from '@bp/shared/components/controls';

const DEFAULT_CVV_LENGTH = 3;

@Component({
	selector: 'bp-payment-card-cvv-input',
	templateUrl: './payment-card-cvv-input.component.html',
	styleUrls: [ './payment-card-cvv-input.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PaymentCardCvvInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PaymentCardCvvInputComponent,
			multi: true,
		},
	],
})
export class PaymentCardCvvInputComponent extends FormFieldControlComponent<string | null> implements OnChanges {

	@Input()
	get paymentCardBrand(): PaymentCardBrand | null {
		return this._paymentCardBrand;
	}

	set paymentCardBrand(value: PaymentCardBrand | null) {
		this._paymentCardBrand = value;

		this._requiredCvvLength = max(value?.scheme.code.lengths ?? [ DEFAULT_CVV_LENGTH ])!;
	}

	private _paymentCardBrand: PaymentCardBrand | null = null;

	@ViewChild(InputComponent, { static: true }) private readonly _input!: InputComponent;

	mask!: TextMaskConfig | null;

	private _requiredCvvLength = DEFAULT_CVV_LENGTH;

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		if (changes.paymentCardBrand)
			this._buildMaskAccordingToPaymentCardBrand();
	}

	focus(): void {
		this._input.focus();
	}

	private _buildMaskAccordingToPaymentCardBrand(): void {
		const cvvMask: TextMask = range(this._requiredCvvLength).map(() => /\d/u);

		this.mask = new TextMaskConfig({
			placeholderChar: TextMaskConfig.whitespace,
			placeholderFromMask: false,
			maskOnFocus: false,
			guide: false,
			mask: () => cvvMask,
		});
	}

	protected override _validator: IValidatorFunc<string | null> | null = ({ value }) => {
		if (Validators.isEmptyValue(value))
			return null; // don't validate empty values to allow optional controls

		if (this._requiredCvvLength === value.length)
			return null;

		return { ccVerificationCode: null };
	};
}
