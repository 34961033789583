<bp-modal>
	<ng-template>
		<img
			*ngIf="announcementFacade.loading$ | async; else loadedNewsTpl"
			src="/assets/bp-rocket.gif"
			alt="Loading."
			class="block m-auto"
		/>

		<ng-template #loadedNewsTpl>
			<div *ngIf="announcementFacade.entity$ | async as announcement; else notFoundTpl">
				<h1>
					{{ announcement.name }}
				</h1>

				<div @fadeInStaggeringChildren>
					<div *ngIf="announcement.coverImgUrl" class="cover-img-container mb-2">
						<bp-img [url]="announcement.coverImgUrl"></bp-img>
					</div>

					<hr class="mb-1" />

					<div class="aux-bar mb-3 flex-align-items-center">
						<div class="mat-h5 mb-0 font-weight-slim uppercase">
							{{ announcement.kind.displayName }} @
							{{ announcement.publicationDate | momentFormat: 'LL' | async }}
						</div>

						<!-- we delay rendering to skip the fadeInStaggeringChildren animation  due to clashing of it with mat button ripple  -->
						<button
							*bpDelayedRender="10"
							mat-icon-button
							class="ml-auto"
							[bpTooltip]="urlCopied ? 'The URL copied to the clipboard' : 'Share'"
							[bpTooltipHideOnClick]="false"
							(mousedown)="$event.stopPropagation(); createAnnouncementUrlAndCopyToClipboard()"
						>
							<mat-icon class="material-icons-round">share</mat-icon>
						</button>
					</div>

					<div class="content" [innerHTML]="announcement.content | safe: 'html'"></div>
				</div>
			</div>

			<ng-template #notFoundTpl>
				<h2>An error occurred while loading the announcement</h2>
			</ng-template>
		</ng-template>
	</ng-template>
</bp-modal>
