import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';

import {
	PaymentCardCvvInputComponent, PaymentCardExpireInputComponent, PaymentCardHolderInputComponent, PaymentCardNumberInputComponent
} from './components';

const EXPOSED = [
	PaymentCardNumberInputComponent,
	PaymentCardHolderInputComponent,
	PaymentCardExpireInputComponent,
	PaymentCardCvvInputComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,

		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedComponentsPaymentCardModule { }
