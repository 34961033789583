
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { BpError } from '@bp/shared/models/core';
import { isEqual } from '@bp/shared/utilities';
import { BridgerPsp } from '@bp/shared/domains/bridger-psps/core';

import type { VirtualTerminalData } from '@bp/admins-shared/core/models';
import { Merchant } from '@bp/admins-shared/core/models';

import { loadFailure, loadSuccess, loadVirtualTerminalDataFailure, loadVirtualTerminalDataSuccess, refreshFailure, refreshSuccess } from './current-merchant-api.actions';
import { resetState } from './current-merchant.actions';

export const FEATURE_KEY = '[domain]:current-merchant';

export interface IState {

	entity: Merchant | null;

	loading: boolean;

	error: BpError | null;

	bridgerPsps: BridgerPsp[];

	virtualTerminalData: VirtualTerminalData | null;

}

export const initialState: IState = {
	entity: null,
	bridgerPsps: [],
	loading: true,
	error: null,
	virtualTerminalData: null,
};

const currentMerchantReducer = createReducer(

	initialState,

	on(resetState, (): IState => initialState),

	on(
		loadSuccess,
		refreshSuccess,
		(state, { result }): IState => ({
			...state,
			entity: isEqual(result, state.entity) ? state.entity : result,
			loading: false,
			error: null,
		}),
	),

	on(
		loadFailure,
		refreshFailure,
		(state, { error }): IState => ({
			...state,
			error,
			entity: null,
			loading: false,
		}),
	),

	on(loadVirtualTerminalDataSuccess, (state, { result }): IState => ({
		...state,
		virtualTerminalData: result,
		error: null,
	})),

	on(loadVirtualTerminalDataFailure, (state, { error }): IState => ({
		...state,
		error,
		virtualTerminalData: null,
	})),

);

export function reducer(state: IState | undefined, action: Action): IState {
	return currentMerchantReducer(state, action);
}
