import { createReducer, on } from '@ngrx/store';

import type { News } from '@bp/shared/domains/newsroom';

import { load } from './announcement.actions';
import { loadFailure, loadSuccess } from './announcement-api.actions';

export interface IState {

	loading: boolean;

	entity: News | null;

}

export const initialState: IState = {

	loading: false,

	entity: null,

};

export const reducer = createReducer(
	initialState,

	on(load, (state): IState => ({
		...state,
		entity: null,
		loading: true,
	})),

	on(loadSuccess, (state, { result }): IState => ({
		...state,
		entity: result,
		loading: false,
	})),

	on(loadFailure, (state): IState => ({
		...state,
		loading: false,
	})),
);
