
import { Validators } from '@bp/shared/features/validation';
import type { ICryptoWalletsPaymentOption } from '@bp/shared/models/business';
import { cryptoWalletsJsonPropertyDtoSourceMapper, CryptoWallet, PaymentOptionType } from '@bp/shared/models/business';
import type { DTO } from '@bp/shared/models/metadata';
import { SourceOfDTO, Default, Validator, Mapper } from '@bp/shared/models/metadata';

import { StaticPaymentOptionBase } from './static-payment-option-base';

export class CryptoWalletsPaymentOption extends StaticPaymentOptionBase implements ICryptoWalletsPaymentOption {

	static type = PaymentOptionType.cryptoWallet;

	readonly type = CryptoWalletsPaymentOption.type;

	@SourceOfDTO(cryptoWalletsJsonPropertyDtoSourceMapper)
	override json!: string[];

	@Validator(Validators.requiredArray)
	@Mapper(CryptoWallet)
	@Default([ new CryptoWallet() ])
	wallets!: CryptoWallet[];

	constructor(dto?: DTO<CryptoWalletsPaymentOption>) {
		super(dto);
	}
}
