
import type { DTO } from '@bp/shared/models/metadata';
import { Default, FirebaseEntity, MapFromDTO } from '@bp/shared/models/metadata';

import type { IAnnouncementsHubStatePerUser } from '@bp/firebase-functions';

export class AnnouncementsHubStatePerUser extends FirebaseEntity implements IAnnouncementsHubStatePerUser {

	@MapFromDTO()
	showNotificationDot!: boolean;

	@Default([])
	seenNewsIds!: string[];

	constructor(dto?: DTO<AnnouncementsHubStatePerUser>) {
		super(dto);
	}

}
