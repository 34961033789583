<bp-input
	class="mr-4"
	appearance="standard"
	placeholder="Search PSP"
	[hasSearchIcon]="true"
	(valueChange)="emitFilterChanged({ name: $event?.toString() })"
>
</bp-input>

<bp-select-field
	*ngIf="availableCurrencies?.size"
	@fade
	multiple
	class="lean-mat-form-field mr-4"
	appearance="standard"
	placeholder="Currencies"
	itemKind="currency"
	[items]="availableCurrencies!"
	(valueChange)="emitFilterChanged({ paymentOptionsCurrencies: $event })"
>
</bp-select-field>

<bp-select-field
	*ngIf="availableBrands?.size"
	@fade
	multiple
	class="lean-mat-form-field mr-4"
	appearance="standard"
	placeholder="Brands"
	itemKind="brand"
	[items]="availableBrands!"
	(valueChange)="emitFilterChanged({ paymentCardBrands: $event })"
>
</bp-select-field>

<mat-slide-toggle
	*bpHasAccess="MerchantAdminFeature.paywith"
	color="primary"
	(change)="emitFilterChanged({ someOptionCanBeUsedInPaywith: $event.checked })"
>
	Paywith PSP
</mat-slide-toggle>
