import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EnvironmentService } from '@bp/shared/services';

import { MerchantAdminFeature } from '@bp/shared-domains-identity';

import { CurrentMerchantFacade, FeatureVersionsService } from '@bp/merchant-admin/domains/current-merchant';

@Component({
	selector: 'bp-menu',
	templateUrl: './menu.component.html',
	styleUrls: [ './menu.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	Feature = MerchantAdminFeature;

	constructor(
		public environment: EnvironmentService,
		public featureVersionsService: FeatureVersionsService,
		public currentMerchantFacade: CurrentMerchantFacade,
	) {}
}
