import { createAction, props } from '@ngrx/store';

import { TELEMETRY_HIDDEN_MARK } from '@bp/shared/models/core';

import type { IChangePasswordApiRequest } from '@bp/admins-shared/domains/identity';

export const ACTIONS_SCOPE = '[Section][Identity]';

export const changePassword = createAction(
	`${ ACTIONS_SCOPE } Change Password ${ TELEMETRY_HIDDEN_MARK }`,
	props<IChangePasswordApiRequest>(),
);
