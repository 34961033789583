import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RecordsPage } from '@bp/shared/models/common';
import type { DTO, IEntitiesApiService } from '@bp/shared/models/metadata';

import { MerchantPsp } from '@bp/admins-shared/core/models';
import { BridgerPspsSharedFacade } from '@bp/admins-shared/domains/bridger-psps';

import type { MerchantPspsQueryParams, PspsToAddToMerchantDTO } from '../models';

@Injectable({
	providedIn: 'root',
})
export class MerchantPspsApiService implements IEntitiesApiService<MerchantPsp, MerchantPspsQueryParams> {

	readonly collectionPath = 'merchant/psps';

	constructor(
		private readonly _bridgerPspsSharedFacade: BridgerPspsSharedFacade,
		private readonly _http: HttpClient,
	) { }

	readonly factory = (dto: DTO<MerchantPsp>): MerchantPsp => new MerchantPsp(dto);

	getRecordsPage(query?: MerchantPspsQueryParams): Observable<RecordsPage<MerchantPsp>> {
		return zip(
			this._getRecordsPage(query),
			this._bridgerPspsSharedFacade.allByPspNameMap$,
		)
			.pipe(
				map(([ merchantPspRecordsPage, bridgerPspsByPspNameMap ]) => new RecordsPage({
					...merchantPspRecordsPage,
					records: merchantPspRecordsPage.records.map(merchantPsp => this.factory({
						...merchantPsp,
						bridgerPsp: bridgerPspsByPspNameMap.get(merchantPsp.pspName),
					})),
				})),
			);
	}

	private _getRecordsPage(query?: MerchantPspsQueryParams): Observable<RecordsPage<MerchantPsp>> {
		return this._http
			.get<RecordsPage<DTO<MerchantPsp>>>(
			this.collectionPath,
			{ params: query?.toHttpParams() },
		)
			.pipe(map(result => new RecordsPage(query, result, this.factory)));
	}

	get(id: string): Observable<MerchantPsp | null> {
		return zip(
			this._get(id),
			this._bridgerPspsSharedFacade.allByPspNameMap$,
		)
			.pipe(map(([ merchantPsp, bridgerPspsByPspNameMap ]) => merchantPsp
				? this.factory({
					...merchantPsp,
					bridgerPsp: bridgerPspsByPspNameMap.get(merchantPsp.pspName),
				})
				: null));
	}

	private _get(id: string): Observable<MerchantPsp | null> {
		return this._http
			.get<DTO<MerchantPsp> | undefined>(`${ this.collectionPath }/${ id }`)
			.pipe(map(result => result ? this.factory(result) : null));
	}

	save(psp: MerchantPsp): Observable<MerchantPsp> {
		return this._http
			.put<DTO<MerchantPsp>>(`${ this.collectionPath }/${ psp.id }`, psp)
			.pipe(map(this.factory));
	}

	delete(entity: MerchantPsp): Observable<void> {
		return this._http
			.delete<void>(`${ this.collectionPath }/${ entity.id }`);
	}

	addPsps(psps: PspsToAddToMerchantDTO): Observable<MerchantPsp[]> {
		return this._http
			.post<DTO<MerchantPsp>[]>(`${ this.collectionPath }/add`, psps)
			.pipe(map(result => result.map(this.factory)));
	}

}
