import { PaymentOptionType } from '@bp/shared/models/business';
import type { ICurrency } from '@bp/shared/models/currencies';
import { CryptoCurrency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import { Control, Default, FieldControlType, Mapper } from '@bp/shared/models/metadata';

import { PspPaymentOptionBase } from './psp-payment-option-base';

export class CryptoPspPaymentOption extends PspPaymentOptionBase {

	static type = PaymentOptionType.crypto;

	readonly type = CryptoPspPaymentOption.type;

	@Mapper(CryptoCurrency)
	@Default([])
	@Control(
		FieldControlType.chip,
		{
			placeholder: 'Add currency...',
		},
	)
	currencies!: ICurrency[];

	constructor(dto?: DTO<CryptoPspPaymentOption>) {
		super(dto);
	}

}
